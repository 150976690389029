import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { differenceInYears, format } from 'date-fns';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import { InputTypeEnum } from '../../constants/InputTypeEnum';
import ClientInfoContext from '../../contexts/ClientInfoContext';
import ReferentialContext from '../../contexts/ReferentialContext';
import { IReferentials } from '../../interfaces/IReferential';
import { IAddress } from '../../interfaces/UserKycMppDto/IContactInformation';
import UserService from '../../services/UserService';
import CouponItem from '../CouponItem/CouponItem';
import IdentityPanelModalGridAddressItem from '../IdentityPanelModalGridAddressItem/IdentityPanelModalGridAddressItem';
import IdentityPanelModalGridItem from '../IdentityPanelModalGridItem/IdentityPanelModalGridItem';
import IIdentityPanelModalProps from './IIdentityPanelModalProps';
import IIdentityPanelModalState from './IIdentityPanelModalState';

const styles: {[key: string]: SxProps} = {
  title: {
    borderBottom: '1px solid',
    borderColor: 'lightGray',
    p: 2,
    textTransform: 'uppercase',
  },

  modalLeftPart: {
    p: 2,
    borderRight: '1px solid',
    borderColor: 'lightGray',
    flexBasis: '70%',
  },

  modalRightPart: {
    p: 2,
    flexBasis: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};

class IdentityPanelModal extends React.Component<IIdentityPanelModalProps, IIdentityPanelModalState> {
  public readonly state: Readonly<IIdentityPanelModalState> = {
    coupons: [],
    userCoupons: [],
    hasChangedValue: false,
  };

  private abortController = new AbortController();
  private userService = new UserService(this.abortController.signal);
  private genders: IReferentials = [
    {
      slug: 'woman',
      label: 'woman',
    }, {
      slug: 'man',
      label: 'man',
    },
  ];

  public async componentDidMount() {
    await Promise.all([
      this.setCoupons(),
      this.setUserCoupons(),
    ]);
  }

  public render() {
    const { open, dto, account, user } = this.props;
    const { coupons } = this.state;
    const taxAddress = this.getAddress(dto.contactInformation?.taxAddress);
    const homeAddress = this.getAddress(dto.contactInformation?.homeAddress);

    return (
      <Modal open={open} onClose={this.onModalClose}>
        <Box className="modal">
          <Typography variant="h5" sx={styles.title}>
            <Trans>account_identity_title</Trans>
          </Typography>

          <ClientInfoContext.Consumer>
            {
              ({ isAccountMinor, kycQuestions }) => (
                <ReferentialContext.Consumer>
                  {
                    ({ countries, maritalStatuses, professionalStatuses }) => (
                      <Box sx={{ display: 'flex' }}>
                        <Box sx={styles.modalLeftPart}>
                          <Grid container spacing={4}>

                            { /* GENDER */ }
                            <IdentityPanelModalGridItem
                              isEditable
                              setHasChangedValue={this.setHasChangedValue}
                              accountId={account['@id']}
                              propertyToUpdate="gender"
                              referentials={this.genders}
                              inputType={InputTypeEnum.Select}
                              dto={dto}
                              label={<Trans>account_identity_civility_label</Trans>}
                              content={t(dto.identity.gender ?? '') ?? ' - '}
                              newValueTranslator={t}
                              kycQuestions={kycQuestions}
                            />

                            { /* NATIONALITY */ }
                            <IdentityPanelModalGridItem
                              label={<Trans>account_identity_nationality_label</Trans>}
                              content={this.getNameFromReferential(countries, dto.birthInformation.nationality)}
                            />

                            { /* FIRSTNAME */ }
                            <IdentityPanelModalGridItem
                              isEditable
                              propertyToUpdate="firstName"
                              setHasChangedValue={this.setHasChangedValue}
                              label={<Trans>account_identity_firstname_label</Trans>}
                              content={dto.identity.firstName}
                              dto={dto}
                              kycQuestions={kycQuestions}
                            />

                            { /* LASTNAME */ }
                            <IdentityPanelModalGridItem
                              isEditable
                              propertyToUpdate="lastName"
                              setHasChangedValue={this.setHasChangedValue}
                              label={<Trans>account_identity_lastname_label</Trans>}
                              content={dto.identity.lastName}
                              dto={dto}
                              kycQuestions={kycQuestions}
                            />

                            { /* BIRTHDAY */ }
                            <IdentityPanelModalGridItem
                              isEditable
                              setHasChangedValue={this.setHasChangedValue}
                              accountId={account['@id']}
                              inputType={InputTypeEnum.Date}
                              dto={dto}
                              gridXs={isAccountMinor ? 12 : 6}
                              propertyToUpdate="birthDate"
                              label={<Trans>account_identity_birthdate_label</Trans>}
                              content={dto.identity.birthDate ? new Date(dto.identity.birthDate).toString() : '-'}
                              jsxContent= {(
                                <>
                                  {
                                    dto.identity.birthDate ?
                                      `${ format(new Date(dto.identity.birthDate), 'dd MMMM yyyy') } ${ differenceInYears(new Date(), new Date(dto.identity.birthDate)) } ${ t(differenceInYears(new Date(), new Date(dto.identity.birthDate)) > 1 ? 'years' : 'year') }`
                                      : '-'
                                  }
                                </>
                              )}
                              newValueTranslator={(newValue: string) => format(new Date(newValue), 'dd MMMM yyyy')}
                              kycQuestions={kycQuestions}
                            />

                            {
                              isAccountMinor ?
                                null
                                : (
                                  <>
                                    { /* CHILDREN */ }
                                    <IdentityPanelModalGridItem
                                      isEditable
                                      accountId={account['@id']}
                                      setHasChangedValue={this.setHasChangedValue}
                                      dto={dto}
                                      propertyToUpdate="nbChildren"
                                      label={<Trans>account_identity_children_label</Trans>}
                                      content={dto.familyInformation?.nbChildren?.toString() || '-'}
                                      kycQuestions={kycQuestions}
                                    />

                                    { /* MARITAL STATUS */ }
                                    <IdentityPanelModalGridItem
                                      isEditable
                                      accountId={account['@id']}
                                      setHasChangedValue={this.setHasChangedValue}
                                      dto={dto}
                                      referentials={maritalStatuses}
                                      inputType={InputTypeEnum.Select}
                                      propertyToUpdate="maritalStatus"
                                      label={<Trans>account_identity_marital_label</Trans>}
                                      content={this.getNameFromReferential(maritalStatuses, dto.familyInformation?.maritalStatus as string)}
                                      newValueTranslator={(newValue: string) => this.getNameFromReferential(maritalStatuses, newValue)}
                                      kycQuestions={kycQuestions}
                                    />

                                    { /* JOB */ }
                                    <IdentityPanelModalGridItem
                                      isEditable
                                      accountId={account['@id']}
                                      setHasChangedValue={this.setHasChangedValue}
                                      dto={dto}
                                      propertyToUpdate="job"
                                      label={<Trans>account_identity_job_label</Trans>}
                                      content={
                                        !!dto.professionalInformation?.job ?
                                          dto.professionalInformation?.job :
                                          this.getNameFromReferential(professionalStatuses, dto.professionalInformation?.professionalStatus as string)
                                      }
                                      kycQuestions={kycQuestions}
                                    />

                                    { /* RETIREMENT AGE */ }
                                    <IdentityPanelModalGridItem
                                      label={<Trans>account_identity_retirement_age</Trans>}
                                      content={dto.professionalInformation.retirementAge ? `${ dto.professionalInformation.retirementAge } ${ t('years_old') }` : '-'}
                                    />
                                  </>
                                )
                            }

                            { /* EMAIL */ }
                            <IdentityPanelModalGridItem
                              isEditable
                              label={<Trans>account_identity_email_label</Trans>}
                              content={dto.contactInformation?.email || ''}
                              propertyToUpdate="email"
                              userIri={user['@id'] as string}
                              setHasChangedValue={this.setHasChangedValue}
                            />

                            { /* PHONE */ }
                            <IdentityPanelModalGridItem
                              isEditable
                              accountId={account['@id']}
                              setHasChangedValue={this.setHasChangedValue}
                              dto={dto}
                              propertyToUpdate="phoneNumber"
                              inputType={InputTypeEnum.PhoneNumber}
                              label={<Trans>account_identity_phone_label</Trans>}
                              jsxContent={<Link href={`tel:${ dto.contactInformation?.phoneNumber }`}>{ dto.contactInformation?.phoneNumber }</Link>}
                              content={dto.contactInformation?.phoneNumber || ''}
                              kycQuestions={kycQuestions}
                            />

                            { /* SPONSOR CODE */ }
                            <IdentityPanelModalGridItem
                              label={<Trans>account_identity_sponsor_code</Trans>}
                              content={coupons.map((coupon) => coupon.code).join(', ')}
                            />

                            { /* BIRTH PLACE */ }
                            <IdentityPanelModalGridItem
                              label={<Trans>account_identity_birthplace_label</Trans>}
                              content={this.getBirthPlace()}
                            />


                            { /* TAX ADDRESS */ }
                            <IdentityPanelModalGridAddressItem
                              kycQuestions={kycQuestions}
                              referentials={countries}
                              addressDefaultValue={dto.contactInformation.taxAddress}
                              dto={dto}
                              propertyToUpdate="taxAddress"
                              gridXs={12}
                              account={account['@id']}
                              user={user['@id']}
                              label={<Trans>account_identity_financial_address_label</Trans>}
                              setHasChangedValue={this.setHasChangedValue}
                              jsxContent={<>{ taxAddress }</>}
                              content={taxAddress}
                            />

                            { /* HOME ADDRESS */ }
                            <IdentityPanelModalGridAddressItem
                              kycQuestions={kycQuestions}
                              referentials={countries}
                              addressDefaultValue={dto.contactInformation.homeAddress}
                              dto={dto}
                              propertyToUpdate="homeAddress"
                              gridXs={12}
                              account={account['@id']}
                              label={<Trans>account_identity_home_address_label</Trans>}
                              setHasChangedValue={this.setHasChangedValue}
                              jsxContent={<>{ homeAddress }</>}
                              content={homeAddress}
                            />
                          </Grid>
                        </Box>

                        <Box sx={styles.modalRightPart}>
                          <Grid container spacing={4}>

                            { /* DATE CONNECTION */ }
                            <IdentityPanelModalGridItem
                              gridXs={12}
                              label={<Trans>account_dates_connection_label</Trans>}
                              content={format(new Date(user.lastLogin as string), 'dd MMM yyyy')}
                            />

                            { /* REGISTER DATE */ }
                            <IdentityPanelModalGridItem
                              gridXs={12}
                              label={<Trans>account_dates_register_label</Trans>}
                              content={format(new Date(account.createdAt as string), 'dd MMM yyyy')}
                            />

                            { /* REGISTER COUPONS */ }
                            <IdentityPanelModalGridItem
                              gridXs={12}
                              label={<Trans>account_register_coupon_label</Trans>}
                              jsxContent={this.renderUserCouponsList()}
                            />

                            { /* SIGNATURE DATE */ }
                            <IdentityPanelModalGridItem
                              gridXs={12}
                              label={<Trans>account_dates_signature_label</Trans>}
                              content={format(new Date(account.signatureDate as string), 'dd MMM yyyy')}
                            />

                            { /* SUBSCRIPTION DATE */ }
                            <IdentityPanelModalGridItem
                              gridXs={12}
                              label={<Trans>account_dates_subscription_label</Trans>}
                              content={account.subscriptionDate ? format(new Date(account.subscriptionDate), 'dd MMM yyyy') : '-'}
                            />

                          </Grid>

                          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                            <Button
                              onClick={this.onModalClose}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              sx={{ mt: 3 }}
                            >
                              <Trans>close_button</Trans>
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    )
                  }
                </ReferentialContext.Consumer>
              )
            }
          </ClientInfoContext.Consumer>
        </Box>
      </Modal>
    );
  }

  private renderUserCouponsList() {
    const { userCoupons } = this.state;

    return userCoupons.length === 0 ? <>-</> : userCoupons.map(
      (userCoupon) => <CouponItem key={`${ userCoupon['@id'] }-${ userCoupon.coupon['@id'] }`} userCoupon={userCoupon} setUserCoupons={() => this.setUserCoupons()} />,
    );
  }

  private async setCoupons() {
    try {
      const { user } = this.props;
      const response = await this.userService.getCoupons(user['@id']);

      this.setState({ coupons: response.data['hydra:member'] });
    } catch (error) {
      console.debug(error);
    }
  }

  private async setUserCoupons() {
    try {
      const { user } = this.props;
      const response = await this.userService.getUserCoupons(user['@id']);

      this.setState({ userCoupons: response.data['hydra:member'] });
    } catch (error) {
      console.debug(error);
    }
  }

  private getNameFromReferential(referentials: IReferentials, slug?: string): string {
    return referentials.find((ref) => ref.slug === slug)?.label || '';
  }

  private onModalClose = () => {
    const { handleIsModalOpen } = this.props;
    const { hasChangedValue } = this.state;

    handleIsModalOpen(hasChangedValue);
    this.setState({ hasChangedValue: false });
  };

  private setHasChangedValue = () => {
    const { hasChangedValue } = this.state;

    if (!hasChangedValue) {
      this.setState({ hasChangedValue: true });
    }
  };

  private getBirthPlace(): string {
    const { dto } = this.props;
    const birthPlace: string[] = [];

    if (dto.birthInformation.birthPlace.city && 'name' in dto.birthInformation.birthPlace.city) {
      birthPlace.push(dto.birthInformation.birthPlace.city.name ?? '');
    }

    if (dto.birthInformation.birthPlace.country && 'name' in dto.birthInformation.birthPlace.country) {
      birthPlace.push(dto.birthInformation.birthPlace.country.name ?? '');
    }

    return birthPlace.length > 0 ? birthPlace.join(' ') : '-';
  }

  private getAddress(addressObj?: IAddress): string {
    if (!addressObj) {
      return '-';
    }

    const { address, zipCode, city, country } = addressObj;
    const values: string[] = [];

    address && values.push(address);
    zipCode && values.push(zipCode);

    city && 'name' in city && city.name && values.push(city.name);
    country && 'name' in country && country.name && values.push(country.name);

    return values.length > 0 ? values.join(' ') : '-';
  }
}

export default IdentityPanelModal;
