import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { Trans } from 'react-i18next';
import IDocument from '../../interfaces/IDocument';
import IUser from '../../interfaces/IUser';
import DocumentService from '../../services/DocumentService';
import WindowNavigationService from '../../services/WindowNavigationService';
import IDocumentsTableBodyProps from './IDocumentsTableBodyProps';
import IDocumentsTableBodyState from './IDocumentsTableBodyState';

export default class DocumentsTableBody extends React.PureComponent<IDocumentsTableBodyProps, IDocumentsTableBodyState> {
  public readonly state: IDocumentsTableBodyState = {
    isMenuOpen: false,
    anchorEl: undefined,
    currentDocument: undefined,
  };

  private abortController = new AbortController();
  private documentService;
  private windowNavigationService;
  private menuItems = [
    {
      content: <Trans>document_visualize</Trans>,
      onclick: () => this.documentShower(true),
    },
    {
      content: <Trans>document_download</Trans>,
      onclick: () => this.documentShower(false),
    },
  ];
  private users: Pick<IUser, '@id' |'firstname' | 'lastname'>[] = [];

  constructor(props: IDocumentsTableBodyProps) {
    super(props);
    const { user, legalRepresentatives } = this.props;

    this.documentService = new DocumentService(this.abortController.signal);
    this.windowNavigationService = new WindowNavigationService();
    this.users = [
      {
        firstname: user.firstname,
        lastname: user.lastname,
        '@id': user['@id'],
      },
      ...legalRepresentatives.map((legal) => ({
        firstname: legal.firstName ?? '',
        lastname: legal.lastName ?? '',
        '@id': legal.user,
      })),
    ];
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const { documents } = this.props;
    const { anchorEl, isMenuOpen } = this.state;

    return (
      <TableBody>
        { documents.map(this._renderRow) }

        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={() => this.handleIsMenuOpen(false)}
        >
          {
            this.menuItems.map(
              (menuItem, idx) => (
                <MenuItem key={idx} onClick={menuItem.onclick}>
                  { menuItem.content }
                </MenuItem>
              ),
            )
          }
        </Menu>
      </TableBody>
    );
  }

  private _renderRow = (document: IDocument) => {
    const renderError = () => {
      if (document.errors instanceof Array) {
        return document.errors[0].detail;
      }

      return document.errors?.global ?? document.errors?.detail ?? '-';
    };

    return (
      <TableRow
        key={document['@id']}
        sx={{ cursor: 'pointer' }}
        onClick={() => this.documentShower(true, document['@id'])}
      >
        <TableCell sx={{ paddingY: 0, width: '30%' }}>{ document.name }</TableCell>
        <TableCell sx={{ paddingY: 0, width: '20%' }}>{ this.getPayerFullname(document.user) }</TableCell>
        <TableCell sx={{ paddingY: 0, width: '35%' }}>{ renderError() }</TableCell>
        <TableCell sx={{ paddingY: 0, width: '10%' }}>
          <Trans>{ `document_status_${ document.status }` }</Trans>
        </TableCell>
        <TableCell align="right" sx={{ paddingY: 0, width: '5%' }}>
          <IconButton onClick={(e) => this.handleIsMenuOpen(true, e, document)}>
            <MoreVertIcon fontSize="large" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  private getPayerFullname(docUser: IDocument['user']): string {
    const payer = this.users.find(
      (user) => user['@id'] === docUser,
    );

    return payer ? `${ payer.firstname ?? '-' } ${ payer.lastname ?? '-' }` : '-';
  }

  private handleIsMenuOpen = (opening: boolean, event?: React.MouseEvent<HTMLButtonElement>, document?: IDocument) => {
    event?.stopPropagation();
    this.setState({
      isMenuOpen: opening,
      anchorEl: event?.currentTarget || undefined,
      currentDocument: document,
    });
  };

  private documentShower = (visualize: boolean, documentIri?: IDocument['@id']) => {
    const { currentDocument } = this.state;
    const url = this.documentService.getDocumentDownloadUrl(documentIri ?? (currentDocument?.['@id'] || ''), visualize);

    this.windowNavigationService.openInNewTab(url);
    this.setState({ isMenuOpen: false });
  };
}
