export enum ProviderContractTypesConstant {
  MPP_MINEUR = '1edc7096-fd57-65f8-843d-6d4419abab8a',
  GENERALI_VIE_MINEUR = '1edc7097-06f2-6144-956f-6d4419abab8a',
  GENERALI_VIE = '1edc7097-107d-6de4-a0c6-6d4419abab8a',
  LIBERALYS_VIE = '1edc7097-1a09-6fe8-8a12-6d4419abab8a',
  MON_PETIT_CONTRAT_VIE = '1edc7097-2395-6c24-854e-6d4419abab8a',
  MPP = '1edc7097-36ae-669e-ae38-6d4419abab8a',
  MON_PLACEMENT_VIE = '018c0192-d6c7-7000-98e4-5d04d96059e1',
  MON_PETIT_PLACEMENT_RETRAITE = '01905e1b-b24c-7ce9-abbc-43c37babcb68'
}
