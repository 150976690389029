import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { setDefaultOptions } from 'date-fns';
import fr from 'date-fns/locale/fr';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import AuthContextProvider from './components/AuthContextProvider/AuthContextProvider';
import MpfRouter from './components/MpfRouter';
import SnackBarAlert from './components/SnackBarAlert/SnackBarAlert';
import ColorConstant from './constants/ColorConstant';
import AuthContext from './contexts/AuthContext';
import english from './translations/en.json';
import french from './translations/fr.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: english,
      fr: french,
    },
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  });

setDefaultOptions({ locale: fr });

const tableTheme = {
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '56px',
        },
        head: {
          height: '56px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '0 16px',
        },
      },
    },
  },
};

const theme = createTheme(createTheme(ColorConstant.theme), tableTheme);

const App = () => (
  <div className="App">
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthContextProvider>
        <AuthContext.Consumer>
          {
            ({ authenticated }) => <MpfRouter authenticated={authenticated} />
          }
        </AuthContext.Consumer>
      </AuthContextProvider>
      <SnackBarAlert />
    </ThemeProvider>
  </div>
);

export default App;
