import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Permission from '../constants/Permission';
import AccountPage from '../pages/AccountPage/AccountPage';
import AccountsPage from '../pages/AccountsPage/AccountsPage';
import CouponsPage from '../pages/CouponsPage/CouponsPage';
import DashboardPage from '../pages/DashboardPage';
import SettingsPage from '../pages/SettingsPage/SettingsPage';
import Layout from './Layout';
import PermissionChecker from './PermissionChecker';
import RenderPublicRoutes from './RenderPublicRoutes';
import RoutesHandler from './RoutesHandler';

export default class MpfRouter extends React.PureComponent<{ authenticated: boolean }> {
  public render() {
    const { authenticated } = this.props;

    return (
      <BrowserRouter>
        <Switch>
          { authenticated ? this.renderProtectedRoutes() : <RenderPublicRoutes /> }
        </Switch>
      </BrowserRouter>
    );
  }

  private renderProtectedRoutes() {
    return (
      <PermissionChecker permissions={[Permission.ACCESS]}>
        <Layout>
          <RoutesHandler>
            <Route path="/dashboard" key="dashboard-route" component={DashboardPage} />
            <Route exact path="/accounts" key="accounts-route" component={AccountsPage} />
            <Route path="/accounts/:id/:tab" key="accounts-route-with-id" component={AccountPage} />
            <Route path="/coupons" key="coupons-route" component={CouponsPage} />
            <Route path="/settings" key="settings-route" component={SettingsPage} />
          </RoutesHandler>
        </Layout>
      </PermissionChecker>
    );
  }
}
