import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MenuConstant from '../constants/MenuConstant';
import PropertiesConstant from '../constants/PropertiesConstant';
import TopBarClient from './TopBarClient/TopBarClient';
import TopBarSearch from './TopBarSearch/TopBarSearch';

class TopBar extends React.PureComponent<RouteComponentProps> {
  public render(): ReactElement {
    const { history } = this.props;

    return (
      <AppBar
        color="transparent"
        elevation={0}
        position="fixed"
        sx={{
          width: `calc(100% - ${ PropertiesConstant.SIDEBAR_SIZE }px)`,
          ml: `${ PropertiesConstant.SIDEBAR_SIZE }px`,
          height: `${ PropertiesConstant.TOPBAR_SIZE }px`,
          borderBottom: '1px solid #E9E9E9',
          backgroundColor: 'white',
        }}
      >
        <Toolbar sx={{
          minHeight: `${ PropertiesConstant.TOPBAR_SIZE }px !important`,
          justifyContent: 'space-between',
        }}
        >
          <Typography variant="h6" noWrap component="div">
            { this.renderTopBarContent() }
          </Typography>
          {
            history.location.pathname !== '/accounts' && <TopBarSearch />
          }
        </Toolbar>
      </AppBar>
    );
  }

  private renderTopBarContent() {
    const { location } = this.props;

    if (location.pathname.includes('/accounts/')) {
      return <TopBarClient />;
    } else {
      return <Trans>{ MenuConstant.find((el) => el.path === location.pathname)?.title }</Trans>;
    }
  }
}

export default withRouter(TopBar);
