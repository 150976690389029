import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SxProps } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { createRef, FormEvent } from 'react';
import { Trans } from 'react-i18next';
import ICouponCategory from '../../interfaces/ICouponCategory';
import { ICouponCreation } from '../../interfaces/ICouponCreation';
import CouponService from '../../services/CouponService';
import SnackbarService from '../../services/SnackbarService';
import { createParamsObjectFromQuerystring } from '../../utils/createParamsObjectFromQueryString';
import SnackBarAlert from '../SnackBarAlert/SnackBarAlert';
import ICouponModalProps from './ICouponModalProps';
import ICouponModalState from './ICouponModalState';

const styles: { [key: string]: SxProps } = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 533,
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
};

class CouponModal extends React.PureComponent<ICouponModalProps, ICouponModalState> {
  public readonly state: Readonly<ICouponModalState> = {
    isWaitingForSubmitAction: false,
    isDisabled: true,
    selectValue: this.props.isCreating ? '': this.props.coupon?.couponCategory?.['@id'] || '',
    codeCouponIsAvaible: false,
    isSearchingCoupon: false,
  };

  private abortController = new AbortController();
  private couponService = new CouponService(this.abortController.signal);
  private debounceTimeout?: NodeJS.Timeout;
  private couponNameRef = createRef<HTMLInputElement>();
  private couponCodeRef = createRef<HTMLInputElement>();
  private couponCategoryRef = createRef<HTMLSelectElement>();
  private couponValueRef = createRef<HTMLInputElement>();
  private couponDescriptionRef = createRef<HTMLInputElement>();

  public componentDidUpdate() {
    const { isModalOpen, isCreating, coupon } = this.props;
    const { selectValue, isDisabled } = this.state;

    // disable button when modal is closed
    if (!isModalOpen && !isDisabled) {
      this.checkIfDisabled();
    }

    // clear Select when modal is closed
    if (!isModalOpen && '' !== selectValue) {
      return this.setState({
        selectValue: '',
      });
    }

    // force select defaultValue when opening for update
    if (isModalOpen && !isCreating && coupon && '' === selectValue) {
      return this.setState({
        isDisabled: false,
        selectValue: coupon?.couponCategory?.['@id'] || '',
      });
    }
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const {
      handleModalClose,
      isModalOpen,
      isCreating,
      coupon,
      couponCategories,
    } = this.props;

    const { selectValue } = this.state;

    return (
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
      >
        <>
          <Box
            component="form"
            sx={styles.modal}
            onSubmit={this.handleSubmit}
            onChange={this.handleFormChange}
          >
            { /* NAME */ }
            {
              isCreating ? (
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    inputRef={this.couponNameRef}
                    label={<Trans>coupon_table_name</Trans>}
                    fullWidth
                  />
                </FormControl>
              ) : (
                <Typography variant="h5">{ coupon?.name }</Typography>
              )
            }

            { /* CODE */ }
            <Box>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  inputRef={this.couponCodeRef}
                  required
                  label={<Trans>coupon_code_sponsor</Trans>}
                  fullWidth
                  onChange={(e) => this.checkCodeValidity(e.target.value)}
                  defaultValue={isCreating ? '' : coupon?.code}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{ this.renderCodeCouponFieldAdornment() }</InputAdornment>,
                  }}
                />
              </FormControl>
            </Box>

            { /* CATEGORY */ }
            <Box>
              <FormControl variant="standard" fullWidth>
                <InputLabel>
                  <Trans>coupon_category</Trans>*
                </InputLabel>
                <Select
                  required
                  fullWidth
                  inputRef={this.couponCategoryRef}
                  value={selectValue}
                  onChange={this.onSelectChange}
                >
                  {
                    couponCategories
                      .map((category) => (
                        <MenuItem
                          key={category['@id']}
                          value={category['@id']}
                        >
                          { category.name }
                        </MenuItem>
                      ))
                  }
                </Select>
              </FormControl>
            </Box>

            { /* VALUE */ }
            <Box>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  inputRef={this.couponValueRef}
                  fullWidth
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 100,
                    },
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  label={<Trans>coupon_value</Trans>}
                  defaultValue={isCreating ? '' : coupon?.discountAmount}
                />
              </FormControl>
            </Box>

            { /* DESCRIPTION */ }
            <Box>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  inputRef={this.couponDescriptionRef}
                  multiline
                  rows={5}
                  label={<Trans>coupon_description</Trans>}
                  fullWidth
                  defaultValue={isCreating ? '' : coupon?.description}
                />
              </FormControl>
            </Box>

            { this.renderSubmitButton() }
          </Box>

          <SnackBarAlert />
        </>
      </Modal>
    );
  }

  private renderSubmitButton() {
    const { isCreating } = this.props;
    const { isWaitingForSubmitAction, isDisabled } = this.state;

    return (
      <Box  sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={isDisabled}
          type="submit"
          sx={{ margin: 1 }}
          variant="contained"
        >
          {
            isWaitingForSubmitAction ?
              <CircularProgress color="secondary" />
              : <Trans>{ isCreating ? 'create_coupon' : 'update_coupon' }</Trans>
          }
        </Button>
      </Box>
    );
  }

  private renderCodeCouponFieldAdornment() {
    const { codeCouponIsAvaible, isSearchingCoupon } = this.state;

    return isSearchingCoupon ? (
      <CircularProgress color="primary" size={24} />
    ) : codeCouponIsAvaible ? (
      <Tooltip title={<Trans>coupon_code_avaible</Trans>}>
        <CheckIcon color="success" />
      </Tooltip>
    ) : (
      <Tooltip title={<Trans>coupon_code_unavaible</Trans>}>
        <CloseIcon color="error" />
      </Tooltip>
    );
  }

  private onSelectChange = (e: SelectChangeEvent<ICouponCategory['name']>) => {
    this.setState({
      selectValue: e.target.value,
    },this.checkIfDisabled);
  };

  private handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    if (e.target === this.couponCodeRef.current) {
      return this.checkCodeValidity(this.couponCodeRef.current.value);
    }

    return this.checkIfDisabled();
  };

  private checkIfDisabled = () => {
    const { isDisabled, codeCouponIsAvaible } = this.state;
    const requiredFields = [
      this.couponCodeRef.current,
      this.couponCategoryRef.current,
    ];
    const foundEmptyField = requiredFields.find((field) => !field?.value);
    const discountAmountIsValid = this.discountAmountIsValidOrUndefined();

    // discountamount checker doesn't work for empty field
    if (foundEmptyField || !discountAmountIsValid || !codeCouponIsAvaible) {
      // setState only if necessary
      return !isDisabled && this.setState({
        isDisabled: true,
      });
    }

    // setState only if necessary
    return isDisabled && this.setState({
      isDisabled: false,
    });
  };

  private handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { isCreating } = this.props;

    isCreating ? this.createCoupon() : this.updateCoupon();
  };

  private createCoupon = async () => {
    const yearInSeconds = 31622400;
    const { setIsModalOpen, setCoupons } = this.props;

    this.setState({ isWaitingForSubmitAction: true });

    try {
      const params = createParamsObjectFromQuerystring();
      const coupon: ICouponCreation = {
        name: this.couponNameRef.current?.value,
        code: this.couponCodeRef.current?.value as string,
        ...(this.couponValueRef.current && { discountAmount: parseInt(this.couponValueRef.current.value) }),
        sponsorshipFactor: 1,
        couponCategory: this.couponCategoryRef.current?.value as ICouponCategory['@id'],
        subscriptionFree: false,
        description: this.couponDescriptionRef.current?.value || undefined,
        enabled: true,
      };

      if ('/v1/coupon_categories/1' !== this.couponCategoryRef.current?.value) {
        coupon.discountTimeInSeconds = yearInSeconds;
      }

      await this.couponService.createCoupon(coupon);
      SnackbarService.open('Coupon créé', 'success');
      await setCoupons(params);
    } catch (error) {
      SnackbarService.open('Échec : coupon non créé', 'error');
    } finally {
      this.setState({
        isWaitingForSubmitAction: false,
        isDisabled: true,
      });
      setIsModalOpen();
    }
  };

  private updateCoupon = async () => {
    const { coupon, setIsModalOpen, setCoupons } = this.props;

    this.setState({ isWaitingForSubmitAction: true });

    try {
      const params = createParamsObjectFromQuerystring();
      const updatedCoupon: ICouponCreation = {
        code: this.couponCodeRef.current?.value as string,
        discountAmount: parseInt(this.couponValueRef.current?.value || '') ?? undefined,
        sponsorshipFactor: 1,
        couponCategory: this.couponCategoryRef.current?.value as ICouponCategory['@id'],
        subscriptionFree: false,
        description: this.couponDescriptionRef.current?.value || undefined,
      };

      await this.couponService.updateCoupon(updatedCoupon, coupon?.['@id'] || '');
      SnackbarService.open('Coupon mis à jour', 'success');
      await setCoupons(params);
    } catch (error) {
      console.debug('Failed to update coupon', error);
      SnackbarService.open('Échec : coupon non mis à jour', 'error');
    } finally {
      this.setState({
        isWaitingForSubmitAction: false,
        isDisabled: true,
      });
      setIsModalOpen();
    }
  };

  private discountAmountIsValidOrUndefined = () => {
    const discountAmountValue = this.couponValueRef.current?.value;

    if (undefined !== discountAmountValue) {
      const amountIsBetweenBoundaries = 0 <= parseInt(discountAmountValue) && 100 >= parseInt(discountAmountValue);

      if ('' === discountAmountValue || amountIsBetweenBoundaries) {
        return true;
      }
    }

    return false;
  };

  private checkCodeValidity = (codeValue: string) => {
    this.debounceTimeout && clearTimeout(this.debounceTimeout);

    if (!codeValue) {
      return this.setState({
        codeCouponIsAvaible: false,
        isSearchingCoupon: false,
        isDisabled: true,
      });
    }

    this.setState({
      isSearchingCoupon: true,
      isDisabled: true,
    });

    this.debounceTimeout = setTimeout(async () => {
      try {
        const params = new URLSearchParams();

        params.append('code', codeValue);
        await this.couponService.checkCouponValidity(codeValue);

        this.setState(
          {
            codeCouponIsAvaible: false,
            isSearchingCoupon: false,
          },
          this.checkIfDisabled,
        );
      } catch (error) {
        this.setState(
          {
            codeCouponIsAvaible: true,
            isSearchingCoupon: false,
          },
          this.checkIfDisabled,
        );
      }
    }, 1000);
  };
}

export default CouponModal;
