import { AxiosResponse } from 'axios';
import { IMoneyPot } from '../interfaces/IMoneyPot';
import HttpQueueService from './HttpQueueService';
import ServiceConstructor from './ServiceConstructor';

class MoneyPotService extends ServiceConstructor {
  public collectMoneyPot(moneyPotIri: IMoneyPot['@id']): Promise<AxiosResponse<void>> {
    return HttpQueueService.makeSingleRequest({
      method: 'POST',
      url: `${ moneyPotIri }/collect`,
      signal: this.signal,
      data: {},
    });
  }
}

export default MoneyPotService;
