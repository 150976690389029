
const FileSubreasonConstant = [
  {
    label: 'recto',
    slug: 'recto',
  },
  {
    label: 'verso',
    slug: 'verso',
  },
  {
    label: 'activity-income',
    slug: 'activity-income',
  },
  {
    label: 'outflow-funds',
    slug: 'outflow-funds',
  },
  {
    label: 'previous-origin',
    slug: 'previous-origin',
  },
  {
    label: 'rental-income',
    slug: 'rental-income',
  },
  {
    label: 'bonus-income',
    slug: 'bonus-income',
  },
  {
    label: 'annuity-income',
    slug: 'annuity-income',
  },
  {
    label: 'employee-company-savings',
    slug: 'employee-company-savings',
  },
  {
    label: 'contract-capital-savings-check-settlement-transfer-notice',
    slug: 'contract-capital-savings-check-settlement-transfer-notice',
  },
  {
    label: 'contract-capital-savings-insurance-company-letter',
    slug: 'contract-capital-savings-insurance-company-letter',
  },
  {
    label: 'contract-capital-savings-insurance-company-letter-other',
    slug: 'contract-capital-savings-insurance-company-letter-other',
  },
  {
    label: 'movable-assets-sale',
    slug: 'movable-assets-sale',
  },
  {
    label: 'real-estate-assets-sale-certificate-of-sale',
    slug: 'real-estate-assets-sale-certificate-of-sale',
  },
  {
    label: 'real-estate-assets-sale-self-certification-questionnaire',
    slug: 'real-estate-assets-sale-self-certification-questionnaire',
  },
  {
    label: 'professional-assets-sale',
    slug: 'professional-assets-sale',
  },
  {
    label: 'dividends',
    slug: 'dividends',
  },
  {
    label: 'games-winnings',
    slug: 'games-winnings',
  },
  {
    label: 'works-of-art-sale',
    slug: 'works-of-art-sale',
  },
  {
    label: 'other',
    slug: 'other',
  },
];

export default FileSubreasonConstant;
