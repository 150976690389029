import { AxiosResponse } from 'axios';
import HttpQueueService from './HttpQueueService';

class ServiceConstructor {
  constructor(protected signal?: AbortSignal) {}

  protected getRessource<T = unknown>(url: string, params?: URLSearchParams): Promise<AxiosResponse<T>> {
    return HttpQueueService.makeSingleRequest<T>({
      method: 'GET',
      url,
      signal: this.signal,
      params,
    });
  }

  protected postRessource<T = unknown, D = Record<string, never>>(url: string, data: D, params?: URLSearchParams): Promise<AxiosResponse<T>> {
    return HttpQueueService.makeSingleRequest<T, D>({
      method: 'POST',
      url,
      signal: this.signal,
      data,
      params,
    });
  }

  protected patchRessource<T = unknown, D = Record<string, never>>(url: string, data: D, params?: URLSearchParams): Promise<AxiosResponse<T>> {
    return HttpQueueService.makeSingleRequest<T, D>({
      method: 'PATCH',
      url,
      signal: this.signal,
      data,
      params,
    });
  }

  protected deleteRessource<T = unknown>(url: string): Promise<AxiosResponse<T>> {
    return HttpQueueService.makeSingleRequest<T>({
      method: 'DELETE',
      url,
      signal: this.signal,
    });
  }
}

export default ServiceConstructor;
