const TablePaginationConstants = {
  style: {
    borderTop: '1px solid rgb(224, 224, 224)',
    height: '64px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
} as const;

export default TablePaginationConstants;
