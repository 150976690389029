import keycloakAuth from '@mppteam/mpp-keycloak-auth';
import Info from '@mui/icons-material/Info';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import { SxProps } from '@mui/material/styles';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ColorConstant from '../constants/ColorConstant';
import MenuConstant from '../constants/MenuConstant';
import PropertiesConstant from '../constants/PropertiesConstant';
import AuthContext from '../contexts/AuthContext';

const styles: { [key: string]: SxProps } = {
  drawer: {
    width: PropertiesConstant.SIDEBAR_SIZE,
    '& .MuiDrawer-paper': {
      borderRightColor: ColorConstant.lightGray,
      justifyContent: 'space-between',
      width: PropertiesConstant.SIDEBAR_SIZE,
    },
  },

  listItemButton: {
    height: `${ PropertiesConstant.TOPBAR_SIZE }px`,
    borderBottom: '1px solid',
    borderColor: 'lightGray',
    boxShadow: 'none',
  },
};

class SideBar extends React.Component {
  public render() {
    const version = process.env['REACT_APP_VERSION'] ?? 'localhost';

    return (
      <Drawer sx={styles.drawer} variant="permanent">

        <List sx={{ padding: '0 !important' }}>
          { MenuConstant.map((item) => (
            <div key={item.slug}>
              <NavLink to={item.path}>
                <Tooltip placement="right" title={<Trans>{ item.slug }</Trans>}>
                  <ListItemButton
                    sx={styles.listItemButton}
                    className="ListItemButtonCustom"
                    alignItems="center"
                  >
                    <ListItemIcon className="ListItemIconCustom">
                      <Box component={item.icon} />
                    </ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              </NavLink>
            </div>
          )) }
        </List>

        <AuthContext.Consumer>
          { ({ setAuthContext }) => (
            <List sx={{ padding: '0 !important' }}>
              <Tooltip
                title={`version ${ version }`}
                placement="right"
              >
                <ListItemButton
                  className="ListItemButtonCustom active"
                  alignItems="center"
                  sx={{ height: `${ PropertiesConstant.TOPBAR_SIZE }px` }}
                >
                  <ListItemIcon className="ListItemIconCustom">
                    <Info />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
              <Tooltip
                title={<Trans>logout</Trans>}
                placement="right"
              >
                <ListItemButton
                  onClick={() => this.logOut(setAuthContext)}
                  sx={{ height: `${ PropertiesConstant.TOPBAR_SIZE }px` }}
                  className="ListItemButtonCustom active"
                  alignItems="center"
                  key="logout"
                >
                  <ListItemIcon className="ListItemIconCustom">
                    <PowerSettingsNew />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </List>
          ) }
        </AuthContext.Consumer>

      </Drawer>
    );
  }

  private logOut = async (logoutCallback: (value: boolean) => void): Promise<void> => {
    keycloakAuth.hasSession() && await keycloakAuth.disconnect();
    keycloakAuth.cleanSession(['accountsListFavoriteColumns']);
    localStorage.removeItem('expires_at');
    logoutCallback(false);
  };

}

export default SideBar;
