import keycloakAuth from '@mppteam/mpp-keycloak-auth';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { FormEvent, Ref } from 'react';
import { Trans } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ColorConstant from '../constants/ColorConstant';
import AuthContext from '../contexts/AuthContext';
import IAuthContext from '../interfaces/IAuthContext';
import ISignInPageState from '../interfaces/ISignInPageState';
import AccountsService from '../services/AccountsService';
import PermissionsService from '../services/PermissionsService';
import UserService from '../services/UserService';

class SignInPage extends React.Component<RouteComponentProps, ISignInPageState> {
  public state: Readonly<ISignInPageState> = {
    usernameRef: React.createRef<HTMLInputElement>(),
    passwordRef: React.createRef<HTMLInputElement>(),
    displayError: false,
    isLoading: false,
  };

  private forgotPwdUrl = `${ process.env.REACT_APP_KEYCLOAK_API_URL }/realms/${ process.env.REACT_APP_KEYCLOAK_REALM }/login-actions/reset-credentials?client_id=${ process.env.REACT_APP_KEYCLOAK_CLIENT_ID }&kc_locale=fr`;
  private abortController = new AbortController();
  private userService: UserService;
  private accountsService: AccountsService;

  constructor(props: RouteComponentProps) {
    super(props);
    this.userService = new UserService(this.abortController.signal);
    this.accountsService = new AccountsService(this.abortController.signal);
  }

  public render(): JSX.Element {
    const { usernameRef, passwordRef, displayError, isLoading } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <Box sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          <Typography component="h1" variant="h4">
            <Trans>login_title</Trans>
          </Typography>

          <AuthContext.Consumer>
            {
              ({ setAuthContext }) => (
                <Box component="form" onSubmit={(event: FormEvent<HTMLFormElement>) => this.onSubmit(event, setAuthContext)} noValidate sx={{ mt: 1 }}>
                  { this.renderEmailTextField(usernameRef) }
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    id="standard-password-input"
                    label={<Trans>password_label</Trans>}
                    type="password"
                    variant="standard"
                    autoComplete="password"
                    inputRef={passwordRef}
                  />

                  {
                    displayError && (
                      <Alert severity="error" onClose={() => {
                        this.setState({ displayError: false });
                      }}
                      ><Trans>login_error</Trans></Alert>
                    )
                  }

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {
                      isLoading ?
                        <CircularProgress size={24} color="secondary" />
                        : <Trans>login_button</Trans>
                    }
                  </Button>

                  <Box sx={{ textAlign: 'center' }}>
                    <Link
                      href={this.forgotPwdUrl}
                      sx={{ color: ColorConstant.darkGray, textDecorationColor: ColorConstant.darkGray }}
                    >
                      <Trans>forgot_password</Trans>
                    </Link>
                  </Box>
                </Box>
              )
            }
          </AuthContext.Consumer>
        </Box>
      </Container>
    );
  }

  private async onSubmit(event: FormEvent<HTMLFormElement>, callback: IAuthContext['setAuthContext']): Promise<void> {
    event.preventDefault();
    this.setState({
      displayError: false,
      isLoading: true,
    });

    const { usernameRef, passwordRef } = this.state;
    const username = usernameRef.current?.value as string;
    const password = passwordRef.current?.value as string;
    let connected = false;

    try {
      await keycloakAuth.signIn(username, password);

      connected = true;
    } catch (exception) {
      this.setState({
        displayError: true,
        isLoading: false,
      });
      return;
    }

    const [userInfo, providerContractTypesResponse] = await Promise.all([keycloakAuth.getUserInfo(), this.accountsService.getProviderContractTypes() ,this.userService.getConnectedUser()]);

    PermissionsService.userPermissions = userInfo.resource_access['mpp-financier']?.roles;

    username && password && callback(connected, providerContractTypesResponse.data['hydra:member']);
  }

  private renderEmailTextField(usernameRef: Ref<HTMLInputElement>): JSX.Element {
    return (
      <TextField
        margin="normal"
        fullWidth
        required
        id="username"
        label={<Trans>email_label</Trans>}
        autoComplete="email"
        variant="standard"
        inputRef={usernameRef}
      />
    );
  }
}

export default withRouter(SignInPage);
