import ColorConstant from '../../constants/ColorConstant';
import ITagProps from './ITagProps';
import { Box, SxProps } from '@mui/material';

const styles: { [key: string]: SxProps } = {
  tag: {
    display: 'flex',
    border: '2px solid',
    borderRadius: '50px',
    overflow: 'hidden',
    fontSize: '16px',
  },
  title: {
    padding: '0 8px',
    backgroundColor: ColorConstant.lightBlue,
    color: 'white',
  },
  value: {
    padding: '0 8px',
  },
};

const Tag = ({ title, value, color }: ITagProps) => {
  const finalColor = color ?? ColorConstant.lightBlue;

  return (
    <Box sx={{
      ...styles.tag,
      borderColor: finalColor,
    }}
    >
      <Box sx={{
        ...styles.title,
        backgroundColor: finalColor,
      }}
      >
        { title }
      </Box>
      <Box sx={styles.value}>
        { value }
      </Box>
    </Box>
  );
};

export default Tag;
