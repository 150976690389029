import React, { PropsWithChildren } from 'react';
import ReferentialContext from '../../contexts/ReferentialContext';
import ReferentialService from '../../services/ReferentialService';
import IReferentialProviderState from './IReferentialProviderState';

class ReferentialProvider extends React.PureComponent<PropsWithChildren<unknown>, IReferentialProviderState> {
  public readonly state: Readonly<IReferentialProviderState> = {
    loaded: false,
    maritalStatuses: [],
    patrimonies: [],
    goals: [],
    beneficiaries: [],
    loanTypes: [],
    countries: [],
    currentSteps: [],
    professionalStatuses: [],
    investProfileCategories: [],
    providers: [],
    providerContractTypes: [],
    advisors: [],
  };

  private abortController = new AbortController();
  private referentialService = new ReferentialService(this.abortController.signal);

  public async componentDidMount() {
    try {
      const referentialList = await this.referentialService.init();

      this.setState({
        loaded: true,
        ...referentialList,
      });
    } catch (e) {
      console.debug(e);
    }
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const {
      loaded,
      maritalStatuses,
      patrimonies,
      goals,
      beneficiaries,
      loanTypes,
      countries,
      currentSteps,
      professionalStatuses,
      investProfileCategories,
      providers,
      providerContractTypes,
      advisors,
    } = this.state;
    const { children } = this.props;

    return (
      loaded ? (
        <ReferentialContext.Provider value={{
          maritalStatuses,
          patrimonies,
          goals,
          beneficiaries,
          loanTypes,
          countries,
          currentSteps,
          professionalStatuses,
          investProfileCategories,
          providers,
          providerContractTypes,
          advisors,
        }}
        >
          { children }
        </ReferentialContext.Provider>
      ) : <></>
    );
  }
}

export default ReferentialProvider;
