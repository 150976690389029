import { createContext } from 'react';
import IReferentialContext from '../interfaces/IReferentialContext';

const ReferentialContext = createContext<IReferentialContext>({
  maritalStatuses: [],
  patrimonies: [],
  goals: [],
  beneficiaries: [],
  loanTypes: [],
  countries: [],
  professionalStatuses: [],
  currentSteps: [],
  investProfileCategories: [],
  providers: [],
  providerContractTypes: [],
  advisors: [],
});

export default ReferentialContext;
