import { Component } from 'react';
import ClientInfoContext from '../../contexts/ClientInfoContext';
import OrderDetailsModal from '../OrderDetailsModal/OrderDetailsModal';
import OrderPanelCardItem from '../OrderPanelCardItem/OrderPanelCardItem';
import IOrderPanelCardProps from './IOrderPanelCardProps';
import IOrderPanelCardState from './IOrderPanelCardState';

export default class OrderPanelCard extends Component<IOrderPanelCardProps, IOrderPanelCardState> {
  public readonly state: IOrderPanelCardState = {
    isModalOpen: false,
  };

  public render() {
    const { order } = this.props;
    const { isModalOpen } = this.state;

    return (
      <ClientInfoContext.Consumer>
        {
          ({ user }) => (
            <>
              <OrderDetailsModal
                isOrderDetailsModalOpen={isModalOpen}
                order={order}
                handleCloseModal={this.handleCloseModal}
                investmentAccounts={user.investmentAccounts}
              />

              <OrderPanelCardItem order={order} handleOpenModal={this.handleOpenModal} />
            </>
          )
        }
      </ClientInfoContext.Consumer>
    );
  }

  private handleOpenModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  private handleCloseModal = async (hasChangedValue?: boolean) => {
    const { updateOrders } = this.props;

    if (hasChangedValue) {
      await updateOrders();
    }

    this.setState({
      isModalOpen: false,
    });
  };
}
