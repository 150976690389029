import { createContext } from 'react';
import IAuthContext from '../interfaces/IAuthContext';

const AuthContext = createContext<IAuthContext>({
  authenticated: false,
  setAuthContext: () => null,
  providerContractTypes: [],
});

export default AuthContext;
