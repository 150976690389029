import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography';
import { SystemCssProperties } from '@mui/system/styleFunctionSx';
import { differenceInYears, format } from 'date-fns';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import ClientInfoContext from '../../contexts/ClientInfoContext';
import IUserKycMppDto from '../../interfaces/UserKycMppDto/IUserKycMppDto';
import AccountsService from '../../services/AccountsService';
import IdentityPanelModal from '../IdentityPanelModal/IdentityPanelModal';
import IIdentityPanelProps from './IIdentityPanelProps';
import IIdentityPanelState from './IIdentityPanelState';

const styles: {[key: string]: SxProps} = {
  card: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'relative',
  },
};

const beforeStyles: {[key: string]: SystemCssProperties} = {
  cardBefore: {
    position: 'absolute',
    left: 0,
    top: 0,
    content: '" "',
    height: '100%',
    width: '16px',
    display: 'block',
    backgroundColor: 'rgba(255, 255, 0, 0.5)',
  },
};

export default class IdentityPanel extends React.Component<IIdentityPanelProps, IIdentityPanelState> {
  private accountsService: AccountsService;

  constructor(props: IIdentityPanelProps) {
    super(props);
    this.accountsService = new AccountsService();
    this.state = {
      open: false,
    };
  }

  public render() {
    const { open } = this.state;
    const { dto, account, user } = this.props;

    return (
      <ClientInfoContext.Consumer>
        {
          ({ isAccountMinor }) => (
            <>
              <Card
                sx={{
                  ...styles.card,
                  ':before': this.isForeigner(dto) ? beforeStyles.cardBefore : undefined,
                }}
              >
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs={7}>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        <Trans>account_identity_title{ isAccountMinor ? '_minor' : '' }</Trans>
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <b><Trans>account_identity_phone_label</Trans> : </b>
                        <Link href={`tel:${ dto.contactInformation.phoneNumber }`}>{ dto.contactInformation.phoneNumber }</Link>
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <b><Trans>account_register_coupon_label</Trans>&nbsp;:</b>&nbsp;
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          { dto.sponsorsInformation.userCoupons.map((userCoupon) => (
                            <Tooltip key={userCoupon.code} title={`${ userCoupon.discount } %`}>
                              <Box component="span" sx={{ mr: 1 }}>
                                { userCoupon.code }
                              </Box>
                            </Tooltip>
                          )) }
                        </Box>
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <b><Trans>account_identity_email_label</Trans> :</b> { dto.contactInformation.email }
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <b><Trans>account_identity_birthdate_label</Trans> : </b>
                        {
                          dto.identity.birthDate ?
                            `${ this.formatDate(new Date(dto.identity.birthDate)) } ${ differenceInYears(new Date(), new Date(dto.identity.birthDate)) } ${ t(differenceInYears(new Date(), new Date(dto.identity.birthDate)) > 1 ? 'years' : 'year') }`
                            : '-'
                        }
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <b><Trans>account_identity_retirement_age</Trans> :</b> { dto.professionalInformation.retirementAge ? `${ dto.professionalInformation.retirementAge } ${ t('years_old') }` : '-' }
                      </Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        <Trans>account_dates_title</Trans>
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <b><Trans>account_dates_connection_label</Trans> : </b>
                        { this.formatDate(new Date(user.lastLogin as string)) }
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <b><Trans>account_dates_register_label</Trans> : </b>
                        { this.formatDate(new Date(account.createdAt as string)) }
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <b><Trans>account_dates_signature_label</Trans> : </b>
                        { this.formatDate(account.signatureDate ? new Date(account.signatureDate) : null) }
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <b><Trans>account_dates_subscription_label</Trans> : </b>
                        { this.formatDate(account.subscriptionDate ? new Date(account.subscriptionDate) : null) }
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <b><Trans>account_dates_end_of_retractation</Trans> : </b>
                        { this.formatDate(this.accountsService.getRetractionDate(account)) }
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Link component="button" onClick={() => this.handleIsModalOpen()}>
                    <Trans>account_seemore_button</Trans>
                  </Link>
                </CardActions>
              </Card>

              <IdentityPanelModal
                open={open}
                handleIsModalOpen={this.handleIsModalOpen}
                account={account}
                dto={dto}
                user={user}
              />
            </>
          )
        }
      </ClientInfoContext.Consumer>
    );
  }

  private handleIsModalOpen = (hasChangedValues?: boolean) => {
    this.setState((previousState) => {
      if (previousState.open && hasChangedValues) {
        const { setClientInfo, account } = this.props;

        setClientInfo(account.id);
      }

      return { open: !previousState.open };
    });
  };

  private isForeigner(dto: IUserKycMppDto): boolean {
    if (!('code' in dto.contactInformation.taxAddress.country)) {
      return false;
    }

    return dto.contactInformation.taxAddress.country.code !== 'fr' || dto.birthInformation.nationality !== 'fr';
  }

  private formatDate(date: Date | null): string {
    return date ? format(date, 'dd/MM/yyyy') : '-';
  }
}
