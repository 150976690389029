import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Trans } from 'react-i18next';
import { IInvestProfileCategory } from '../../interfaces/IInvestProfileCategory';
import { IUserInvestmentAccountProduct, IUserInvestmentAccountProducts } from '../../interfaces/IUserInvestmentAccountProduct';
import { IRIString } from '../../interfaces/IriType';
import AccountsService from '../../services/AccountsService';
import IProductListProps from './IProductListProps';
import IProductListState from './IProductListState';

const styles: { [key: string]: SxProps } = {
  list: {
    width: '100%',
    pt: 0,
    height: '100%',
    overflow: 'auto',
  },

  card: {
    mb: 1,
    border: '1px solid',
    borderColor: 'lightGray',
  },

  productContent: {
    paddingBottom: '8px',
    padding: '8px !important',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  productContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '50%',
    maxWidth: '50%',
  },

  productName: {
    textTransform: 'capitalize',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  badges: {
    textTransform: 'uppercase',
    color: '#fff',
    padding: '4px 8px',
    fontSize: '8px',
    fontWeight: '400',
    marginRight: '8px',
    width: 'fit-content',
  },

  perf: {
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '13px',
  },

  amount: {
    fontSize: '18px',
    flexBasis: '25%',
    textAlign: 'right',
  },
};

export default class ProductList extends React.Component<IProductListProps, IProductListState> {
  public readonly state: IProductListState = {
    userInvestmentAccountProducts: [],
  };

  private abortController = new AbortController();
  private accountsService;

  constructor(props: IProductListProps) {
    super(props);
    this.accountsService = new AccountsService(this.abortController.signal);
  }

  public async componentDidMount() {
    try {
      const { account, investProfileCategories } = this.props;
      const userInvestmentAccountProducts = await this.accountsService.getAccountInvestmentProducts(account?.['@id'] as IRIString, investProfileCategories);

      this.setState({ userInvestmentAccountProducts: userInvestmentAccountProducts as IUserInvestmentAccountProducts });
    } catch (error) {
      console.debug(error);
    }
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const { userInvestmentAccountProducts } = this.state;

    return (
      <List sx={styles.list}>
        {
          userInvestmentAccountProducts.map((userInvestmentAccountProduct: IUserInvestmentAccountProduct) => (
            <Card
              key={userInvestmentAccountProduct.name}
              sx={styles.card}
            >
              <ButtonBase
                sx={{ width: '100%' }}
                onClick={() => this.handleListItemClick()}
              >
                <CardContent sx={styles.productContent}>
                  <Box sx={styles.productContentWrapper}>
                    <Tooltip title={userInvestmentAccountProduct.name}>
                      <Typography
                        variant="h6"
                        sx={styles.productName}
                      >
                        <Trans>{ userInvestmentAccountProduct.name }</Trans>
                      </Typography>
                    </Tooltip>

                    <Box sx={{ display: 'flex' }}>
                      { this.renderInvestProfileCategoriesList(userInvestmentAccountProduct.investProfileCategoriesCustom) }
                    </Box>
                  </Box>

                  <Box sx={styles.perf}>
                    { this.renderPerf(userInvestmentAccountProduct) }
                  </Box>

                  <Box
                    sx={{
                      ...styles.amount,
                      color: 'primary.main',
                    }}
                  >
                    { userInvestmentAccountProduct.amount }€
                  </Box>
                </CardContent>
              </ButtonBase>
            </Card>
          ))
        }
      </List>
    );
  }

  private handleListItemClick() {
    return;
  }

  private renderInvestProfileCategoriesList(investProfileCategories: IInvestProfileCategory[]) {
    return investProfileCategories.map((investProfileCategory: IInvestProfileCategory) => (
      <Box key={investProfileCategory.name}
        sx={{
          ...styles.badges,
          backgroundColor: `#${ investProfileCategory?.color }`,
        }}
      >
        { investProfileCategory.name }
      </Box>
    ));
  }

  private renderPerf(userInvestmentAccountProduct: IUserInvestmentAccountProduct) {
    const { performance } = userInvestmentAccountProduct;

    if (null === performance) {
      return;
    }

    const isPerformancePositive = performance && performance > 0;

    return (
      performance && (
        <Box
          component="span"
          sx={{ color: isPerformancePositive ? 'primary.main' : '#b04d61' }}
        >
          { isPerformancePositive && '+' }{ performance }%
        </Box>
      )
    );
  }
}
