import * as am4charts from '@amcharts/amcharts4/charts';
import { DateAxis, LineSeries, ValueAxis, XYCursor } from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { RoundedRectangle, color } from '@amcharts/amcharts4/core';
import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { format } from 'date-fns';
import { t } from 'i18next';
import React from 'react';
import { IAccount } from '../../interfaces/IAccount';
import { IFinancialValues } from '../../interfaces/IFinancialValues';
import AccountsService from '../../services/AccountsService';
import IInvestmentValuesChartProps from './IInvestmentValuesChartProps';
import IInvestmentValuesChartState, { IChartType } from './IInvestmentValuesChartState';

const styles: { [key: string]: SxProps } = {
  chart: {
    width: '100%',
    height: 'calc(100% - 24px)',
    marginTop: '24px',
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
};

class InvestmentValuesChart extends React.Component<IInvestmentValuesChartProps, IInvestmentValuesChartState> {
  public readonly state: Readonly<IInvestmentValuesChartState> = {
    type: 'performance',
    showChart: false,
    isLoadingChart: false,
  };

  private chart: am4charts.XYChart | undefined;
  private data: IFinancialValues = [];

  private abortController = new AbortController();
  private accountsService = new AccountsService(this.abortController.signal);

  public componentWillUnmount() {
    this.chart?.dispose();
    this.abortController.abort();
  }

  public render() {
    const { type, showChart, isLoadingChart } = this.state;

    return showChart ? (
      <>
        { isLoadingChart ? (
          <Box sx={styles.wrapper}>
            <CircularProgress size={80} />
          </Box>
        ) : (
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={this.handleAlignment}
            sx={{ position: 'absolute', right: '8px', maxHeight: '24px' }}
            aria-label="text alignment"
          >
            <ToggleButton value="performance" aria-label="left aligned">
              %
            </ToggleButton>
            <ToggleButton value="gain" aria-label="centered">
              €
            </ToggleButton>
            <ToggleButton sx={{ textTransform: 'capitalize' }} value="amount" aria-label="centered">
              Épargne
            </ToggleButton>
          </ToggleButtonGroup>
        ) }
        <Box id="chart" sx={styles.chart} />
      </>
    ) : (
      <Box sx={styles.wrapper}>
        <Button onClick={this.createChart} variant="contained">
          { t('show_chart') }
        </Button>
      </Box>
    );
  }

  private createChart = () => {
    am4core.useTheme(am4themes_animated);

    this.setState({
      showChart: true,
      isLoadingChart: true,
    }, this.getChartData);
  };

  private handleAlignment = (event: React.MouseEvent<HTMLElement>) => {
    const { value } = event.target as HTMLInputElement;

    this.setState(
      { type: value as IChartType },
      this.instantiateChart,
    );
  };

  private async getChartData(): Promise<void> {
    const { account } = this.props;

    try {
      const { data: accountFinancialHistory } = await this.accountsService.getAccountInvestmentValues(account as IAccount);

      this.data = accountFinancialHistory.financialValues.map((value) => ({
        ...value,
        date: format(new Date(value.date), 'M/d/yyyy'),
      }));

      await this.instantiateChart();
    } catch (error) {
      console.debug(error);
      this.setState({
        showChart: false,
        isLoadingChart: false,
      });
    }
  }

  private async instantiateChart(): Promise<void> {
    const { type } = this.state;
    const chart = am4core.create('chart', am4charts.XYChart);

    chart.data = this.data;
    chart.paddingRight = 0;
    chart.paddingLeft = 0;
    chart.paddingTop = 0;
    chart.paddingBottom = 0;
    chart.language.locale = am4lang_fr_FR;

    chart.cursor = new XYCursor();
    chart.cursor.lineX.disabled = false;
    chart.cursor.lineY.disabled = false;

    const dateAxis = chart.xAxes.push(new DateAxis());

    if (dateAxis && dateAxis.tooltip) {
      dateAxis.visible = true;
      dateAxis.tooltip.disabled = true;
      dateAxis.maxHeight = 0;
      dateAxis.maxWidth = 0;

      dateAxis.renderer.grid.template.strokeWidth = 0;
      dateAxis.renderer.baseGrid.disabled = true;

      // avoid empty border on small data set
      dateAxis.startLocation = 0.5;
      dateAxis.endLocation = 0.53;
    }

    // customize label
    const dateLabelTemplate = dateAxis.renderer.labels.template;

    dateLabelTemplate.dy = -10;
    dateLabelTemplate.fill = color('#FFFFFF');
    dateLabelTemplate.padding(4, 8, 1, 8);

    dateLabelTemplate.background = new RoundedRectangle();
    dateLabelTemplate.background.fill = color('#4A8D2C');
    dateLabelTemplate.fontSize = 10;
    dateLabelTemplate.fontWeight = '500';

    // customize grid
    dateAxis.renderer.grid._template.strokeWidth = 1;

    // avoid label on axis limit
    dateAxis.renderer.inside = true;

    const valueAxis = chart.yAxes.push(new ValueAxis());

    if (valueAxis && valueAxis.tooltip) {
      valueAxis.tooltip.disabled = true;
      valueAxis.visible = true;
      valueAxis.renderer.grid.template.strokeWidth = 0;
      valueAxis.renderer.baseGrid.disabled = true;

      valueAxis.maxHeight = 0;
      valueAxis.maxWidth = 0;
      valueAxis.baseValue = -100;
    }

    // customize grid
    valueAxis.renderer.grid._template.strokeWidth = 1;
    valueAxis.renderer.labels.template.adapter.add('text', (text) => `${ text }${ type === 'performance' ? '%' : '€' }`);

    // customize label
    const labelTemplate = valueAxis.renderer.labels.template;

    labelTemplate.dx = 10;
    labelTemplate.dy = -0.2;
    labelTemplate.fill = color('#FFFFFF');
    labelTemplate.padding(4, 8, 1, 8);

    labelTemplate.background = new RoundedRectangle();
    (labelTemplate.background as RoundedRectangle).cornerRadius(3, 3, 3, 3);
    labelTemplate.background.fill = color('#B4DD7C');
    labelTemplate.fontSize = 10;
    labelTemplate.fontWeight = '500';

    // avoid label on axis limit
    valueAxis.renderer.inside = true;
    valueAxis.renderer.minLabelPosition = 0.1;
    valueAxis.renderer.maxLabelPosition = 0.9;

    const series = chart.series.push(new LineSeries());

    if (series && series.tooltip) {
      series.dataFields.dateX = 'date';
      series.dataFields.valueY = type;
      series.fillOpacity = 0;
      series.strokeOpacity = 0;
      series.tooltipHTML = `
          <div style="text-align: center; font-size: 12px; color: #a9a9a9 !important; font-weight: 500;">
            {dateX.formatDate(\'d MMMM yyyy\')}
          </div>
          <div style="text-align: center; font-size: 16px; font-weight: 500;">
            {valueY.value.formatNumber(\'#.00\')} ${ type === 'performance' ? '%' : '€' }
          </div>
        `;

      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = color('#FFFFFF');
      series.tooltip.background.opacity = 1;
      series.tooltip.label.fill = color('#4DB120');
      series.tooltip.label.minWidth = 100;
      series.tooltip.label.minHeight = 40;
      series.tooltip.pointerOrientation = 'down';
    }

    series.strokeOpacity = 1;
    series.stroke = color('#4DB120');
    series.strokeWidth = 2;

    this.setState(
      { isLoadingChart: false },
      () => this.chart = chart,
    );
  }
}

export default InvestmentValuesChart;
