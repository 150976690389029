import axios, { AxiosResponse } from 'axios';
import { IGouvAddress } from '../interfaces/IGouvAddress';
import IGouvCity from '../interfaces/IGouvCity';
import ServiceConstructor from './ServiceConstructor';

class GouvService extends ServiceConstructor {
  private readonly addressApiUrl = 'https://api-adresse.data.gouv.fr';
  private readonly cityApiUrl = 'https://geo.api.gouv.fr';

  public searchAddress(address: string): Promise<AxiosResponse<IGouvAddress>> {
    return axios.get('/search/', {
      baseURL: this.addressApiUrl,
      params: {
        q: address,
      },
      signal: this.signal,
    });
  }

  public searchCity(city: string): Promise<AxiosResponse<IGouvCity[]>> {
    return axios.get('/communes/', {
      baseURL: this.cityApiUrl,
      params: {
        nom: city,
      },
      signal: this.signal,
    });
  }
}

export default GouvService;
