import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SxProps } from '@mui/material/styles';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import ICouponCategory from '../../interfaces/ICouponCategory';
import { createParamsObjectFromQuerystring } from '../../utils/createParamsObjectFromQueryString';
import ICouponCategoriesFilterProps from './ICouponCategoriesFilterProps';
import ICouponCategoriesFilterState from './ICouponCategoriesFilterState';

const styles: { [key: string]: SxProps } = {
  selectWrapper: {
    padding: '0 8px 8px',
  },

  select: {
    width: '40%',
    minWidth: '220px',
  },
};

class CouponCategoriesFilter extends React.PureComponent<ICouponCategoriesFilterProps, ICouponCategoriesFilterState> {
  public readonly state: Readonly<ICouponCategoriesFilterState> = {
    selectedCategorySlug: '',
  };

  public render() {
    const { couponCategories } = this.props;
    const { selectedCategorySlug } = this.state;

    return (
      <Box sx={styles.selectWrapper}>
        <FormControl  variant="standard">
          <InputLabel>
            <Trans>coupon_categories</Trans>
          </InputLabel>
          <Select
            sx={styles.select}
            label={t('coupon_categories')}
            value={selectedCategorySlug}
            onChange={this.onSelectChange}
          >
            {
              couponCategories
                .map((categorie) => (
                  <MenuItem
                    key={categorie['@id']}
                    value={categorie.name}
                  >
                    { categorie.name }
                  </MenuItem>
                ))
            }
          </Select>
        </FormControl>
      </Box>
    );
  }

  private onSelectChange = async (e: SelectChangeEvent<ICouponCategory['slug']>) => {
    const { setCoupons } = this.props;
    const params = createParamsObjectFromQuerystring();
    const categoryName = e.target.value;

    params.set('couponCategory.name', categoryName);
    const isSuccess = await setCoupons(params);

    if(isSuccess) {
      this.setState({ selectedCategorySlug: categoryName });
    }
  };
}

export default CouponCategoriesFilter;
