import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ProviderContractTypesConstant } from '../../constants/ProviderContractTypesConstant';
import ClientInfoContext from '../../contexts/ClientInfoContext';
import IFinancialCapital from '../../interfaces/IFinancialCapital';
import IProviderContractType from '../../interfaces/IProviderContractType';
import IUser from '../../interfaces/IUser';
import { IRIString } from '../../interfaces/IriType';
import IUserKycMppDto from '../../interfaces/UserKycMppDto/IUserKycMppDto';
import AccountsService from '../../services/AccountsService';
import DtoService from '../../services/DtoService';
import KycService from '../../services/KycService';
import UserService from '../../services/UserService';
import createIriFromId from '../../utils/createIriFromId';
import isMinor from '../../utils/isMinor';
import { IClientInfoContextProviderProps } from './IClientInfoContextProviderProps';
import IClientInfoProviderState from './IClientInfoProviderState';
import IManagementMode from '../../interfaces/IManagementMode';

const styles: {[key: string]: SxProps} = {
  circularWrapper: {
    height: '100%',
    width: '100%',
    mt: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

class ClientInfoContextProvider extends React.Component<IClientInfoContextProviderProps, IClientInfoProviderState> {
  private abortController = new AbortController();
  private accountsService = new AccountsService(this.abortController.signal);
  private userService = new UserService(this.abortController.signal);
  private dtoService = new DtoService(this.abortController.signal);
  private kycService = new KycService(this.abortController.signal);

  constructor(props: IClientInfoContextProviderProps) {
    super(props);

    this.state = {
      loaded: false,
      setUser: this.setUser,
      setClientInfo: this.setClientInfo,
      firstTimeLoading: true,
      account: null,
      kycQuestions: [],
    };
  }

  public componentDidMount() {
    const { match: { params: { id } } } = this.props;

    this.setClientInfo(id);
  }

  public componentDidUpdate(previousValue: IClientInfoContextProviderProps) {
    const { match } = this.props;
    const previousId = previousValue.match.params.id;
    const newId = match.params.id;

    if (previousId !== newId) {
      this.setClientInfo(newId);
    }
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const { loaded, error, firstTimeLoading, dto, financialCapital, user }: IClientInfoProviderState = this.state;
    const { children } = this.props;
    const contextData = {
      ...this.state,
      dto: dto as IUserKycMppDto,
      financialCapital: financialCapital as IFinancialCapital,
      user: user as IUser,
    };

    return (
      loaded ? (
        !error ? (
          <ClientInfoContext.Provider value={contextData}>
            { children }
          </ClientInfoContext.Provider>
        ) : (
          <Paper sx={{ padding: 2, margin: 2, overflow: 'auto' }}>
            <Typography variant="h4">
              <Trans>client_info_context_error</Trans>
            </Typography>
            <pre>
              { JSON.stringify(error, null, 2) }
            </pre>
          </Paper>
        )
      ) : firstTimeLoading ? (
        <Box sx={styles.circularWrapper}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <>
          <ClientInfoContext.Provider value={contextData}>
            { children }
          </ClientInfoContext.Provider>
          <Box
            sx={{
              ...styles.circularWrapper,
              backgroundColor: 'rgba(255, 255, 255, .5)',
              position: 'absolute',
              top: '0',
              left: '0',
            }}
          >
            <CircularProgress size={60} />
          </Box>
        </>
      )
    );
  }

  private setUser = (newValues: Partial<IUser>) => {
    if (this.state.user) {
      this.setState({
        user: {
          ...this.state.user,
          ...newValues,
        },
      });
    }
  };

  private setClientInfo = async (id: string) => {
    this.setState({ loaded: false });

    try {
      const { kycQuestions } = this.state;
      const { data: account } = await this.accountsService.getAccount(createIriFromId('user_investment_accounts', id));
      const providerContractTypesIds: IProviderContractType['id'][] = [];

      if (account.providerContractType && kycQuestions.length === 0) {
        const accountProviderContractTypeId = account.providerContractType.split('/').pop() as IProviderContractType['id'];

        providerContractTypesIds.push(accountProviderContractTypeId);

        if (![ProviderContractTypesConstant.MPP, ProviderContractTypesConstant.MPP_MINEUR].includes(accountProviderContractTypeId)) {
          providerContractTypesIds.push(accountProviderContractTypeId === ProviderContractTypesConstant.GENERALI_VIE_MINEUR ? ProviderContractTypesConstant.MPP_MINEUR : ProviderContractTypesConstant.MPP);
        }
      }

      const [
        { data: user },
        { data: dto },
        { data: financialCapital },
        kycQuestionsResponse,
      ] = await Promise.all([
        this.userService.getUser(account.user),
        this.dtoService.getDto(account.userKyc),
        this.accountsService.getUserFinancialCapital(account['@id']),
        this.kycService.getKycQuestionsByProviderContractTypes(providerContractTypesIds),
      ]);
      let isAccountMinor = false;
      let managementMode: IManagementMode | undefined;

      if (account.providerContractType) {
        const providerContractTypeId = account.providerContractType.split('/').pop() as IProviderContractType['id'];

        isAccountMinor = isMinor(providerContractTypeId);
      }

      const response = await this.accountsService.getProviderContractType(account.providerContractType as IRIString);
      const providerContractType = response.data;

      if (account.managementMode) {
        const response = await this.accountsService.getManagementMode(account.managementMode ?? '');

        managementMode = response.data;
      }

      document.dispatchEvent(new CustomEvent('topBarClient', { detail: { account, user, providerContractType, managementMode } }));

      this.setState({
        account,
        user,
        dto,
        financialCapital,
        loaded: true,
        firstTimeLoading: false,
        isAccountMinor,
        kycQuestions: kycQuestions.length === 0 ? kycQuestionsResponse : kycQuestions,
      });
    } catch (error) {
      console.error('ClientInfoContextProvider : ', error);

      this.setState({
        loaded: true,
        error: error,
      });
    }
  };
}

export default withRouter(ClientInfoContextProvider);
