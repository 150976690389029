import { CircularProgress, List, ListItem, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React from 'react';
import { Trans } from 'react-i18next';
import ClientInfoContext from '../../contexts/ClientInfoContext';
import ReferentialContext from '../../contexts/ReferentialContext';
import { IAccount } from '../../interfaces/IAccount';
import { IReferentials } from '../../interfaces/IReferential';
import { IRIString } from '../../interfaces/IriType';
import IUserKycMppDto from '../../interfaces/UserKycMppDto/IUserKycMppDto';
import AccountsService from '../../services/AccountsService';
import ReferentialService from '../../services/ReferentialService';
import SnackbarService from '../../services/SnackbarService';
import IProfilePanelState from './IProfilePanelState';

const styles: { [key: string]: SxProps } = {
  card: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  modalTitle: {
    borderBottom: '1px solid',
    borderColor: 'lightGray',
    p: 2,
    textTransform: 'uppercase',
  },
};

class ProfilePanel extends React.PureComponent<Record<string, never>, IProfilePanelState> {
  public readonly state: IProfilePanelState = {
    open: false,
    accountNeed: undefined,
    alreadyFetchAccountNeeds: false,
    isLoading: false,
  };

  private readonly abortController = new AbortController();
  private readonly accountsService = new AccountsService(this.abortController.signal);
  private readonly referentialService = new ReferentialService();

  public render() {
    const { open, isLoading, accountNeed } = this.state;

    return (
      <ClientInfoContext.Consumer>
        {
          ({ dto, account }) => (
            <ReferentialContext.Consumer>
              {
                ({ loanTypes, goals, investProfileCategories }) => (
                  <Card sx={styles.card}>
                    <CardContent>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Typography variant="h6" sx={{ mb: 2 }}>
                            <Trans>account_profile_title</Trans>
                          </Typography>

                          <Typography variant="body2" sx={{ mb: 1 }}>
                            <b><Trans>account_profile_financial_knowledge_label</Trans> :</b> { dto.computedInformation.financialKnowledgeCategory }
                          </Typography>

                          <Typography variant="body2" sx={{ mb: 1 }}>
                            <b><Trans>account_profile_financial_experience_label</Trans> :</b> { dto.computedInformation?.financialExperienceCategory }
                          </Typography>

                          <Typography variant="body2" sx={{ mb: 1 }}>
                            <b><Trans>account_profile_appetite_label</Trans> :</b> { dto.computedInformation?.riskAppetiteCategory }
                          </Typography>

                          <Typography variant="body2" sx={{ mb: 1 }}>
                            <b><Trans>account_profile_capacity_label</Trans> :</b> { dto.computedInformation.investmentCapacityCategory }
                          </Typography>

                          <Typography variant="body2" sx={{ mb: 1 }}>
                            <b><Trans>account_profile_update_date</Trans> :</b> { account?.lastProviderKycUpdateAt ? format(new Date(account.lastProviderKycUpdateAt), 'dd/MM/yyyy') : '-' }
                          </Typography>

                          {
                            (account as IAccount).onboardingScore && (
                              <Typography variant="body2" sx={{ mb: 1 }}>
                                <b><Trans>account_profile_onboarding</Trans> :</b> { (account as IAccount).onboardingScore }
                              </Typography>
                            )
                          }
                        </Grid>
                      </Grid>
                    </CardContent>

                    <CardActions sx={{ display: 'flex', justifyContent: 'right' }}>
                      <Link
                        component="button"
                        onClick={() => this.handleOpen(account?.['@id'] as IRIString)}
                        disabled={isLoading}
                      >
                        {
                          isLoading ?
                            <CircularProgress size={16} /> :
                            <Trans>account_seemore_button</Trans>
                        }
                      </Link>
                    </CardActions>

                    <Modal
                      open={open}
                      onClose={() => this.handleClose()}
                    >
                      <Box className="modal">
                        <Typography variant="h5" sx={styles.modalTitle}>
                          <Trans>account_profile_title</Trans>
                        </Typography>

                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{ p: 2, flexBasis: '100%' }}>
                            <Grid container spacing={4}>
                              <Grid item xs={6}>
                                <small><Trans>account_profile_financial_knowledge_label</Trans></small>
                                <Typography variant="subtitle1">
                                  { dto.computedInformation.financialKnowledgeCategory }
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <small><Trans>account_profile_financial_experience_label</Trans></small>
                                <Typography variant="subtitle1">
                                  { dto.computedInformation?.financialExperienceCategory }
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <small><Trans>account_profile_appetite_label</Trans></small>
                                <Typography variant="subtitle1">
                                  { dto.computedInformation?.riskAppetiteCategory }
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <small><Trans>account_profile_capacity_label</Trans></small>
                                <Typography variant="subtitle1">
                                  { dto.computedInformation.investmentCapacityCategory }
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <small><Trans>account_profile_goals_label</Trans></small>
                                <Typography variant="subtitle1">
                                  {
                                    accountNeed ?
                                      accountNeed.goals.map((goal) => (
                                        <span key={goal}>
                                        - { this.getNameFromModels(goals, goal) }
                                          <br />
                                        </span>
                                      )) :
                                      <>-</>
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <small><Trans>account_profile_thematics_label</Trans>: </small>
                                {
                                  accountNeed?.percent !== undefined ? (
                                    <small style={{ fontWeight: 'bold' }}>{ accountNeed.percent }%</small>
                                  ) : ''
                                }
                                <Typography variant="subtitle1">
                                  {
                                    accountNeed ?
                                      accountNeed.investProfileCategories.map((investProfileCategory) => (
                                        <span key={investProfileCategory}>
                                        - { this.getNameFromModels(investProfileCategories, investProfileCategory) }
                                          <br />
                                        </span>
                                      )) :
                                      <>-</>
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <small><Trans>account_profile_patrimony_label</Trans></small>
                                <Typography variant="subtitle1">
                                  { dto.financialInformation.assetsInformation.total.toLocaleString() }
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                { this.renderAllIncomes(dto) }
                              </Grid>
                              <Grid item xs={6}>
                                <small><Trans>investment_goal</Trans></small>
                                <Typography variant="subtitle1">
                                  { dto.investmentInformation.duration } an{ dto.investmentInformation.duration && dto.investmentInformation.duration > 1 && <>s</> }
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <small><Trans>account_profile_capacity_savings_label</Trans></small>
                                <Typography variant="subtitle1">
                                  { dto.financialInformation.familyMonthlyIncome?.savingCapacityAmount?.toLocaleString() ?? '-' }
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <small><Trans>account_profile_loans_label</Trans></small>
                                <Typography variant="subtitle1">
                                  {
                                    dto.financialInformation.loansInformations && dto.financialInformation.loansInformations.length > 0 ?
                                      dto.financialInformation.loansInformations.map(
                                        (loan) => (
                                          <span key={loan.type}>
                                              - { this.getNameFromReferential(loanTypes, loan.type ?? '') } / { loan.amount }€ / { loan.duration } <Trans>months</Trans>
                                            <br />
                                          </span>
                                        ),
                                      ) : <span>-</span>
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <small><Trans>account_profile_beneficiary_clause_label</Trans></small>
                                <Typography variant="subtitle1">
                                  <Trans>{ dto.investmentInformation.beneficiaryClause }</Trans>
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <small><Trans>account_profile_update_date</Trans></small>
                                <Typography variant="subtitle1">
                                  <Trans>{ account?.lastProviderKycUpdateAt ? format(new Date(account.lastProviderKycUpdateAt), 'dd/MM/yyyy') : '-' }</Trans>
                                </Typography>
                              </Grid>
                              {
                                (account as IAccount).onboardingAnswers ?
                                  this.renderAccountOnboardingAnswers((account as IAccount).onboardingAnswers as Record<string, string>)
                                  : null
                              }
                            </Grid>

                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                              <Button
                                onClick={() => this.handleClose()}
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{ mt: 3 }}
                              >
                                <Trans>close_button</Trans>
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Modal>
                  </Card>
                )
              }
            </ReferentialContext.Consumer>
          )
        }
      </ClientInfoContext.Consumer>
    );
  }

  private renderAccountOnboardingAnswers(onboardingAnswers: string | Record<string, string>) {
    const parsedAnswers: Record<string, string> = 'string' === typeof onboardingAnswers ? JSON.parse(onboardingAnswers) : onboardingAnswers;
    const answersToArray = Object.entries(parsedAnswers).map(
      ([question, answer]) => ({ question, answer }),
    );

    return (
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Trans>account_profile_onboarding_questions</Trans></TableCell>
              <TableCell><Trans>account_profile_onboarding_answers</Trans></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              answersToArray.map(
                (answer, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{ answer.question }</TableCell>
                    <TableCell>{ answer.answer }</TableCell>
                  </TableRow>
                ),
              )
            }
          </TableBody>
        </Table>
      </Grid>
    );
  }

  private renderAllIncomes(dto: IUserKycMppDto) {
    const { familyMonthlyIncome, personalMonthlyIncome } = dto.financialInformation;
    const isMarriedButGoodsSharing = this.referentialService.isMarriedButGoodsSharing(dto.familyInformation.maritalStatus ?? '', dto.familyInformation.matrimonialRegime);

    return (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <small><Trans>{ isMarriedButGoodsSharing ? 'account_profile_income_label_family' : 'account_profile_income_label' }</Trans> :&nbsp;</small>
          <Typography variant="subtitle1">
            { familyMonthlyIncome?.total.toLocaleString() }
          </Typography>
        </Box>

        {
          (familyMonthlyIncome?.professional || familyMonthlyIncome?.asset || familyMonthlyIncome?.substitute)
            ? (
              <List sx={{ listStyleType: 'disc' }}>
                {
                  familyMonthlyIncome.professional ? (
                    <ListItem sx={{ display: 'list-item', marginLeft: 2, paddingLeft: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>{ familyMonthlyIncome.professional.toLocaleString() }</Typography>&nbsp;<small>: <Trans>as_professional_income</Trans></small>
                      </Box>
                    </ListItem>
                  ) : null
                }
                {
                  familyMonthlyIncome.asset ? (
                    <ListItem sx={{ display: 'list-item', marginLeft: 2, paddingLeft: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>{ familyMonthlyIncome.asset.toLocaleString() }</Typography>&nbsp;<small>: <Trans>as_patrimony_income</Trans></small>
                      </Box>
                    </ListItem>
                  ) : null
                }
                {
                  familyMonthlyIncome.substitute ? (
                    <ListItem sx={{ display: 'list-item', marginLeft: 2, paddingLeft: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>{ familyMonthlyIncome.substitute.toLocaleString() }</Typography>&nbsp;<small>: <Trans>as_substitute_income</Trans></small>
                      </Box>
                    </ListItem>
                  ) : null
                }
              </List>
            ) : null
        }

        {
          isMarriedButGoodsSharing ?
            (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <small><Trans>account_profile_income_label_personal</Trans> :&nbsp;</small>
                <Typography variant="subtitle1">
                  { personalMonthlyIncome?.total.toLocaleString() ?? '-' }
                </Typography>
              </Box>
            ) : null
        }
      </>
    );
  }

  private async handleOpen(accountIri: IAccount['@id']) {
    const { alreadyFetchAccountNeeds } = this.state;

    if (!alreadyFetchAccountNeeds) {
      this.setState({
        isLoading: true,
      });

      try {
        const response = await this.accountsService.getAccountNeeds(accountIri);
        const accountNeeds = response.data['hydra:member'];

        return this.setState({
          open: true,
          accountNeed: accountNeeds[0],
          isLoading: false,
          alreadyFetchAccountNeeds: true,
        });
      } catch {
        SnackbarService.open('failed_get_account_needs', 'error');
        this.setState({
          alreadyFetchAccountNeeds: true,
          isLoading: false,
        });
      }
    }

    this.setState({ open: true });
  }

  private handleClose() {
    this.setState({ open: false });
  }

  private getNameFromReferential(referentials: IReferentials, slug: string): string {
    return referentials.find((ref) => ref.slug === slug)?.label ?? '';
  }

  private getNameFromModels(models: { name?: string; '@id': IRIString }[], iri: IRIString): string {
    return models.find((model) => model['@id'] === iri)?.name ?? '';
  }
}

export default ProfilePanel;
