import { AxiosResponse } from 'axios';
import ICoupon from '../interfaces/ICoupon';
import ICouponAssignation from '../interfaces/ICouponAssignation';
import ICouponCategory from '../interfaces/ICouponCategory';
import { ICouponCreation } from '../interfaces/ICouponCreation';
import IIriArray from '../interfaces/IIriArray';
import { IRIString } from '../interfaces/IriType';
import ServiceConstructor from './ServiceConstructor';

class CouponService extends ServiceConstructor {
  public getCoupons(params?: URLSearchParams): Promise<AxiosResponse<IIriArray<ICoupon>>> {
    return this.getRessource('/v1/coupons', params);
  }

  public getCouponCategories(params?: URLSearchParams): Promise<AxiosResponse<IIriArray<ICouponCategory>>> {
    return this.getRessource('/v1/coupon_categories', params);
  }

  public assignCoupon(user: IRIString, coupon: IRIString): Promise<AxiosResponse> {
    return this.postRessource<unknown, ICouponAssignation>('/v1/user_coupons', {
      user,
      coupon,
    });
  }

  public createCoupon(coupon: ICouponCreation): Promise<AxiosResponse<unknown>> {
    return this.postRessource<unknown, ICouponCreation>('/v1/coupons', coupon);
  }

  public updateCoupon(coupon: ICouponCreation, couponUrl: IRIString): Promise<AxiosResponse<ICoupon>> {
    return this.patchRessource<ICoupon, ICouponCreation>(couponUrl, coupon);
  }

  public checkCouponValidity(couponCode: string): Promise<AxiosResponse<unknown>> {
    return this.getRessource(`/v1/validation/coupons/${ couponCode }`);
  }

  public deleteCoupon(userCouponIri: IRIString): Promise<AxiosResponse<unknown>> {
    return this.deleteRessource(userCouponIri);
  }
}

export default CouponService;
