import { AxiosRequestConfig, AxiosResponse } from 'axios';
import IIriArray from '../interfaces/IIriArray';
import IKycQuestion, { IKycQuestions } from '../interfaces/IKycQuestion';
import IProviderContractType from '../interfaces/IProviderContractType';
import { IUserKyc } from '../interfaces/IUserKyc';
import IUserKycAnswer, { IPostUserKycAnswer } from '../interfaces/IUserKycAnswer';
import { IRIString } from '../interfaces/IriType';
import createIriFromId from '../utils/createIriFromId';
import HttpQueueService from './HttpQueueService';
import ServiceConstructor from './ServiceConstructor';

class KycService extends ServiceConstructor {
  public getUserKyc(kycIri: IRIString): Promise<AxiosResponse<IUserKyc>> {
    return this.getRessource<IUserKyc>(kycIri);
  }

  public async getKycQuestionsByProviderContractTypes(providerContractTypesIds: IProviderContractType['id'][]): Promise<IKycQuestions | []> {
    try {
      const requests: AxiosRequestConfig[] = providerContractTypesIds.map(
        (contractTypeId) => ({
          method: 'GET',
          url: `${ createIriFromId('provider_contract_types', contractTypeId) }/kyc_questions`,
          signal: this.signal,
        }),
      );
      const responses = await HttpQueueService.makeMultipleRequests<IIriArray<IKycQuestion>>(requests);

      return responses.map((response) => response.data['hydra:member']).flat();
    }  catch (error) {
      console.debug(error);
      throw new Error('Failed');
    }
  }

  public postAnswer(answer: IPostUserKycAnswer): Promise<AxiosResponse<IUserKycAnswer>> {
    return this.postRessource<IUserKycAnswer, IPostUserKycAnswer>('/v1/user_kyc_answers', answer);
  }
}

export default KycService;
