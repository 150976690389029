import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { SxProps } from '@mui/material/styles';
import React from 'react';
import { createParamsObjectFromQuerystring } from '../../utils/createParamsObjectFromQueryString';
import { ICouponSearchBarProps } from './ICouponSearchBarProps';

const styles: { [key: string]: SxProps } = {
  wrapper: {
    padding: 1,
    display: 'flex',
    flexDirection: 'column',
  },
};

class CouponSearchBar extends React.PureComponent<ICouponSearchBarProps> {
  private debounceTimeout?: NodeJS.Timeout;

  public render() {
    return (
      <Box sx={styles.wrapper}>
        <Card sx={{ flex: 1 }} elevation={0}>
          <TextField
            autoFocus
            fullWidth
            placeholder="Recherche par code"
            sx={{ backgroundColor: 'white' }}
            onChange={this.handleQuickSearchFieldChange}
          />
        </Card>
      </Box>
    );
  }

  private handleQuickSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    this.debounceTimeout && clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      this.searchByCode(value);
    }, 1000);
  };

  private searchByCode(code: string) {
    const { setCoupons } = this.props;
    const params = createParamsObjectFromQuerystring();

    params.set('code', code);
    setCoupons(params);
  }
}

export default CouponSearchBar;
