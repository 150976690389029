import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import React from 'react';
import { Trans } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CouponCategoriesFilter from '../../components/CouponCategoriesFilter/CouponCategoriesFilter';
import CouponModal from '../../components/CouponModal/CouponModal';
import CouponSearchBar from '../../components/CouponSearchBar/CouponSearchBar';
import CouponTableRow from '../../components/CouponTableRow/CouponTableRow';
import PermissionChecker from '../../components/PermissionChecker';
import ColorConstant from '../../constants/ColorConstant';
import ITableList from '../../constants/ITableListConstant';
import Permission from '../../constants/Permission';
import PropertiesConstant from '../../constants/PropertiesConstant';
import TablePaginationConstants from '../../constants/TablePaginationConstant';
import ICoupon from '../../interfaces/ICoupon';
import { ICouponsFilterPossibility } from '../../interfaces/ICouponsFilterPossibility';
import CouponService from '../../services/CouponService';
import { createParamsObjectFromQuerystring } from '../../utils/createParamsObjectFromQueryString';
import { ICouponsPageState } from './ICouponsPageState';

const styles: { [key: string]: SxProps } = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  filters: {
    marginBottom: '32px',
  },

  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 0,
    height: '100%',
    borderRadius: 0,
  },

  tablecontainer: {
    flex: 1,
    overflowX: 'hidden',
  },

  table: {
    backgroundColor: ColorConstant.theme.palette.secondary.main,
  },
};

class CouponsPage extends React.Component<RouteComponentProps, ICouponsPageState> {
  public state: Readonly<ICouponsPageState> = {
    coupons: [],
    isLoading: false,
    tableList: {
      rowsPerPage: 25,
      page: 0,
      totalCoupons: 0,
      order: 'asc',
      orderBy: '',
    },
    couponCategories: [],
    isModalOpen: false,
    iscreatingCoupon: false,
    currentCouponToEdit: undefined,
  };

  private abortController = new AbortController();
  private couponService: CouponService;
  private tableHeaders = [
    {
      content: 'coupon_table_name',
      id: 'couponName',
      isOrderingPossibility: false,
    },
    {
      content: 'coupon_table_code',
      id: 'code',
      isOrderingPossibility: true,
    },
    {
      content: 'coupon_table_creation_date',
      id: 'createdAt',
      isOrderingPossibility: true,
    },
    {
      content: 'coupon_table_validity_discount',
      id: 'discountTimeInSeconds',
      isOrderingPossibility: true,
    },
    {
      content: '',
      id: 'actions',
      isOrderingPossibility: false,
    },
  ];
  private rowsPerPagePossibilities = PropertiesConstant.ROWS_PER_PAGE_POSSIBILITIES;

  constructor (props: RouteComponentProps) {
    super(props);
    this.couponService = new CouponService(this.abortController.signal);
  }

  public async componentDidMount() {
    try {
      const params = createParamsObjectFromQuerystring();
      const paramsIsEmpty = Array.from(params.entries()).length === 0;

      if (paramsIsEmpty) {
        const { tableList: { rowsPerPage, page } } = this.state;

        params.append('limit', rowsPerPage.toString());
        params.append('page', (page + 1).toString());
      } else {
        const isOrderedBy = Object.keys(params).find((property) => property.startsWith('order[')) as ICouponsFilterPossibility | undefined;
        const order = isOrderedBy ? (params.get('isOrderedBy') as 'asc' | 'desc') : 'asc';

        this.setState((previousState) => ({
          tableList: {
            ...previousState.tableList,
            page: parseInt(params.get('page') ?? '0') || 0,
            rowsPerPage: parseInt(params.get('limit') ?? '25') || 25,
            orderBy: (isOrderedBy?.slice(6, -1) as ICouponsFilterPossibility) ?? '',
            order,
            code: params?.get('code') || undefined,
          },
        }));
      }

      const [couponCategoriesResponse] = await Promise.all([
        this.fetchCouponCategories(),
        this.setCoupons(params),
      ]);

      this.setState({
        couponCategories: couponCategoriesResponse.data['hydra:member'],
      }, () => this.setCoupons(params));
    } catch (error) {
      console.debug(error);
    }
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const {
      couponCategories,
      isModalOpen,
      iscreatingCoupon,
      currentCouponToEdit,
      tableList: {
        rowsPerPage,
        page,
        totalCoupons,
      },
    } = this.state;

    return (
      <PermissionChecker permissions={[Permission.READ_COUPON_LIST]}>
        <Box sx={styles.wrapper}>
          <Box sx={styles.filters}>
            <CouponSearchBar setCoupons={this.setCoupons} />

            <CouponCategoriesFilter
              setCoupons={this.setCoupons}
              couponCategories={couponCategories}
            />
          </Box>

          <Paper sx={styles.tableWrapper}>
            <PermissionChecker permissions={[Permission.CREATE_COUPON_ITEM_ALL]} denied={<></>}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{ margin: 1 }}
                  variant="contained"
                  onClick={() => this.setIsModalOpen({ iscreatingCoupon: true })}
                >
                  <Trans>create_coupon</Trans> <AddIcon />
                </Button>
              </Box>
            </PermissionChecker>

            <TableContainer sx={styles.tablecontainer}>
              <Table sx={styles.table} stickyHeader>
                { this.renderTableHead() }
                { this.renderTableBody() }
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={totalCoupons}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={this.rowsPerPagePossibilities}
              labelRowsPerPage={<Trans>rows_per_page</Trans>}
              onRowsPerPageChange={(event) => this.onTablePaginationChange('limit',  event.target.value)}
              page={totalCoupons > 0 ? page : 0}
              onPageChange={(_, page: number) => this.onTablePaginationChange('page', (page + 1).toString())}
              sx={TablePaginationConstants.style}
            />
          </Paper>
        </Box>

        <CouponModal
          isModalOpen={isModalOpen}
          isCreating={iscreatingCoupon}
          handleModalClose={this.setIsModalOpen}
          coupon={currentCouponToEdit}
          couponCategories={couponCategories}
          setIsModalOpen={this.setIsModalOpen}
          setCoupons={this.setCoupons}
        />
      </PermissionChecker>
    );
  }

  private renderTableHead() {
    const { tableList: { order, orderBy } } = this.state;

    return (
      <TableHead>
        <TableRow>
          {
            this.tableHeaders.map((tableHeader) => tableHeader.isOrderingPossibility ? (
              <TableCell key={tableHeader.id}>
                <TableSortLabel
                  active={orderBy === tableHeader.id}
                  direction={orderBy === tableHeader.id ? order : 'asc'}
                  onClick={() => this.onTableSortChange(tableHeader.id)}
                >
                  <Typography fontWeight={'medium'}>
                    <Trans>{ tableHeader.content }</Trans>
                  </Typography>
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell key={tableHeader.id}>
                <Typography fontWeight={'medium'}>
                  <Trans>{ tableHeader.content }</Trans>
                </Typography>
              </TableCell>
            ),
            )
          }
        </TableRow>
      </TableHead>
    );
  }

  private renderTableBody() {
    const { isLoading } = this.state;

    return (
      <TableBody sx={{ overflowY: 'scroll' }}>
        {
          isLoading ? (
            <TableRow>
              <TableCell colSpan={this.tableHeaders.length + 1} align="center">
                <CircularProgress size={60} />
              </TableCell>
            </TableRow>
          ) : this.renderCoupons()
        }
      </TableBody>
    );
  }

  private renderCoupons() {
    const { coupons } = this.state;

    return (
      coupons.length > 0 ?
        coupons.map(
          (coupon) => (
            <CouponTableRow
              key={coupon.id}
              coupon={coupon}
              setIsModalOpen={this.setIsModalOpen}
            />
          ),
        ) : (
          <TableRow>
            <TableCell colSpan={this.tableHeaders.length + 1} align="center">
              <Typography fontWeight={'normal'} fontSize={14}>
                <Trans>no_coupon_found</Trans>
              </Typography>
            </TableCell>
          </TableRow>
        )
    );
  }

  private setIsModalOpen = (args?: {iscreatingCoupon?: boolean; currentCouponToEdit?: ICoupon}) => {
    this.setState(
      (previousState) => (
        {
          isModalOpen: !previousState.isModalOpen,
          iscreatingCoupon: args?.iscreatingCoupon ?? previousState.iscreatingCoupon,
          currentCouponToEdit: args?.currentCouponToEdit ?? previousState.currentCouponToEdit,
        }
      ),
    );
  };

  private onTableSortChange = (tableHeaderId: string) => {
    const { tableList: { order, orderBy } } = this.state;
    const isAsc = orderBy === tableHeaderId && order === 'asc';

    this.onTableListChange({
      order: isAsc ? 'desc' : 'asc',
      orderBy: tableHeaderId as ICouponsFilterPossibility,
    });
  };

  private onTablePaginationChange = (paramProperty: string, paramValue: string) => {
    const params = createParamsObjectFromQuerystring();

    params.set(paramProperty, paramValue);
    return this.setCoupons(params);
  };

  private onTableListChange = async (newTableList: Partial<ICouponsPageState['tableList']>) => {
    const { isLoading, tableList: { orderBy, order } } = this.state;

    if (isLoading) {
      return;
    }

    try {
      const params = createParamsObjectFromQuerystring();

      for (const pairAndValue of Object.entries(newTableList)) {
        const [pair, value] = pairAndValue as [keyof ITableList, string | number];

        'rowsPerPage' === pair && params.set('limit', value.toString());
        'page' === pair && params.set('page', (+value + 1).toString());
        'order' !== pair && 'orderBy' !== pair && params.set(pair, value.toString());
      }

      if (newTableList.orderBy) {
        if (orderBy) {
          params.delete(`order[${ orderBy }]`);
        }
        params.set(`order[${ newTableList.orderBy }]`, newTableList.order || order);
      }

      this.setCoupons(params);

      this.setState(({ tableList }) => ({
        tableList: {
          ...tableList,
          ...newTableList,
        },
      }));
    } catch (error) {
      console.debug(error);
    }
  };

  private updateUrl(params?: URLSearchParams) {
    if (params) {
      const { history } = this.props;

      if (history.location.search.slice(1) !== params.toString()) {
        history.replace({
          pathname: history.location.pathname,
          search: params.toString(),
        });
      }
    }
  }

  private fetchCoupons = (params?: URLSearchParams) => this.couponService.getCoupons(params);

  private fetchCouponCategories = () => {
    const params = new URLSearchParams();

    params.append('limit', '200');
    return this.couponService.getCouponCategories(params);
  };

  private setCoupons = async (params?: URLSearchParams) => {
    this.setState((previousState) => ({
      ...previousState,
      isLoading: true,
    }));

    try {
      const response = await this.fetchCoupons(params);
      const coupons = response.data['hydra:member'];

      this.setState((previousState) => ({
        ...previousState,
        coupons,
        isLoading: false,
        tableList: {
          ...previousState.tableList,
          totalCoupons: response.data['hydra:totalItems'],
          page: (parseInt(params?.get('page') || '') - 1) || 0,
          rowsPerPage: parseInt(params?.get('limit') || '') || 25,
          code: params?.get('code') || undefined,
        },
      }));

      this.updateUrl(params);

      return true;
    } catch (error) {
      console.debug('Can\'t load coupons', error);
      this.setState((previousState) => ({
        ...previousState,
        isLoading: false,
      }));

      return false;
    }
  };
}

export default withRouter(CouponsPage);
