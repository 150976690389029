const ColorConstant = {
  theme: {
    palette: {
      primary: {
        main: '#4cbf17',
        dark: '#359b07',
        contrastText: '#fff',
      },
      secondary: {
        main: '#fff',
        contrastText: '#4D4D4D',
      },
      lightGray: '#E9E9E9',
      darkGray: '#4D4D4D',
      warning: {
        main: '#f44336',
      },
      gray: '#A9A9A9',
    },
    typography: {
      allVariants: {
        color: '#4D4D4D',
      },
      fontFamily: [
        'Roboto',
      ].join(','),
    },
  },
  primary: '#4cbf17',
  secondary: '#359b07',
  lightGray: '#E9E9E9',
  disabledGray: '#E0E0E0',
  borderColor: '#E7E7E7',
  darkGray: '#4D4D4D',
  gray: '#A9A9A9',
  blueMinor: '#229AA1',
  lightBlue: '#34AFF7',
} as const;

export default ColorConstant;
