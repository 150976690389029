import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Badge, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { format, isValid } from 'date-fns';
import React from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ColorConstant from '../../constants/ColorConstant';
import { ProviderContractTypesConstant } from '../../constants/ProviderContractTypesConstant';
import ReferentialContext from '../../contexts/ReferentialContext';
import WindowNavigationService from '../../services/WindowNavigationService';
import isMinor from '../../utils/isMinor';
import AccountsListIconMenu from '../AccountsListIconMenu/AccountsListIconMenu';
import IAccountsListTableBodyRowProps from './IAccountsListTableBodyRowProps';
import IAccountsListTableBodyRowState from './IAccountsListTableBodyRowState';

const styles: { [x: string]: SxProps } = {
  row: {
    position: 'relative',
    cursor: 'pointer',
  },
};
const foreignStyles: SxProps = {
  '::before': {
    position: 'absolute',
    left: 0,
    top: 0,
    content: '" "',
    height: '100%',
    width: '16px',
    display: 'block',
    marginRight: 2,
    backgroundColor: 'rgba(255, 255, 0, 0.5)',
  },
};
const minorStyles: SxProps = {
  '::before': {
    position: 'absolute',
    left: 0,
    top: 0,
    content: '" "',
    height: '100%',
    width: '16px',
    display: 'block',
    marginRight: 2,
    backgroundColor: ColorConstant.blueMinor,
  },
};

class AccountsListTableBodyRow extends React.PureComponent<IAccountsListTableBodyRowProps, IAccountsListTableBodyRowState> {
  public readonly state: IAccountsListTableBodyRowState = {
    anchorEl: null,
    anchorDivRefPosition: {
      X: 0,
      Y: 0,
    },
    isHovering: false,
  };

  private windowNavigationService = new WindowNavigationService();
  private anchorDivRef = React.createRef<HTMLDivElement>();

  public render() {
    const { account } = this.props;
    const { anchorEl, anchorDivRefPosition } = this.state;

    return (
      <TableRow
        onClick={this.handleTableRowClick}
        onContextMenu={this.handleContextMenu}
        onAuxClick={this.handleTableRowClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        hover
        sx={styles.row}
      >
        { this.renderContent() }

        <AccountsListIconMenu
          accountId={account.id}
          anchorEl={anchorEl}
          handleIconClick={this.handleIconClick}
          handleCloseMenu={this.handleCloseMenu}
        />

        <TableCell
          ref={this.anchorDivRef}
          sx={{
            position: 'fixed',
            padding: 0,
            left: anchorDivRefPosition.X,
            top: anchorDivRefPosition.Y,
          }}
        />
      </TableRow>
    );
  }

  private handleTableRowClick = (event: React.MouseEvent) => {
    if (event.button > 1) {
      return;
    }

    const { history, account } = this.props;
    const pathname = `/accounts/${ account.id }/client-info`;

    this.windowNavigationService.tryAltOpen(event, pathname) || history.push(pathname);
  };

  private handleIconClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  private handleCloseMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  private handleContextMenu = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    event.preventDefault();
    const { anchorEl } = this.state;

    if (anchorEl) {
      return this.setState({ anchorEl: null });
    }

    this.setState({
      anchorEl: this.anchorDivRef.current,
      anchorDivRefPosition: {
        X: event.clientX,
        Y: event.clientY,
      },
    });
  };

  private handleEmailIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    const { email } = this.props;

    navigator.clipboard.writeText(email);
  };

  private handleMouseEnter = () => {
    this.setState({ isHovering: true });
  };

  private handleMouseLeave = () => {
    this.setState({ isHovering: false });
  };

  private renderContent() {
    const {
      account,
      providerContractTypeSlug,
      email,
      favoriteColumns,
    } = this.props;
    const { isHovering } = this.state;
    const providerContractTypeId = account.providerContractType?.split('/').pop() as ProviderContractTypesConstant | undefined;
    const minor = providerContractTypeId ? isMinor(providerContractTypeId) : false;
    const cellsToDisplay: (string | number | boolean | string[] | undefined)[] = [providerContractTypeSlug, email];

    for (const column of favoriteColumns) {
      !cellsToDisplay.includes(column) && cellsToDisplay.push(account[column as keyof typeof account]);
    }

    return cellsToDisplay.map((property, idx) => (
      <TableCell key={idx} sx={idx === 0 ? this.getTableCellStyle(minor, account.foreigner) : null}>
        <ReferentialContext.Consumer>
          {
            ({ currentSteps, advisors }) => (
              <Typography fontWeight="normal" fontSize={14} sx={{ pl: property === providerContractTypeSlug ? '4px' : 0, whiteSpace: 'nowrap' }}>
                {
                  (() => {
                    switch (property) {
                      case email:
                        return (
                          <>
                            { property }
                            <Tooltip title={<Trans>copy</Trans>}>
                              <IconButton onClick={this.handleEmailIconClick}>
                                <ContentCopyIcon
                                  fontSize="small"
                                  sx={{ opacity: +isHovering }}
                                />
                              </IconButton>
                            </Tooltip>
                          </>
                        );
                      case account.phone:
                        return (
                          property ? (
                            <Link
                              href={`tel:${ property }`}
                              rel="noreferrer"
                              onClick={(e) => e.stopPropagation()}
                              color="inherit"
                            >
                              { property }
                            </Link>
                          ) : '-'
                        );
                      case account.couponCodes:
                        if (property && property.length > 0) {
                          const remainingCoupons = property.length - 1;

                          return (
                            <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box component="span" sx={{ pr: 3 }}>{ property[0] }</Box>
                              { remainingCoupons > 0 && (
                                <Badge badgeContent={`+${ remainingCoupons }`}
                                  sx={{ '& .MuiBadge-badge': { backgroundColor: ColorConstant.disabledGray } }}
                                />
                              ) }
                            </Box>
                          );
                        } else {
                          return '-';
                        }
                      case account.createdAt:
                      case account.lastLoginAt:
                      case account.signatureDate:
                      case account.videoUploadedAt:
                        const date = property ? new Date(property as string) : null;

                        return isValid(date) ? format(date as Date, 'dd/MM/yyyy') : '-';
                      case account.initialInvestmentFirst:
                      case account.initialInvestmentMonthly:
                        return property ? `${ property.toString() }€` : '-';
                      case account.advisor:
                        return advisors.find((advisor) => advisor['@id'] === account.advisor)?.firstname || '-';
                      case account.currentStep:
                        return currentSteps.find((currentStep) => currentStep.slug === account.currentStep)?.label || '-';
                      default:
                        return property || '-';
                    }
                  })()
                }
              </Typography>
            )
          }
        </ReferentialContext.Consumer>
      </TableCell>
    ));
  }

  private getTableCellStyle = (minor: boolean, foreigner: boolean): SxProps | null => {
    if (minor) {
      return minorStyles;
    }
    if (foreigner) {
      return foreignStyles;
    }

    return null;
  };
}

export default withRouter(AccountsListTableBodyRow);
