import keycloakAuth from '@mppteam/mpp-keycloak-auth';
import axios from 'axios';
import { t } from 'i18next';
import AuthService from './AuthService';
import SnackbarService from './SnackbarService';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_MPP_API_URL,
});

httpClient.defaults.headers.common['Content-Type'] = 'application/ld+json';

httpClient.interceptors.request.use(async (request) => {
  const accessToken = keycloakAuth.getAccessToken();

  if (!request?.url?.match('gouv.fr') && !request.headers?.['Authorization'] && null !== accessToken) {
    if (request.headers) {
      if (keycloakAuth.isSessionExpired() && request.url !== `${ process.env.REACT_APP_KEYCLOAK_API_URL }/realms/${ process.env.REACT_APP_KEYCLOAK_REALM }/protocol/openid-connect/token`) {
        console.debug('refresh');
        const refreshToken = keycloakAuth.getRefreshToken();

        refreshToken && await AuthService.refreshToken(refreshToken);
      }

      request.headers['authorization'] = `Bearer ${ accessToken }`;
      request.headers['version'] = process.env['REACT_APP_VERSION'] ?? 'localhost';
      if (request.method === 'patch') {
        request.headers['content-type'] = 'application/merge-patch+json';
      }
    }
  }

  return request;
});

httpClient.interceptors.response.use((response) => response, async (error) => {
  const { response, request, message } = error;

  if (message === 'canceled') {
    return Promise.reject(error);
  }

  if ((!request.responseURL.match(/\/token/) || response?.config?.data?.includes('grant_type=refresh_token')) &&
    ((response?.status === 401 && response.data.error !== 'invalid_grant')
      || ([401, 400].includes(response?.status) && response?.data.error === 'invalid_grant'))) {
    keycloakAuth.cleanSession(['accountsListFavoriteColumns']);
  }

  if ([403].includes(response?.status) && response.config.method === 'post') {
    SnackbarService.open(t('permission_denied'), 'error');
  }

  return Promise.reject(error);
});

export { httpClient };
