import Alert, { AlertColor } from '@mui/material/Alert';
import Paper from '@mui/material/Paper/Paper';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import ISnackBarAlertState from './ISnackBarAlertState';

class SnackBarAlert extends React.Component<unknown, ISnackBarAlertState> {
  public readonly state: ISnackBarAlertState = {
    open: false,
    message: '',
    severity: undefined,
  };

  public componentDidMount(): void {
    document.addEventListener('snackbar', (event) => {
      this.setState({
        open: true,
        message: (event as CustomEvent<{ message?: string }>).detail.message,
        severity: (event as CustomEvent<{ severity?: AlertColor }>).detail.severity,
      });
    });
  }

  public render(): ReactElement {
    const { severity, message, open } = this.state;

    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        TransitionComponent={this.leftSlide}
      >
        <Paper elevation={24}>
          <Alert onClose={this.handleClose} severity={severity} sx={{ width: '100%' }}>
            <Trans>{ message }</Trans>
          </Alert>
        </Paper>
      </Snackbar>
    );
  }

  private leftSlide(props: SlideProps) {
    return <Slide {...props} direction="right" />;
  }

  private handleClose = (_: unknown, reason?: SnackbarCloseReason): void => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      open: false,
    });
  };
}

export default SnackBarAlert;
