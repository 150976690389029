import { AxiosResponse } from 'axios';
import { IRIString } from '../interfaces/IriType';
import IUserKycMppDto from '../interfaces/UserKycMppDto/IUserKycMppDto';
import ServiceConstructor from './ServiceConstructor';

class DtoService extends ServiceConstructor {
  public getDto(userKycIri: IRIString): Promise<AxiosResponse<IUserKycMppDto>> {
    return this.getRessource<IUserKycMppDto>(`${ userKycIri }/information`);
  }
}

export default DtoService;
