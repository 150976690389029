import { IInvestOrder } from './IInvestOrder';
import { IInvestProfileCategory } from './IInvestProfileCategory';
import { OrderStatus } from './IOrder';
import { IProduct } from './IProduct';
import { IRIString, IRIType } from './IriType';

export interface IInvestRequest extends IRIType {
  id: string;
  frequency: InvestRequestFrequency;
  type: InvestRequestType;
  referenceMpp?: number;
  reference?: string;
  referenceProvider?: string;
  status: OrderStatus;
  error?: string;
  amountOrdered: number;
  originFunds?: { slug: string; percent: number; date: Date | null; details?: string }[];
  consentGivenAt?: string;
  taxOption?: string;
  investRequestDetails: IInvestRequestDetails;
  userInvestmentAccount: IRIString;
  investOrders: Pick<IInvestOrder, '@id' | '@context' | '@type' | 'id' | 'processedAt' | 'valuatedAt' | 'userInvestmentAccount'>[];
  currentPlace: string;
  reason?: IRIString;
  reasonDetail?: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  abandoned: boolean;
}

export type IInvestRequests = IInvestRequest[];

export interface IInvestRequestDetail extends IRIType {
  id: string;
  type: string;
  amountOrdered: number;
  product?: IProductInInvestRequestDetail;
  investProfile?: Pick<IInvestProfileCategory, '@id' | '@context' | '@type' | 'id' | 'name' | 'slug'>;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export type IProductInInvestRequestDetail = Pick<IProduct, '@id' | '@context' | '@type' | 'id' | 'name' | 'slug' | 'isin' | 'generaliAmendmentUri' | 'apicilAmendmentUri' | 'lfmAmendmentUri' | 'createdAt' | 'updatedAt' | 'uc'>;

export type IInvestRequestDetails = IInvestRequestDetail[];

export enum InvestRequestType {
  TYPE_INITIAL_INVESTMENT = 'initial-investment',
  TYPE_FREE_INVESTMENT = 'free-investment',
  TYPE_PARTIAL_SELL = 'partial-sell',
  TYPE_TOTAL_SELL = 'total-sell',
  TYPE_ARBITRATION = 'exchange',
  TYPE_START_MONTHLY_INVESTMENT = 'start-monthly-investment',
  TYPE_UPDATE_MONTHLY_INVESTMENT = 'update-monthly-investment',
  TYPE_STOP_MONTHLY_INVESTMENT = 'stop-monthly-investment'
}

export const INVEST_REQUEST_TYPES = {
  [InvestRequestType.TYPE_INITIAL_INVESTMENT]: 'buy',
  [InvestRequestType.TYPE_FREE_INVESTMENT]: 'buy',
  [InvestRequestType.TYPE_START_MONTHLY_INVESTMENT]: 'start-monthly-investment',
  [InvestRequestType.TYPE_UPDATE_MONTHLY_INVESTMENT]: 'update-monthly-investment',
  [InvestRequestType.TYPE_PARTIAL_SELL]: 'sell',
  [InvestRequestType.TYPE_TOTAL_SELL]: 'sell',
  [InvestRequestType.TYPE_STOP_MONTHLY_INVESTMENT]: 'stop-monthly-investment',
  [InvestRequestType.TYPE_ARBITRATION]: 'exchange',
};

export enum InvestRequestFrequency {
  FREQUENCY_PONCTUAL = 'ponctual',
  FREQUENCY_PERIODIC = 'periodic'
}
