import { OrderStatus } from './IOrder';
import { IProduct } from './IProduct';
import { IRIString, IRIType } from './IriType';

export interface IInvestOrder extends IRIType {
  id: string;
  reference?: string;
  type: string;
  subType?: InvestOrderSubType;
  status: OrderStatus;
  amountOrdered: number;
  amountDebited: number;
  processedAt?: Date | string;
  valuatedAt?: Date | string;
  userInvestmentAccount?: IRIString;
  investOrderDetails: IInvestOrderDetails;
  userInvestmentAccountAmount?: IRIString;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export type IInvestOrders = IInvestOrder[];

export interface IInvestOrderDetail extends IRIType {
  id: string;
  type: string;
  status: string;
  amountOrdered: number;
  amountDebited?: number;
  securityUnitReceived?: string;
  securityUnitValue?: number;
  product?: IProductInInvestOrderDetail;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export type IProductInInvestOrderDetail = Pick<IProduct, '@id' | '@context' | '@type' | 'id' | 'name' | 'slug' | 'isin' | 'createdAt' | 'updatedAt' | 'investProfiles' | 'uc'>;

export enum InvestOrderSubType {
  SUBTYPE_ARBITRATION = 'exchange',
  SUBTYPE_DIVIDENDS_DISTRIBUTION = 'dividends',
  SUBTYPE_FEES = 'fees',
  SUBTYPE_FREE_INVESTMENT = 'free-investment',
  SUBTYPE_INITIAL_INVESTMENT = 'initial-investment',
  SUBTYPE_MONTHLY_INVESTMENT = 'monthly-investment',
  SUBTYPE_OST = 'automatic-exchange',
  SUBTYPE_PARTIAL_SELL = 'partial-sell',
  SUBTYPE_REFUND = 'refund',
  SUBTYPE_TOTAL_SELL = 'total-sell',
  SUBTYPE_UNKNOWN = 'unknown'
}

export type IInvestOrderDetails = IInvestOrderDetail[];
