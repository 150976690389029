import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button, Checkbox, Divider, Drawer, FormControl, FormControlLabel, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import { format, isValid, parse } from 'date-fns';
import { t } from 'i18next';
import { ChangeEvent, Component } from 'react';
import { Trans } from 'react-i18next';
import ReferentialContext from '../../contexts/ReferentialContext';
import { createParamsObjectFromQuerystring } from '../../utils/createParamsObjectFromQueryString';
import FromDateToDate from '../FromDateToDate/FromDateToDate';
import { IAccountsAdvancedSearchProps } from './IAccountsAdvancedSearchProps';
import { IAccountsAdvancedSearchState } from './IAccountsAdvancedSearchState';

const styles: { [key: string]: SxProps } = {
  formLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  filterSideBarWrapper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
};

export default class AccountsAdvancedSearch extends Component<IAccountsAdvancedSearchProps, IAccountsAdvancedSearchState> {
  constructor(props: IAccountsAdvancedSearchProps) {
    super(props);
    const params = createParamsObjectFromQuerystring();
    const signatureDateAfter = params.get('signatureDate[after]');
    const signatureDateBefore = params.get('signatureDate[before]');
    const lastLoginAtAfter = params.get('lastLoginAt[after]');
    const lastLoginAtBefore = params.get('lastLoginAt[before]');
    const videoUploadedAtBefore = params.get('videoUploadedAt[before]');
    const videoUploadedAtAfter = params.get('videoUploadedAt[after]');
    const hidden = !!params.get('hidden');

    this.state = {
      filtersSideBarIsOpen: false,
      dateFieldsValues: {
        'signatureDate[after]': signatureDateAfter ? new Date(signatureDateAfter) : undefined,
        'signatureDate[before]': signatureDateBefore ? new Date(signatureDateBefore) : undefined,
        'lastLoginAt[after]': lastLoginAtAfter ? new Date(lastLoginAtAfter) : undefined,
        'lastLoginAt[before]': lastLoginAtBefore ? new Date(lastLoginAtBefore) : undefined,
        'videoUploadedAt[before]': videoUploadedAtBefore ? new Date(videoUploadedAtBefore) : undefined,
        'videoUploadedAt[after]': videoUploadedAtAfter ? new Date(videoUploadedAtAfter) : undefined,
      },
      hidden,
    };
  }

  public render() {
    const { filtersSideBarIsOpen, dateFieldsValues, hidden } = this.state;

    return (
      <>
        <Tooltip title={<Trans>filters</Trans>}>
          <IconButton onClick={() => this.handleFiltersSideBar(true)}>
            <FilterAltIcon fontSize="large" />
          </IconButton>
        </Tooltip>

        <ReferentialContext.Consumer>
          {
            () => (
              <Drawer
                anchor="right"
                open={filtersSideBarIsOpen}
                onClose={() => this.handleFiltersSideBar(false)}
              >
                <Box sx={styles.filterSideBarWrapper}>
                  <Box sx={styles.formLine}>
                    <Typography padding={2} fontWeight="medium"><Trans>advanced_research</Trans></Typography>
                    <Box>
                      <IconButton onClick={() => this.handleFiltersSideBar(false)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ padding: 2, overflowY: 'auto', flex: 1 }}>
                    <FormControl sx={{ width: '100%', gap: 2 }}>
                      { /* Signature date */ }
                      <FromDateToDate
                        legend={t('signature_date')}
                        fromDateLabel={<Trans>from_date</Trans>}
                        toDatelabel={<Trans>to_date</Trans>}
                        fromDateValue={dateFieldsValues['signatureDate[after]']}
                        toDateValue={dateFieldsValues['signatureDate[before]']}
                        handleDateValueChange={this.handleDateValueChange}
                        fieldNames={['signatureDate[after]', 'signatureDate[before]']}
                        displayInLine
                      />

                      { /* Connection date */ }
                      <FromDateToDate
                        legend={t('connection_date')}
                        fromDateLabel={<Trans>from_date</Trans>}
                        toDatelabel={<Trans>to_date</Trans>}
                        fromDateValue={dateFieldsValues['lastLoginAt[after]']}
                        toDateValue={dateFieldsValues['lastLoginAt[before]']}
                        handleDateValueChange={this.handleDateValueChange}
                        fieldNames={['lastLoginAt[after]', 'lastLoginAt[before]']}
                        displayInLine
                      />

                      { /* Video Upload date */ }
                      <FromDateToDate
                        legend={t('accounts_advanced_search_upload_date')}
                        fromDateLabel={<Trans>from_date</Trans>}
                        toDatelabel={<Trans>to_date</Trans>}
                        fromDateValue={dateFieldsValues['videoUploadedAt[after]']}
                        toDateValue={dateFieldsValues['videoUploadedAt[before]']}
                        handleDateValueChange={this.handleDateValueChange}
                        fieldNames={['videoUploadedAt[after]', 'videoUploadedAt[before]']}
                        displayInLine
                      />

                      <Box sx={{ display: 'flex', alignContent: 'center' }}>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={hidden}
                              onChange={(_: ChangeEvent<HTMLInputElement>, checked: boolean) => this.setState({ hidden: checked })}
                            />
                          )}
                          label={t('accounts_advanced_search_hidden_accounts')}
                        />
                      </Box>
                    </FormControl>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button sx={{ margin: 1 }} onClick={this.clearFilters}>
                      <Trans>clear_filters</Trans>
                    </Button>
                    <Button
                      sx={{ margin: 1 }}
                      onClick={this.applyFilters}
                      variant="contained"
                    >
                      <Trans>apply</Trans>
                    </Button>
                  </Box>
                </Box>
              </Drawer>
            )
          }
        </ReferentialContext.Consumer>
      </>
    );
  }

  private handleFiltersSideBar = (isOpen: boolean) => {
    if (isOpen) {
      const { dateFieldsValues } = this.state;
      const dateFieldValuesCopy = { ...dateFieldsValues };
      const params = createParamsObjectFromQuerystring();

      for (const [paramName, paramValue] of Array.from(params.entries())) {
        if (Object.keys(dateFieldsValues).includes(paramName)) {
          dateFieldValuesCopy[paramName as keyof IAccountsAdvancedSearchState['dateFieldsValues']] = parse(paramValue, 'dd-MM-yyyy', new Date());
        }
      }

      return this.setState({
        dateFieldsValues: {
          ...dateFieldsValues,
          ...dateFieldValuesCopy,
        },
        filtersSideBarIsOpen: isOpen,
      });
    }

    this.setState({ filtersSideBarIsOpen: isOpen });
  };

  private applyFilters = () => {
    const { getUserInvestmentAccountInformations } = this.props;
    const { dateFieldsValues, hidden } = this.state;
    const params = new URLSearchParams({});

    for (const key in dateFieldsValues) {
      if (Object.prototype.hasOwnProperty.call(dateFieldsValues, key)) {
        const value = dateFieldsValues[key as keyof IAccountsAdvancedSearchState['dateFieldsValues']];

        params.append(key, value ? format(new Date(value), 'dd-MM-yyyy') : '');
      }
    }

    params.append('hidden', hidden ? hidden.toString() : '');

    getUserInvestmentAccountInformations(params);
    this.setState({
      filtersSideBarIsOpen: false,
    });
  };

  private handleDateValueChange = (fieldName: keyof IAccountsAdvancedSearchState['dateFieldsValues']) => (event: Date | null) => {
    if (!event) {
      return;
    }

    isValid(new Date(event)) && this.setState(
      (previousState) => ({
        dateFieldsValues: {
          ...previousState.dateFieldsValues,
          [fieldName]: event,
        },
      }),
    );
  };

  private clearFilters = (): void => {
    const { updateUrl } = this.props;
    const params = createParamsObjectFromQuerystring();
    const { dateFieldsValues } = this.state;
    const paramsKeys = Object.keys(dateFieldsValues) as (keyof IAccountsAdvancedSearchState['dateFieldsValues'])[];

    this.setState({
      dateFieldsValues: paramsKeys.reduce<IAccountsAdvancedSearchState['dateFieldsValues']>((acc, key) => {
        acc[key] = undefined;

        return acc;
      }, {}),
    });

    for (const key of paramsKeys) {
      if (params.has(key)) {
        params.delete(key);
      }
    }

    updateUrl(params);
  };
}
