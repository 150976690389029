import Box from '@mui/material/Box';
import React, { PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import IPermissionCheckerProps from '../interfaces/IPermissionProps';
import UserService from '../services/UserService';

export default class PermissionChecker extends React.PureComponent<PropsWithChildren<IPermissionCheckerProps>> {
  private userService = new UserService();

  public render() {
    const { children, permissions, denied } = this.props;

    return (
      <>
        { this.userService.hasPermissions(permissions) ? children : denied ?? this.renderAccessDenied() }
      </>
    );
  }

  private renderAccessDenied() {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Trans>access_denied</Trans>
      </Box>
    );
  }
}
