enum Reasons {
  REASON_LETTRE_MISSION_MPP = 'lettre-mission-mpp',
  REASON_FORMULAIRE_OUVERTURE_NATIXIS = 'formulaire-ouverture-natixis',
  REASON_QCC_SMONEY = 'qcc-smoney',
  REASON_ATTESTATION_SMONEY_NATIXIS = 'attestation-smoney-natixis',
  REASON_ATTESTATION_SUR_HONNEUR_TIERS = 'attestation-sur-honneur-tiers',
  REASON_CARTE_IDENTITE_FR = 'carte-identite-fr',
  REASON_CARTE_IDENTITE_TIERS_FR = 'carte-identite-tiers-fr',
  REASON_DRIVING_LICENCE = 'driving-licence',
  REASON_PASSEPORT_FR = 'passeport-fr',
  REASON_TITRE_SEJOUR_FR = 'titre-sejour-fr',
  REASON_JUSTIFICATIF_DOMICILE = 'justificatif-domicile',
  REASON_JUSTIFICATIF_DOMICILE_TIERS = 'justificatif-domicile-tiers',
  REASON_RAPPORT_ORDRE = 'rapport-ordre',
  REASON_RELEVE_COMPTE_TRIMESTRIEL = 'releve-compte-trimestriel',
  REASON_MISSION_REPORT = 'mission-report',
  REASON_ADVICE_GENERALI = 'advice-generali',
  REASON_ADVICE = 'advice',
  REASON_BULLETIN_SOUSCRIPTION_GENERALI = 'bulletin-souscription-generali',
  REASON_BULLETIN_SOUSCRIPTION_APICIL = 'bulletin-souscription-apicil',
  REASON_BULLETIN_SOUSCRIPTION_LFM = 'bulletin-souscription-la-france-mutualiste',
  REASON_BULLETIN_AVENANT_GENERALI = 'bulletin-avenant-generali',
  REASON_BULLETIN_AVENANT_APICIL = 'bulletin-avenant-apicil',
  REASON_BULLETIN_AVENANT_LFM = 'bulletin-avenant-la-france-mutualiste',
  REASON_ADEQUACY_REPORT = 'rapport-adequation',
  REASON_BILLING = 'billing',
  REASON_IBAN = 'iban',
  REASON_W9 = 'w-9',
  REASON_DICI = 'product-dici',
  REASON_IMPACT_REPORT = 'impact-report',
  REASON_FUNDING_PROOF = 'funding-proof',
  REASON_MPP_SUBSCRIPTION_DOCUMENTS = 'mpp-subscription-documents',
  REASON_SEPA_MANDATE = 'sepa-mandate',
  REASON_ANNUAL_FEES = 'annual-fees',
  REASON_ANNUAL_SITUATION_STATEMENT = 'annual-situation-statement'
}

export default Reasons;
