import { AxiosResponse } from 'axios';
import { Permissions } from '../constants/Permission';
import ICoupon from '../interfaces/ICoupon';
import IIriArray from '../interfaces/IIriArray';
import { IMoneyPot } from '../interfaces/IMoneyPot';
import IUser from '../interfaces/IUser';
import { IUserCoupon } from '../interfaces/IUserCoupon';
import { IRIString } from '../interfaces/IriType';
import PermissionsService from './PermissionsService';
import ServiceConstructor from './ServiceConstructor';

class UserService extends ServiceConstructor {
  public connectedUser: IUser | null = null;

  public getUser(userIri: IRIString): Promise<AxiosResponse<IUser>> {
    return this.getRessource<IUser>(userIri);
  }

  public async getConnectedUser(): Promise<void> {
    const response = await this.getRessource<IUser>('/v1/me');

    this.connectedUser = response.data;
  }

  public synchronizeOrder(accountIri: IRIString): Promise<AxiosResponse> {
    return this.postRessource(`${ accountIri }/synchronization`, {});
  }

  public blacklistUser(userIri: IRIString): Promise<AxiosResponse> {
    return this.patchRessource<unknown, { blacklisted: true }>(`${ userIri }/blacklist-sms`, { blacklisted: true });
  }

  public getCoupons(userIri: IRIString): Promise<AxiosResponse<IIriArray<ICoupon>>> {
    return this.getRessource<IIriArray<ICoupon>>(`${ userIri }/coupons`);
  }

  public getUserCoupons(userIri: IRIString): Promise<AxiosResponse<IIriArray<IUserCoupon>>> {
    return this.getRessource<IIriArray<IUserCoupon>>(`${ userIri }/user_coupons`);
  }

  public deleteUser(userIri: IRIString): Promise<AxiosResponse<void>> {
    return this.deleteRessource(`${ userIri }/anonymise`);
  }

  /**
   * Check whether if user possesses the passed permissions
   * @param permissionsRequired
   * @returns
   */
  public hasPermissions(permissionsRequired: Permissions, exceptions: Permissions = []): boolean {
    if (exceptions.length > 0) {
      for (const perm of exceptions) {
        if (PermissionsService.userPermissions?.indexOf(perm) !== -1) {
          return false;
        }
      }
    }

    for (const perm of permissionsRequired) {
      if (!PermissionsService.userPermissions?.includes(perm)) {
        return false;
      }
    }

    return true;
  }

  public updateEmail(userIri: IRIString, email: string): Promise<AxiosResponse<IUser>> {
    return this.patchRessource(`${ userIri }/update_email`, { email });
  }

  public getUserMoneyPots(userIri: IRIString): Promise<AxiosResponse<IIriArray<IMoneyPot>>> {
    return this.getRessource(`${ userIri }/money_pots`);
  }
}

export default UserService;
