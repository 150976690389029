import keycloakAuth from '@mppteam/mpp-keycloak-auth';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import AuthService from './AuthService';
import { httpClient } from './HttpClientService';

type AxiosRequestConfigs<T> = AxiosRequestConfig<T>[];
type TConfig<T> = AxiosRequestConfig<T> | AxiosRequestConfigs<T>;

class HttpQueueService {
  public static instance = new HttpQueueService();
  private isRefreshingToken = false;
  private tokenPromise?: Promise<boolean>;

  public makeSingleRequest<T, D = unknown>(config: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    return this.handleNewRequest<T, D>(config) as Promise<AxiosResponse<T>>;
  }

  public makeMultipleRequests<T>(config: AxiosRequestConfigs<T>): Promise<AxiosResponse<T>[]> {
    return this.handleNewRequest(config) as Promise<AxiosResponse<T>[]>;
  }

  private sendRequest<T, D = unknown>(config: TConfig<D>): Promise<AxiosResponse<T>> | Promise<AxiosResponse<T>[]> {
    if (Array.isArray(config)) {
      return Promise.all(config.map((singleConfig) => httpClient.request<T>(singleConfig)));
    }
    return httpClient.request<T>(config);
  }

  private refreshToken(): ReturnType<typeof AuthService['refreshToken']> {
    console.debug('refresh');
    return AuthService.refreshToken(keycloakAuth.getRefreshToken() as string);
  }

  private async handleNewRequest<T, D = unknown>(config: TConfig<D>): Promise<AxiosResponse<T> | AxiosResponse<T>[]> {
    if (!this.isRefreshingToken) {
      if (keycloakAuth.isSessionExpired() && keycloakAuth.getRefreshToken()) {
        this.isRefreshingToken = true;
        this.tokenPromise = this.refreshToken().finally(() => {
          this.tokenPromise = undefined;
          this.isRefreshingToken = false;
        });
        await this.tokenPromise;
      }
    } else {
      await this.tokenPromise;
    }

    return this.sendRequest<T, D>(config);
  }
}

export default HttpQueueService.instance;


