import { Box, Button, Card, CardContent, CircularProgress, List, Modal, SxProps, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import ColorConstant from '../../constants/ColorConstant';
import { MoneyPotStatus } from '../../constants/MoneyPotStatus';
import { MoneyPotType } from '../../constants/MoneyPotType';
import { IMoneyPot } from '../../interfaces/IMoneyPot';
import MoneyPotService from '../../services/MoneyPotService';
import SnackbarService from '../../services/SnackbarService';
import UserService from '../../services/UserService';
import IMoneyPotsPanelProps from './IMoneyPotsPanelProps';
import IMoneyPotsPanelState from './IMoneyPotsPanelState';

const styles: { [key: string]: SxProps } = {
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
  },

  borderBottom: {
    borderBottom: `1px solid ${ ColorConstant.lightGray }`,
  },

  modalBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 3,
    gap: 2,
  },
};

export default class MoneyPotsPanel extends React.PureComponent<IMoneyPotsPanelProps, IMoneyPotsPanelState> {
  public readonly state: Readonly<IMoneyPotsPanelState> = {
    moneyPots: [],
    currentMoneyPot: null,
    isCollectingMoneyPot: false,
    isLoading: false,
  };

  private abortController = new AbortController();
  private userService: UserService;
  private moneyPotService: MoneyPotService;

  constructor(props: IMoneyPotsPanelProps) {
    super(props);
    this.userService = new UserService(this.abortController.signal);
    this.moneyPotService = new MoneyPotService(this.abortController.signal);
  }

  public async componentDidMount(): Promise<void> {
    try {
      this.setState({
        isLoading: true,
      });
      const { userIri } = this.props;
      const moneyPotsResponse = await this.userService.getUserMoneyPots(userIri);

      this.setState({
        moneyPots: moneyPotsResponse.data['hydra:member'],
        isLoading: false,
      });
    } catch (error) {
      console.debug(error);
      this.setState({
        isLoading: false,
      });
    }
  }

  public render() {
    const { moneyPots, isLoading, currentMoneyPot, isCollectingMoneyPot } = this.state;

    return (
      0 === moneyPots.length ? null : (
        <>
          <Card sx={{
            ...styles.card,
            minHeight: `${ 1 === moneyPots.length ? '160' : '220' }px`,
          }}
          >
            <CardContent sx={styles.cardContent}>
              <Box>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  <Trans>account_money_pots</Trans>
                </Typography>
              </Box>

              {
                isLoading ?
                  <CircularProgress size={32} />
                  : this.renderMoneyPots()
              }
            </CardContent>
          </Card>

          {
            null === currentMoneyPot ?
              <></>
              : (
                <Modal open onClose={this.handleCloseModal}>
                  <Box className="modal" padding={2}>
                    <Typography>
                    Es-tu sûr.e de vouloir débloquer la cagnotte de { currentMoneyPot.beneficiary?.firstname } { currentMoneyPot.beneficiary?.lastname } ?
                    </Typography>
                    <Box sx={styles.modalBtnWrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.collectMoneyPot(currentMoneyPot['@id'])}
                      >
                        {
                          isCollectingMoneyPot ?
                            <CircularProgress size={24} color="secondary" />
                            : <Trans>yes</Trans>
                        }
                      </Button>
                      <Button
                        variant="text"
                        sx={{ color: ColorConstant.darkGray, marginRight: 2 }}
                        onClick={this.handleCloseModal}
                      >
                        <Trans>no</Trans>
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              )
          }
        </>
      )
    );
  }

  private renderMoneyPots(): JSX.Element {
    const { moneyPots } = this.state;

    return (
      <List sx={styles.list}>
        {
          moneyPots.map(
            (moneyPot, idx) => (
              <Box
                key={idx}
                paddingY={2}
                sx={{
                  ...styles.flex,
                  borderBottom: moneyPot !== moneyPots[moneyPots.length - 1] ? `1px solid ${ ColorConstant.lightGray }` : undefined,
                }}
              >
                { this.renderMoneypot(moneyPot) }
              </Box>
            ),
          )
        }
      </List>
    );
  }

  private renderMoneypot(moneyPot: IMoneyPot): JSX.Element {
    let moneyPotContent: ReactElement;

    switch (moneyPot.status) {
      case MoneyPotStatus.Validation:
        moneyPotContent = <Trans>reclamation</Trans>;
        break;
      case MoneyPotStatus.Used:
        moneyPotContent = <Trans>transfered</Trans>;
        break;
      case MoneyPotStatus.Closed:
      case MoneyPotStatus.Open:
        moneyPotContent = <>{ moneyPot.amount.toFixed(2) } €</>;
        break;
    }

    const isDisabled = MoneyPotType.LePotCommun !== moneyPot.type || MoneyPotStatus.Open !== moneyPot.status || !moneyPot.beneficiary;

    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>{ moneyPot.beneficiary?.firstname } { moneyPot.beneficiary?.lastname }</Typography>
          <Typography fontSize={'10px'}>{ moneyPot.type }</Typography>
        </Box>
        <Box sx={styles.flex}>
          <Typography fontSize={'14px'}>
            { moneyPotContent }
          </Typography>
          <Button
            variant="contained"
            disabled={isDisabled}
            onClick={() => this.openCollectMoneyPotModal(moneyPot)}
            sx={{ textTransform: 'none' }}
          >
            <Trans>free</Trans>
          </Button>
        </Box>
      </>
    );
  }

  private handleCloseModal = () => {
    const { isCollectingMoneyPot: isCollectingMoneypot } = this.state;

    if (isCollectingMoneypot) {
      return;
    }

    this.setState({
      currentMoneyPot: null,
      isCollectingMoneyPot: false,
    });
  };

  private openCollectMoneyPotModal(moneyPot: IMoneyPot): void {
    this.setState({
      currentMoneyPot: moneyPot,
    });
  }

  private async collectMoneyPot(moneyPotIri: IMoneyPot['@id']) {
    const { isCollectingMoneyPot: isCollectingMoneypot } = this.state;

    if (isCollectingMoneypot) {
      return;
    }

    try {
      this.setState({
        isCollectingMoneyPot: true,
      });
      await this.moneyPotService.collectMoneyPot(moneyPotIri);
      const { userIri } = this.props;
      const moneyPotsResponse = await this.userService.getUserMoneyPots(userIri);

      this.setState({
        moneyPots: moneyPotsResponse.data['hydra:member'],
        currentMoneyPot: null,
        isCollectingMoneyPot: false,
      });
      SnackbarService.open('collect_money_pot_success', 'success');
    } catch (error) {
      SnackbarService.open('collect_money_pot_failed', 'error');
      this.setState({
        currentMoneyPot: null,
        isCollectingMoneyPot: false,
      });
    }
  }
}
