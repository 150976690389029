import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import React, { PureComponent } from 'react';
import { Trans } from 'react-i18next';
import ColorConstant from '../../constants/ColorConstant';
import CouponService from '../../services/CouponService';
import IAccountPageActionsModalProps from './IAccountPageActionsModalProps';
import IAccountPageActionsModalState from './IAccountPageActionsModalState';

const styles: { [key: string]: SxProps } = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 533,
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    padding: '32px 16px 18px 32px',
  },
};

class AccountPageActionsModal extends PureComponent<IAccountPageActionsModalProps, IAccountPageActionsModalState> {
  public readonly state: IAccountPageActionsModalState = {
    coupons: [],
    autocompleteValue: '',
    isTyping: false,
  };
  private abortController = new AbortController();
  private modalField = React.createRef<HTMLTextAreaElement | HTMLInputElement>();
  private couponService = new CouponService(this.abortController.signal);
  private debounceTimeout?: NodeJS.Timeout;

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const { isModalOpen, handleModalClose, title, placeholder, isTextarea, onSubmitFunc } = this.props;
    const { coupons, autocompleteValue, isTyping } = this.state;

    return (
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
      >
        <Box sx={styles.modal}>
          <Box sx={{ paddingRight: '16px' }}>
            <Typography variant="h6" component="h2" sx={{ marginBottom: '56px' }}>
              <Trans>{ title }</Trans>
            </Typography>
            {
              isTextarea ?
                <TextField fullWidth multiline rows={8} placeholder={placeholder} inputRef={this.modalField} /> : (
                  <Autocomplete
                    freeSolo
                    options={coupons.map((coupon) => coupon.code)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>
                            {
                              isTyping ? <CircularProgress /> : null
                            }
                          </>,
                        }}
                      />
                    )}
                    popupIcon={false}
                    clearIcon={false}
                    onChange={(_, newValue) => this.setState({ autocompleteValue: coupons.find((coupon) => coupon.code === newValue)?.['@id'] as string })}
                    onInputChange={(event) => this.handleInputChange(event)}
                  />
                )
            }
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '26px' }}>
            <Button
              variant="text"
              sx={{ color: ColorConstant.darkGray, marginRight: 2 }}
              onClick={handleModalClose}
            >
              <Trans>cancel_button</Trans>
            </Button>
            <Button variant="text" onClick={() => onSubmitFunc(this.modalField.current?.value || autocompleteValue)}>
              <Trans>send_button</Trans>
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }

  private async setCoupons(code: string) {
    this.setState({ isTyping: true });
    const params = new URLSearchParams();

    params.append('code', code);
    const response = await this.couponService.getCoupons(params);
    const coupons = response.data['hydra:member'];

    this.setState({
      coupons,
      isTyping: false,
    });
  }

  private handleInputChange(event: React.SyntheticEvent<Element, Event>) {
    const { value: inputValue } = event.currentTarget as HTMLInputElement;

    clearTimeout(this.debounceTimeout as NodeJS.Timeout);

    if (inputValue.length > 0) {
      this.debounceTimeout = setTimeout(() => this.setCoupons(inputValue), 600);
    } else {
      this.setState({ coupons: [] });
    }
  }
}

export default AccountPageActionsModal;
