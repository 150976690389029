import React from 'react';

export default class DashboardPage extends React.PureComponent {
  public render(): JSX.Element {
    return (
      <>
      </>
    );
  }
}
