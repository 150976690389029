import { AxiosResponse } from 'axios';
import { IRIString } from '../interfaces/IriType';
import ServiceConstructor from './ServiceConstructor';

class OrderService extends ServiceConstructor {
  public resumeRequest(requestIri: IRIString): Promise<AxiosResponse> {
    return this.postRessource<unknown, { action: 'resume' }>(
      `${ requestIri }/actions`,
      { action: 'resume' },
    );
  }

  public deleteRequest(requestIri: IRIString): Promise<AxiosResponse> {
    return this.deleteRessource(requestIri);
  }
}

export default OrderService;
