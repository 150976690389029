import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress, SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AxiosResponse } from 'axios';
import { compareAsc } from 'date-fns';
import { t } from 'i18next';
import React, { ChangeEvent } from 'react';
import { Trans } from 'react-i18next';
import ColorConstant from '../../constants/ColorConstant';
import FileReasonConstants from '../../constants/FileReasonConstant';
import { KycQuestionIdentifiersConstant } from '../../constants/KycQuestionIdentifiersConstant';
import { IGouvAddress, IGouvAddressFeatures } from '../../interfaces/IGouvAddress';
import { IGouvCities } from '../../interfaces/IGouvCity';
import IKycQuestion from '../../interfaces/IKycQuestion';
import { IRIString } from '../../interfaces/IriType';
import DocumentService from '../../services/DocumentService';
import GouvService from '../../services/GouvService';
import KycService from '../../services/KycService';
import SnackbarService from '../../services/SnackbarService';
import IIdentityPanelModalGridAddressItemProps from './IIdentityPanelModalGridAddressItemProps';
import IIdentityPanelModalGridAddressItemState from './IIdentityPanelModalGridAddressItemState';

const styles: {[key: string]: SxProps} = {
  editIcon: {
    position: 'absolute',
    top: '50%',
    left: 'calc(100% + 8px)',
    transform: 'translate(0, -50%)',
  },

  iconsWrapper: {
    justifyContent: 'flex-end',
    display: 'flex',
    gap: 1,
  },

  relativeWrapper: {
    position: 'relative',
    display: 'inline-block',
  },

  addressFromWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },

  formInputFile: {
    width: '100%',
    height: '72px',
    border: `4px dashed ${ ColorConstant.borderColor }`,
    borderRadius: '4px',
    cursor: 'pointer',
    position: 'relative',
  },

  dragAndDropText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textTransform: 'uppercase',
    color: ColorConstant.disabledGray,
    display: 'flex',
    alignItems: 'center',
  },

  droppedText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    color: ColorConstant.primary,
  },

  inputFile: {
    opacity: 0,
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },

  error: {
    color: '#d32f2f',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '0.75rem',
    lineHeight: 1.66,
    textAlign: 'left',
    marginTop: '4px',
    marginRight: '14px',
    marginBottom: 0,
    marginLeft: '14px',
  },
};

class IdentityPanelModalGridAddressItem extends React.Component<IIdentityPanelModalGridAddressItemProps, IIdentityPanelModalGridAddressItemState> {
  public readonly state: Readonly<IIdentityPanelModalGridAddressItemState> = {
    showEditIcon: false,
    isEditing: false,
    isLoading: false,
    dateValue: new Date(this.props.content as string),

    addressPossibilities: [],
    isFetchingAddress: false,
    selectedAddress: undefined,
    isAddressError: false,

    cityPossibilities: [],
    isFetchingCity: false,
    selectedCity: undefined,
    isCityError: false,

    isDragging: false,
    uploadedFileName: '',
    isInputFileError: false,

    newValue: {
      objectToString() {
        if (!this.address || !this.zipCode || !this.city || !this.country) {
          return '';
        }
        return `${ this.address } ${ this.zipCode } ${ this.city }, ${ this.country }`;
      },
    },
  };

  private addressDebounceTimeout: NodeJS.Timeout | undefined;
  private cityDebounceTimeout: NodeJS.Timeout | undefined;
  private abortController = new AbortController();
  private kycService = new KycService(this.abortController.signal);
  private gouvService = new GouvService(this.abortController.signal);
  private documentService = new DocumentService(this.abortController.signal);
  private countryRef = React.createRef<HTMLInputElement>();
  private cityRef = React.createRef<HTMLInputElement>();
  private zipCodeRef = React.createRef<HTMLInputElement>();
  private addressRef = React.createRef<HTMLInputElement>();
  private inputFileRef = React.createRef<HTMLInputElement>();

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const { gridXs, label, content, jsxContent } = this.props;
    const { showEditIcon, isEditing, newValue } = this.state;

    return (
      <Grid
        item
        xs={gridXs || 6}
        onMouseEnter={() => this.setState({ showEditIcon: true })}
        onMouseLeave={() => this.setState({ showEditIcon: false })}
      >
        <Typography>
          <small>{ label }</small>
        </Typography>

        {
          isEditing ?
            this.renderEditingItem() : (
              <Typography variant="subtitle1" sx={styles.relativeWrapper}>
                { newValue?.objectToString() || jsxContent || content || ' - ' }
                {
                  showEditIcon && (
                    <IconButton
                      size="small"
                      sx={styles.editIcon}
                      onClick={() => this.setState({ isEditing: true })}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  )
                }
              </Typography>
            )
        }
      </Grid>
    );
  }

  private renderEditingItem() {
    const { referentials, addressDefaultValue, propertyToUpdate } = this.props;
    const {
      addressPossibilities,
      isFetchingAddress,
      isAddressError,
      cityPossibilities,
      isFetchingCity,
      isCityError,
      isLoading,
      newValue,
    } = this.state;

    return (
      <Box sx={{ position: 'relative', display: 'block' }}>
        <Box sx={styles.addressFromWrapper}>
          <Select
            size="small"
            fullWidth
            defaultValue={newValue.country && this.findCountryByLabel(newValue.country) || ( addressDefaultValue?.country && 'code' in addressDefaultValue.country ? addressDefaultValue.country.code : '')}
            inputRef={this.countryRef}
            onChange={this.checkIfFranceSelected}
          >
            {
              referentials?.map((referential) => (
                <MenuItem key={referential.slug} value={referential.slug}>
                  { referential.label }
                </MenuItem>
              ))
            }
          </Select>

          <Autocomplete
            size="small"
            freeSolo
            options={cityPossibilities}
            filterOptions={(options: IGouvCities) => options}
            getOptionLabel={(city) => typeof city === 'string' ? city : city.nom}
            renderOption={(optionProps, city) => (
              <MenuItem
                {...optionProps}
                key={city.code}
                value={city.code}
                onClick={(e) => {
                  optionProps.onClick?.(e);
                  this.setState({
                    selectedCity: city,
                    isCityError: false,
                  });
                }}
              >
                { city.nom } ({ city.codeDepartement })
              </MenuItem>
            )}
            loading={isFetchingCity}
            loadingText={t('loading...')}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                inputRef={this.cityRef}
                onChange={this.onCityChange}
                error={isCityError}
                helperText={isCityError && t('city_form_error')}
              />
            )}
            PaperComponent={(componentProps) => <Paper elevation={8} {...componentProps} />}
            defaultValue={newValue.city || (addressDefaultValue?.city && 'name' in addressDefaultValue.city ? addressDefaultValue.city.name : '')}
          />

          <TextField
            size="small"
            fullWidth
            inputRef={this.zipCodeRef}
            defaultValue={newValue.zipCode || addressDefaultValue?.zipCode}
          />

          <Autocomplete
            size="small"
            freeSolo
            options={addressPossibilities}
            filterOptions={(options: IGouvAddressFeatures) => options}
            getOptionLabel={(address) => typeof address === 'string' ? address : address.properties.name}
            renderOption={(optionProps, address) => (
              <MenuItem
                {...optionProps}
                key={address.properties.id}
                value={address.properties.name}
                onClick={(e) => {
                  optionProps.onClick?.(e);
                  this.setState({
                    selectedAddress: address.properties,
                    isAddressError: false,
                  });
                }}
              >
                { address.properties.label }
              </MenuItem>
            )}
            loading={isFetchingAddress}
            loadingText={t('loading...')}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                inputRef={this.addressRef}
                onChange={this.onAddressChange}
                error={isAddressError}
                helperText={isAddressError && t('address_form_error')}
              />
            )}
            PaperComponent={(componentProps) => <Paper elevation={8} {...componentProps} />}
            defaultValue={newValue.address || addressDefaultValue?.address}
          />
          { 'taxAddress' === propertyToUpdate && this.renderUploadDocument() }
        </Box>

        <Box sx={styles.iconsWrapper}>
          {
            isLoading ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={this.postAnswer} size="small">
                  <CheckIcon color="success" />
                </IconButton>
                <IconButton
                  onClick={() => this.setState({ isEditing: false, showEditIcon: false })}
                  size="small"
                >
                  <CloseIcon color="error" />
                </IconButton>
              </Box>
            )
          }
        </Box>
      </Box>
    );
  }

  private renderUploadDocument() {
    const { isDragging, uploadedFileName, isInputFileError } = this.state;

    return (
      <Box>
        <InputLabel
          htmlFor="file"
          sx={{
            ...styles.formInputFile,
            border: isInputFileError ? '1px solid #d32f2f' : `4px dashed ${ ColorConstant.borderColor }`,
          }}
        >
          <Box sx={styles.dragAndDropText}>
            {
              isDragging ? (
                <CheckCircleOutlineIcon
                  fontSize="large"
                  sx={{ color: ColorConstant.primary }}
                />
              ) : uploadedFileName ? (
                <Typography
                  variant="h6"
                  paragraph
                  sx={styles.droppedText}
                >
                  <CheckCircleOutlineIcon
                    fontSize="medium"
                    sx={{ color: ColorConstant.primary }}
                  />
                  { uploadedFileName }
                </Typography>
              ) : (
                <Trans>proof_of_address</Trans>
              )
            }
          </Box>

          <TextField
            type="file"
            inputRef={this.inputFileRef}
            onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ uploadedFileName: e.target?.files?.[0]?.name || '', isInputFileError: false })}
            onDragEnter={() => this.setState({ isDragging: true })}
            onDragLeave={() => this.setState({ isDragging: false })}
            onDrop={() => this.setState({ isDragging: false })}
            sx={styles.inputFile}
          />

        </InputLabel>

        {
          isInputFileError && (
            <Box sx={styles.error}>{ t('invalid_document') }</Box>
          )
        }
      </Box>
    );
  }

  private getQuestionIri(): IRIString {
    const identifier = KycQuestionIdentifiersConstant.MPP_PERSONAL_INFORMATION_ADDRESS;
    const { kycQuestions } = this.props;
    const question = kycQuestions.find((kycQuestion) => kycQuestion.identifier === identifier) as IKycQuestion;

    return question['@id'];
  }

  private postAnswer = async () => {
    if (!this.checkFormValidity()) {
      return;
    }

    const { referentials } = this.props;
    const { selectedAddress, selectedCity } = this.state;
    const newAddress = {
      address: selectedAddress?.name || this.addressRef.current?.value,
      country: referentials?.find((referential) => referential.slug === this.countryRef.current?.value)?.label,
      cityName: selectedAddress?.city || this.cityRef.current?.value,
      cityCode: selectedAddress?.citycode,
      zipCode: this.zipCodeRef.current?.value,
      departmentCode: selectedCity?.codeDepartement || '99',
      countryCode: this.countryRef.current?.value,
    };

    try {
      this.setState({ isLoading: true });

      const { dto, propertyToUpdate, setHasChangedValue } = this.props;
      const { userKyc } = dto.kycInformation;
      const kycResponse = await this.kycService.getUserKyc(userKyc);
      const { userKycAnswers } = kycResponse.data;
      const questionIri = this.getQuestionIri();
      const filteredAnswers = userKycAnswers?.filter((answer) => questionIri === answer.question);

      if (filteredAnswers && filteredAnswers?.length > 0) {
        const mostRecentAnswer = filteredAnswers.sort((a, b) => compareAsc(new Date(b.createdAt), new Date(a.createdAt)))[0];
        const parsedAnswer = JSON.parse(mostRecentAnswer.value as string);

        Object.assign(parsedAnswer, {
          [`${ propertyToUpdate }`]: newAddress,
        });

        await Promise.all([
          this.kycService.postAnswer({
            question: questionIri,
            kyc: userKyc,
            valid: true,
            value: JSON.stringify(parsedAnswer),
          }),
          'taxAddress' === propertyToUpdate && this.handleUpload(),
        ]);

        this.setState((previousState) => ({
          ...previousState,
          isLoading: false,
          isEditing: false,
          showEditIcon: false,
          newValue: {
            ...previousState.newValue,
            address: this.addressRef.current?.value,
            zipCode: this.zipCodeRef.current?.value,
            city: this.cityRef.current?.value,
            country: this.findCountryBySlug(this.countryRef.current?.value || ''),
          },
        }));

        setHasChangedValue();
      }
    } catch (error) {
      console.debug(error);
      SnackbarService.open(t('account_update_failed'), 'error');
    }
  };

  private async handleUpload() {
    try {
      const file: File = (this.inputFileRef?.current?.files && this.inputFileRef?.current?.files[0]) as File;
      const { account, user } = this.props;

      await this.documentService.uploadDocument({
        type: file?.type || '',
        reason: FileReasonConstants.find((reason) => 'justificatif-domicile' === reason.label)?.label || '',
        file,
        investmentAccount: account || '',
        user: user || '',
      });
    } catch (error) {
      console.debug(error);
      SnackbarService.open(t('upload_document_failed'), 'error');
    }

  }

  private findCountryBySlug(slug: string): string {
    const { referentials } = this.props;

    return referentials.find((ref) => ref.slug === slug)?.label || '';
  }

  private findCountryByLabel(label: string): string {
    const { referentials } = this.props;

    return referentials.find((ref) => ref.label === label)?.slug || '';
  }

  private checkFormValidity(): boolean {
    const { account: accountId, user: userId, propertyToUpdate } = this.props;
    let formIsValid = true;

    if (!this.isAddressValid()) {
      this.setState({ isAddressError: true });
      formIsValid = false;
    }

    if (!this.isCityValid()) {
      this.setState({ isCityError: true });
      formIsValid = false;
    }

    if ('taxAddress' === propertyToUpdate) {
      const file = this.inputFileRef?.current?.files && this.inputFileRef?.current?.files[0];

      if (!file) {
        this.setState({ isInputFileError: true });
        formIsValid = false;
      }

      if (!accountId || !userId) {
        formIsValid = false;
      }
    }

    return formIsValid;
  }

  private checkIfFranceSelected = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    const { addressPossibilities } = this.state;

    if ('fr' !== value) {
      this.setState((previousState) => ({
        selectedAddress: undefined,
        addressPossibilities: addressPossibilities && addressPossibilities.length > 0 ? [] : previousState.addressPossibilities,
      }));
    }

    if ('fr' !== value && addressPossibilities && addressPossibilities.length > 0) {
      this.setState({ addressPossibilities: [] });
    }
  };

  private onAddressChange = () => {
    const value = `${ this.addressRef.current?.value } ${ this.cityRef.current?.value }`;
    const { isFetchingAddress } = this.state;

    if (this.addressDebounceTimeout) {
      clearTimeout(this.addressDebounceTimeout);
    }

    if ('fr' !== this.countryRef.current?.value) {
      return;
    }

    if (!isFetchingAddress) {
      this.setState({ isFetchingAddress: true, addressPossibilities: [] });
    }

    if (value.length > 0) {
      this.addressDebounceTimeout = setTimeout(async () => {
        try {
          const addressResponse = await this.fetchAddressPossibilities(value);
          const addressPossibilities = addressResponse.data.features;

          this.setState({
            isFetchingAddress: false,
            addressPossibilities,
          });
        } catch (error) {
          console.debug(error);
          SnackbarService.open(t('fetch_address_failed'), 'error');
        }
      },1000);
    }
  };

  private onCityChange = () => {
    const value = `${ this.cityRef.current?.value } ${ this.cityRef.current?.value }`;
    const { isFetchingCity } = this.state;

    if (this.cityDebounceTimeout) {
      clearTimeout(this.cityDebounceTimeout);
    }

    if ('fr' !== this.countryRef.current?.value) {
      return;
    }

    if (!isFetchingCity) {
      this.setState({ isFetchingCity: true, cityPossibilities: [] });
    }

    if (value.length > 0) {
      this.cityDebounceTimeout = setTimeout( async () => {
        try {
          const cityResponse = await this.fetchCityPossibilities(value);
          const cityPossibilities = cityResponse.data;

          this.setState({
            isFetchingCity: false,
            cityPossibilities,
          });
        } catch (error) {
          console.debug(error);
          SnackbarService.open(t('fetch_city_failed'), 'error');
        }
      },1000);
    }
  };

  private fetchAddressPossibilities(value: string): Promise<AxiosResponse<IGouvAddress>> {
    return this.gouvService.searchAddress(value);
  }

  private fetchCityPossibilities(value: string): Promise<AxiosResponse<IGouvCities>> {
    return this.gouvService.searchCity(value);
  }

  private isAddressValid(): boolean {
    const { selectedAddress } = this.state;

    if ('fr' === this.countryRef.current?.value) {
      return !!selectedAddress;
    }

    return true;
  }

  private isCityValid(): boolean {
    const { selectedCity } = this.state;

    if ('fr' === this.countryRef.current?.value) {
      return selectedCity ? true : false;
    }

    return true;
  }
}

export default IdentityPanelModalGridAddressItem;
