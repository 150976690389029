import { now } from '@amcharts/amcharts4/.internal/core/utils/Time';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { addSeconds, format, intervalToDuration } from 'date-fns';
import React from 'react';
import { Trans } from 'react-i18next';
import { ICouponTableRowProps } from './ICouponTableRowProps';

export default class CouponTableRow extends React.PureComponent<ICouponTableRowProps> {
  public render() {
    const { coupon: { name, code, createdAt, discountTimeInSeconds } } = this.props;
    const formatedCreatedAt = format(new Date(createdAt), 'dd MMM yyyy');
    const convertedDiscountTimeInSeconds = this.convertDiscountTimeInSeconds(discountTimeInSeconds);

    return (
      <TableRow>
        {
          [
            name,
            code,
            formatedCreatedAt,
            convertedDiscountTimeInSeconds,
          ].map(this.renderSingleCell)
        }
        { this.renderActionIcon() }
      </TableRow>
    );
  }

  private renderSingleCell(content?: string | number | JSX.Element, idx?: number) {
    return (
      <TableCell key={idx}>
        <Typography fontWeight={'normal'} fontSize={14}>
          { content }
        </Typography>
      </TableCell>
    );
  }

  private renderActionIcon() {
    const { setIsModalOpen, coupon } = this.props;

    return (
      <TableCell>
        <IconButton onClick={() => setIsModalOpen({ iscreatingCoupon: false, currentCouponToEdit: coupon })}>
          <EditIcon fontSize="medium" />
        </IconButton>
      </TableCell>
    );
  }

  private convertDiscountTimeInSeconds(discountTimeInSeconds?: number) {
    if (!discountTimeInSeconds) {
      return <Trans>for_life</Trans>;
    }

    const duration = intervalToDuration({
      start: now(),
      end: addSeconds(now(), discountTimeInSeconds),
    });
    const { years, months, days } = duration;
    const yearsToDisplay = years && years > 0 ? `${ years } an${ years > 1 ? 's' : '' }` : null;
    const monthsToDIsplay = months && months > 0 ? `${ months } mois` : null;
    const daysToDisplay = days && days > 0 ? `${ days } jour${ days > 1 ? 's' : '' }` : null;

    return [yearsToDisplay, monthsToDIsplay, daysToDisplay].filter((eltToDisplay) => eltToDisplay).join(', ');
  }
}
