import { AlertColor } from '@mui/material';

class SnackbarService {
  public static instance = new SnackbarService();

  public open(message: string, severity?: AlertColor): void {
    document.dispatchEvent(new CustomEvent('snackbar', { detail: { message, severity } }));
  }
}

export default SnackbarService.instance;
