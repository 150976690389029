import DeleteIcon from '@mui/icons-material/Delete';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import ColorConstant from '../../constants/ColorConstant';
import CouponService from '../../services/CouponService';
import SnackbarService from '../../services/SnackbarService';
import ICouponItemProps from './ICouponItemProps';
import ICouponItemState from './ICouponItemState';

const styles: { [key: string]: SxProps } = {
  modal: {
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    padding: 2,
    minWidth: 'unset',
  },

  btnWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    marginTop: 4,
  },

  editCouponWrapper: {
    '& .MuiButtonBase-root': {
      opacity: 0,
    },

    ':hover': {
      '& .MuiButtonBase-root': {
        opacity: 1,
      },
    },
  },
};

class CouponItem extends React.Component<ICouponItemProps, ICouponItemState> {
  public readonly state: Readonly<ICouponItemState> = {
    isModalOpen: false,
    couponId: '',
    isDeleting: false,
  };

  private couponService = new CouponService();

  public render() {
    const { userCoupon } = this.props;

    return (
      <>
        <Box sx={styles.editCouponWrapper}>
          <span>{ userCoupon.coupon.code }</span>
          {
            (userCoupon.coupon.discountAmount ?? 0) > 0 &&
            (
              <>
                <IconButton
                  onClick={() => this.setIsModalOpen()}
                  size="small"
                >
                  <DeleteIcon color="error" />
                </IconButton>
                { this.renderModal() }
              </>
            )
          }
        </Box>
      </>
    );
  }

  private renderModal() {
    const { isDeleting, isModalOpen } = this.state;

    return (
      <Modal open={isModalOpen}>
        <Box className="modal" sx={styles.modal}>
          <Box><Trans>delete_coupon_confirm</Trans></Box>
          <Box sx={styles.btnWrapper}>
            <Button
              disabled={isDeleting}
              variant="text"
              sx={{ color: ColorConstant.darkGray, marginRight: 2 }}
              onClick={() => this.setIsModalOpen()}
            >
              <Trans>no</Trans>
            </Button>
            <Button
              disabled={isDeleting}
              variant="text"
              onClick={() => this.deleteCoupon()}
            >
              {
                isDeleting ?
                  <CircularProgress size={32} />
                  : <Trans>yes</Trans>
              }
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }

  private setIsModalOpen() {
    this.setState((prevValue) => ({ isModalOpen: !prevValue.isModalOpen }));
  }

  private async deleteCoupon() {
    const { userCoupon, setUserCoupons } = this.props;

    try {
      this.setState({ isDeleting: true });
      await this.couponService.deleteCoupon(userCoupon['@id']);
      await setUserCoupons();
    } catch (error) {
      console.debug(error);
      SnackbarService.open(t('delete_coupon_error'), 'error');
    } finally {
      this.setIsModalOpen();
      this.setState({ isDeleting: false });
    }
  }
}

export default CouponItem;
