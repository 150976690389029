import keycloakAuth from '@mppteam/mpp-keycloak-auth';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { IRIString } from '../../interfaces/IriType';
import CouponService from '../../services/CouponService';
import SnackbarService from '../../services/SnackbarService';
import UserService from '../../services/UserService';
import AccountPageActionsModal from '../AccountPageActionsModal/AccountPageActionsModal';
import DeleteAccountModal from '../DeleteAccountModal/DeleteAccountModal';
import IAccountPageActionsProps from './IAccountPageActionsProps';
import IAccountPageActionsState from './IAccountPageActionsState';

const styles: { [key: string]: SxProps } = {
  menuItem: {
    minWidth: '270px',
    fontSize: '14px',
    p: 2,
  },

  menuItemWithLoader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 'inherit',
  },
};

class AccountPageActions extends Component<IAccountPageActionsProps, IAccountPageActionsState> {
  public readonly state: IAccountPageActionsState = {
    isLoadingSynchronize: false,
    isLoadingBlacklistSms: false,
    isMenuOpen: false,
    anchorEl: null,
    modalHandler: {
      isModalOpen: false,
      title: '',
      placeholder: '',
      isTextarea: false,
      onSubmitFunc: () => null,
    },
    deleteModalOpen: false,
  };

  private accessToken = keycloakAuth.getAccessToken();
  private couponService = new CouponService();
  private userService = new UserService();
  private readonly MENU_ITEMS = [
    {
      content: () => (
        <Typography component="span" sx={styles.menuItemWithLoader}>
          <Trans>account_page_menu_synchronise</Trans>
          { this.state.isLoadingSynchronize && <CircularProgress size={20} /> }
        </Typography>
      ),
      onClick: () => this.synchronizeOrder(),
      isDisabled: false,
    },
    {
      content: () => <Trans>account_page_menu_coupon_assignement</Trans>,
      onClick: () => this.handleModalOpen(0),
    },
    {
      content: () => <Trans>account_page_menu_SEPA_mandate_generation</Trans>,
      onClick: () => this.generateSepaMandate(),
    },
    {
      content: () => <Trans>account_page_menu_renunciation_letter</Trans>,
      onClick: () => this.handleModalOpen(1),
    },
    {
      content: () => (
        <Typography component="span" sx={styles.menuItemWithLoader}>
          <Trans>account_page_menu_blacklist_sms</Trans>
          { this.state.isLoadingBlacklistSms && <CircularProgress size={20} /> }
        </Typography>
      ),
      onClick: () => this.blacklistSms(),
      isDisabled: false,
    },
    {
      content: () => <Trans>account_page_menu_delete_account</Trans>,
      onClick: () => this.handleDeleteModal(true),
    },
  ];
  private readonly MODAL_TYPES = [
    {
      title: 'account_page_menu_coupon_assignement',
      placeholder: t('coupon'),
      isTextarea: false,
      onSubmitFunc: async (couponIri: IRIString) => {
        try {
          const { userIri, setUser } = this.props;

          await this.couponService.assignCoupon(userIri, couponIri);
          setUser({
            userCoupons: [{
              coupon: couponIri,
            }],
          });
          this.handleModalClose();
          SnackbarService.open('success_coupon_assigned');
        } catch (error) {
          const { response } = error as AxiosError;

          if (response?.status === 400) {
            SnackbarService.open('coupon_not_found', 'warning');
          }
        }
      },
    },
    {
      title: 'account_page_waiver_letter',
      placeholder: t('cause'),
      isTextarea: true,
      onSubmitFunc: (inputValue: string) => {
        window.open(`${ process.env.REACT_APP_MPP_API_URL }${ this.props.accountIri }/generate_waiver_letter.json?token=${ this.accessToken }&reason=${ inputValue }`, '_blank'),
        this.handleModalClose();
      },
    },
  ];

  public render(): JSX.Element {
    const { isMenuOpen, anchorEl, modalHandler, deleteModalOpen } = this.state;

    return (
      <>
        <AccountPageActionsModal
          handleModalClose={this.handleModalClose}
          {...modalHandler}
        />

        {
          deleteModalOpen && (
            <DeleteAccountModal handleModalClose={this.handleDeleteModal} />
          )
        }

        <Box sx={{ margin: 1 }}>
          <Button
            onClick={this.handleOpenMenu}
            variant="contained"
            sx={{ textTransform: 'none' }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            <Trans>account_page_menu_action_button</Trans>
          </Button>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={this.handleCloseMenu}
        >
          { this.renderMenuItems() }
        </Menu>
      </>
    );
  }

  private blacklistSms = async () => {
    const { userIri } = this.props;

    this.MENU_ITEMS[4].isDisabled = true;
    this.setState({ isLoadingBlacklistSms: true });

    try {
      await this.userService.blacklistUser(userIri);
      SnackbarService.open('blacklist_sms_successful', 'success');
      this.toggleMenuState();
    } catch (error) {
      console.debug(error);
      SnackbarService.open('blacklist_sms_failed', 'error');
    } finally {
      this.MENU_ITEMS[4].isDisabled = false;
      this.setState({ isLoadingBlacklistSms: false });
    }
  };

  private handleModalClose: () => void = () => {
    this.setState({
      modalHandler: {
        ...this.state.modalHandler,
        isModalOpen: false,
      },
    });
  };

  private handleModalOpen: (index: number) => void = (index: number) => {
    this.setState({
      modalHandler: {
        isModalOpen: true,
        ...this.MODAL_TYPES[index],
      },
    });
  };

  private handleDeleteModal: (open: boolean) => void = (open: boolean) => {
    this.setState({
      deleteModalOpen: open,
    });
  };

  private synchronizeOrder: () => Promise<void> = async () => {
    this.MENU_ITEMS[0].isDisabled = true;
    this.setState({ isLoadingSynchronize: true });

    try {
      const { accountIri } = this.props;

      await this.userService.synchronizeOrder(accountIri);

      SnackbarService.open(t('synchronize_successful'), 'success');
      this.toggleMenuState();
    } catch (error) {
      console.debug(error);
      SnackbarService.open(t('synchronize_failed'), 'error');
    } finally {
      this.MENU_ITEMS[0].isDisabled = false;
      this.setState({ isLoadingSynchronize: false });
    }
  };

  private generateSepaMandate(): void {
    const { accountIri } = this.props;

    window.open(`${ process.env.REACT_APP_MPP_API_URL }${ accountIri }/generate_sepa_mandate.json?token=${ this.accessToken }`);
  }

  private toggleMenuState(): void {
    this.setState((state: IAccountPageActionsState) => ({
      ...state,
      isMenuOpen: !state.isMenuOpen,
    }));
  }

  private handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      isMenuOpen: true,
      anchorEl: event.currentTarget,
    });
  };

  private handleCloseMenu = () => {
    this.setState({ isMenuOpen: false });
  };

  private renderMenuItems(): JSX.Element[] {
    return this.MENU_ITEMS.map((item, index) => (
      <MenuItem
        key={index}
        disabled={item.isDisabled}
        onClick={item.onClick}
        sx={styles.menuItem}
      >
        { item.content() }
      </MenuItem>
    ));
  }
}

export default AccountPageActions;
