import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import minorSvg from '../../assets/icons/minor.svg';
import ColorConstant from '../../constants/ColorConstant';

const styles: {[key: string]: SxProps} =  {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingY: '5px',
    paddingX: '8px',
    borderRadius: '20px',
    backgroundColor: ColorConstant.blueMinor,
    color: '#FFF',
  },
};

const MinorBadge = () => (
  <Box sx={styles.wrapper} component="span">
    <img src={minorSvg} alt="minor badge" style={{ width: '18px' }} />
  </Box>
);

export default MinorBadge;
