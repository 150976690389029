
const FileReasonConstants = [
  {
    label: 'lettre-mission-mpp',
    slug: 'lettre-mission-mpp',
  },
  {
    label: 'formulaire-ouverture-natixis',
    slug: 'formulaire-ouverture-natixis',
  },
  {
    label: 'qcc-smoney',
    slug: 'qcc-smoney',
  },
  {
    label: 'attestation-smoney-natixis',
    slug: 'attestation-smoney-natixis',
  },
  {
    label: 'carte-identite-fr',
    slug: 'carte-identite-fr',
  },
  {
    label: 'driving-licence',
    slug: 'driving-licence',
  },
  {
    label: 'passeport-fr',
    slug: 'passeport-fr',
  },
  {
    label: 'titre-sejour-fr',
    slug: 'titre-sejour-fr',
  },
  {
    label: 'justificatif-domicile',
    slug: 'justificatif-domicile',
  },
  {
    label: 'rapport-ordre',
    slug: 'rapport-ordre',
  },
  {
    label: 'releve-compte-trimestriel',
    slug: 'releve-compte-trimestriel',
  },
  {
    label: 'mission-report',
    slug: 'mission-report',
  },
  {
    label: 'advice-generali',
    slug: 'advice-generali',
  },
  {
    label: 'advice',
    slug: 'advice',
  },
  {
    label: 'bulletin-souscription-generali',
    slug: 'bulletin-souscription-generali',
  },
  {
    label: 'bulletin-amendment-generali',
    slug: 'bulletin-amendment-generali',
  },
  {
    label: 'bulletin-souscription-apicil',
    slug: 'bulletin-souscription-apicil',
  },
  {
    label: 'rapport-adequation',
    slug: 'rapport-adequation',
  },
  {
    label: 'billing',
    slug: 'billing',
  },
  {
    label: 'iban',
    slug: 'iban',
  },
  {
    label: 'w-9',
    slug: 'w-9',
  },
  {
    label: 'product-dici',
    slug: 'product-dici',
  },
  {
    label: 'impact-report',
    slug: 'impact-report',
  },
  {
    label: 'mpp-subscription-documents',
    slug: 'mpp-subscription-documents',
  },
  {
    label: 'sepa-mandate',
    slug: 'sepa-mandate',
  },
  {
    label: 'funding-proof',
    slug: 'funding-proof',
  },
  {
    label: 'annual-fees',
    slug: 'annual-fees',
  },
  {
    label: 'bulletin-actualisaton-connaissance-client-apicil',
    slug: 'bulletin-actualisaton-connaissance-client-apicil',
  },
  {
    label: 'bulletin-avenant-apicil',
    slug: 'bulletin-avenant-apicil',
  },
  {
    label: 'bulletin-souscription-la-france-mutualiste',
    slug: 'bulletin-souscription-la-france-mutualiste',
  },
  {
    label: 'bulletin-avenant-la-france-mutualiste',
    slug: 'bulletin-avenant-la-france-mutualiste',
  },
];

export default FileReasonConstants;
