import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { t } from 'i18next';
import React, { Component, ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ColorConstant from '../../constants/ColorConstant';
import ClientInfoContext from '../../contexts/ClientInfoContext';
import IUser from '../../interfaces/IUser';
import SnackbarService from '../../services/SnackbarService';
import UserService from '../../services/UserService';
import IDeleteAccountModalProps from './IDeleteAccountModalProps';
import IDeleteAccountModalState from './IDeleteAccountModalState';

const styles: { [key: string]: SxProps } = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 533,
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    padding: '32px 16px 18px 32px',
  },

  emailWrapper: {
    marginBottom: '32px',
    textAlign: 'center',
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
};

class DeleteAccountModal extends Component<IDeleteAccountModalProps, IDeleteAccountModalState> {
  public readonly state: IDeleteAccountModalState = {
    step: 1,
    email: '',
    hasError: false,
    firstWraningBtnTimer: 3,
  };

  private abortController = new AbortController();
  private userService = new UserService();
  private warningBtnTimeout?: NodeJS.Timeout;

  public componentDidMount(): void {
    this.warningBtnTimeout = setInterval(() => {
      const { firstWraningBtnTimer } = this.state;

      this.setState({
        firstWraningBtnTimer: firstWraningBtnTimer - 1 as IDeleteAccountModalState['firstWraningBtnTimer'],
      });

      if (firstWraningBtnTimer === 1) {
        clearInterval(this.warningBtnTimeout);
      }
    }, 1000);
  }

  public componentWillUnmount() {
    this.warningBtnTimeout && clearTimeout(this.warningBtnTimeout);
    this.abortController.abort();
  }

  public render() {
    const { step } = this.state;

    return (
      <ClientInfoContext.Consumer>
        {
          ({ user }) => (
            <Modal open onClose={this.handleModalClose}>
              <Box sx={styles.modal}>
                <Box sx={{ paddingRight: '16px' }}>
                  <Typography variant="h6" component="h2" sx={{ marginBottom: '32px' }}>
                    <Trans>delete_modal_title</Trans>
                  </Typography>
                </Box>
                { step === 1 && this.renderFirstStepModal(user.email) }
                { step === 2 && this.renderSecondStepModal(user) }
              </Box>
            </Modal>
          )
        }
      </ClientInfoContext.Consumer>
    );
  }

  private renderFirstStepModal(email: string): ReactElement {
    const { firstWraningBtnTimer } = this.state;

    return (
      <>
        <Box sx={{ paddingRight: 2 }}>
          <Typography variant="body2" sx={{ marginBottom: 1, textAlign: 'center' }}>
            <Trans>delete_modal_first_step_subtitle</Trans>
          </Typography>
          <Typography variant="body2" sx={styles.emailWrapper}>
            { email }
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
          { this.renderCancelButton() }
          <Button variant="text" onClick={() => this.setState({ step: 2 })} disabled={firstWraningBtnTimer > 0}>
            {
              firstWraningBtnTimer > 0 ? firstWraningBtnTimer : <Trans>delete_modal_next_button</Trans>
            }
          </Button>
        </Box>
      </>
    );
  }

  private renderSecondStepModal(user: IUser): ReactElement {
    const { email, hasError } = this.state;

    return (
      <>
        <Typography variant="body2" sx={{ marginBottom: '16px' }}>
          <Trans>delete_modal_second_step_subtitle</Trans>
        </Typography>
        <Box sx={{ display: 'flex', paddingRight: '16px' }}>
          <TextField
            sx={{ flexGrow: 1 }}
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(event) => this.handleEmailChange(event, user.email)}
            onPaste={(event) => event.preventDefault()}
            error={hasError}
            helperText={hasError ? t('delete_modal_email_error') : ''}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '26px' }}>
          { this.renderCancelButton() }
          <Button
            variant="text"
            onClick={() => this.deleteAccount(user)}
            disabled={hasError || email === ''}
          >
            <Trans>delete_modal_validate_button</Trans>
          </Button>
        </Box>
      </>
    );
  }

  private renderCancelButton(): ReactElement {
    return (
      <Button
        variant="text"
        sx={{ color: ColorConstant.darkGray, marginRight: 2 }}
        onClick={() => this.handleModalClose()}
      >
        <Trans>cancel_button</Trans>
      </Button>
    );
  }

  private async deleteAccount(user: IUser) {
    const { history } = this.props;

    try {
      await this.userService.deleteUser(user['@id']);
      this.setState({ step: 1 });
      this.handleModalClose();
      SnackbarService.open(t('delete_modal_success_message'), 'success');
      history.replace('/accounts');
    } catch (error) {
      console.error(error);
      SnackbarService.open(t('delete_modal_error_message'), 'error');
    }
  }

  private handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, email: string) => {
    const { value } = event.target;

    this.setState({
      email: value,
      hasError: value !== email,
    });
  };

  private handleModalClose = () => {
    const { handleModalClose } = this.props;

    handleModalClose(false);
    this.setState({
      step: 1,
      email: '',
      hasError: false,
      firstWraningBtnTimer: 3,
    });
  };
}

export default withRouter(DeleteAccountModal);
