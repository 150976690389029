enum Permission {
  ACCESS = 'ACCESS',
  DENIED = 'DENIED',
  READ_COUPON_LIST = 'READ_COUPON_LIST',
  READ_PROSPECT_INFO = 'READ_PROSPECT_INFO',
  READ_CLIENT_INFO = 'READ_CLIENT_INFO',
  CREATE_COUPON_ITEM_ALL = 'CREATE_COUPON_ITEM_ALL',
  EDIT_SETTINGS = 'EDIT_SETTINGS'
}

export type Permissions = Permission[];
export default Permission;
