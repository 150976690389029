import keycloakAuth from '@mppteam/mpp-keycloak-auth';

class AuthService {
  public static instance = new AuthService();

  public addListener(func: (value: boolean) => void): void {
    this.listener = func;
  }

  public notifyListener(value: boolean): void {
    this.listener && this.listener(value);
  }

  public async refreshToken(refreshToken: string): Promise<boolean> {
    const params = new URLSearchParams();

    params.append('grant_type', 'refresh_token');
    params.append('client_id', process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '');
    params.append('refresh_token', refreshToken);

    try {
      await keycloakAuth.refreshSession();
    } catch (e) {
      keycloakAuth.cleanSession(['accountsListFavoriteColumns']);
      this.notifyListener(false);

      return false;
    }

    return true;
  }

  private listener?(value: boolean): void;
}

export default AuthService.instance;
