import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { differenceInYears, format } from 'date-fns';
import { t } from 'i18next';
import { Component } from 'react';
import { Trans } from 'react-i18next';
import ILegalRepresentativeIdentityCardProps from './ILegalRepresentativeIdentityCardProps';

class LegalRepresentativeIdentityCard extends Component<ILegalRepresentativeIdentityCardProps> {
  public render() {
    const { legal, isPayer } = this.props;

    return (
      <Card sx={{ flexGrow: 1 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>
            <Trans>legal_representative_identity</Trans> { legal.order } { isPayer && '💸' }
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <b><Trans>account_identity_gender_label</Trans> : </b>
            { legal.gender ? <Trans>{ legal.gender }</Trans> : '-' }
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <b><Trans>account_identity_lastname_label</Trans> : </b>
            { legal.lastName ?? '-' }
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <b><Trans>account_identity_birthname_label</Trans> : </b>
            { legal.birthName ?? legal.lastName ?? '-' }
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <b><Trans>account_identity_firstname_label</Trans> : </b>
            { legal.firstName ?? '-' }
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <b><Trans>account_identity_birthdate_label</Trans> : </b>
            { this.getBirthdate() }
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <b><Trans>account_identity_phone_label</Trans> : </b>
            <Link href={`tel:${ legal.contactInformation.phoneNumber }`}>{ legal.contactInformation.phoneNumber }</Link>
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <b><Trans>account_identity_email_label</Trans> : </b>
            { legal.contactInformation.email }
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <b><Trans>account_identity_home_address_label</Trans> : </b>
            { this.getAddressContent() }
          </Typography>
        </CardContent>
      </Card>
    );
  }

  private getBirthdate(): string {
    const  { legal } = this.props;

    return legal.birthDate ? `${ format(new Date(legal.birthDate), 'dd MMMM yyyy') } (${ differenceInYears(new Date(), new Date(legal.birthDate)) } ${ t('years') })` : '-';
  }

  private getAddressContent(): string {
    const { legal } = this.props;
    let address = `${ legal.contactInformation.homeAddress.address } ${ legal.contactInformation.homeAddress.zipCode }`;

    address += 'name' in legal.contactInformation.homeAddress.city ? legal.contactInformation.homeAddress.city.name : '';
    address += 'name' in legal.contactInformation.homeAddress.country ? legal.contactInformation.homeAddress.country.name : '';

    return address;
  }
}

export default LegalRepresentativeIdentityCard;
