import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import ColorConstant from '../../constants/ColorConstant';
import Reasons from '../../constants/DocumentReaonConstant';
import ClientInfoContext from '../../contexts/ClientInfoContext';
import IDocument, { IDocuments } from '../../interfaces/IDocument';
import IUser from '../../interfaces/IUser';
import IUserKycMppDto from '../../interfaces/UserKycMppDto/IUserKycMppDto';
import DocumentService from '../../services/DocumentService';
import SnackbarService from '../../services/SnackbarService';
import DocumentPanelModal from '../DocumentPanelModal/DocumentPanelModal';
import DocumentsTableBody from '../DocumentsTableBody/DocumentsTableBody';
import IDocumentsPanelProps from './IDocumentsPanelProps';
import IDocumentsPanelState from './IDocumentsPanelState';

class DocumentsPanel extends React.PureComponent<IDocumentsPanelProps,IDocumentsPanelState> {
  public readonly state: IDocumentsPanelState = {
    modalIsOpen: false,
    annualSituationStatementDocuments: [],
    bankInformationsDocuments: [],
    contractDocuments: [],
    identityDocuments: [],
    invoicesDocuments: [],
    otherDocuments: [],
    proofOfResidenceDocuments: [],
  };

  private abortController = new AbortController();
  private documentService: DocumentService;

  constructor(props: IDocumentsPanelProps) {
    super(props);
    this.documentService = new DocumentService(this.abortController.signal);
  }

  public async componentDidMount() {
    try {
      const {
        annualSituationStatementDocuments,
        bankInformationsDocuments,
        contractDocuments,
        identityDocuments,
        invoicesDocuments,
        otherDocuments,
        proofOfResidenceDocuments,
      } = await this.getDocuments();

      this.setState((previousState) => ({
        ...previousState,
        annualSituationStatementDocuments,
        bankInformationsDocuments,
        contractDocuments,
        identityDocuments,
        invoicesDocuments,
        otherDocuments,
        proofOfResidenceDocuments,
      }));
    } catch (error) {
      console.error('failed to fetch data : ', error);
    }
  }

  public render() {
    const { accountIri } = this.props;
    const {
      annualSituationStatementDocuments,
      bankInformationsDocuments,
      contractDocuments,
      identityDocuments,
      invoicesDocuments,
      otherDocuments: othersDocuments,
      proofOfResidenceDocuments,
      modalIsOpen,
    } = this.state;

    return (
      <ClientInfoContext.Consumer>
        {
          ({ user, dto }) => (
            <>
              {
                modalIsOpen && (
                  <DocumentPanelModal
                    accountIri={accountIri}
                    handleModal={this.handleModal}
                    userIri={user['@id']}
                    dto={dto}
                  />
                )
              }

              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 2,
              }}
              >
                <Button
                  variant="contained"
                  sx={{ letterSpacing: '1.25px', fontSize: '12px' }}
                  onClick={() => this.handleModal()}
                >
                  + <Trans>document_button_upload</Trans>
                </Button>
              </Box>

              <Box sx={{ overflowY: 'scroll', paddingRight: 1 }}>
                { this.renderTable('annual_situation_statements', annualSituationStatementDocuments, user, dto) }
                { this.renderTable('proofs_of_address', proofOfResidenceDocuments, user, dto) }
                { this.renderTable('account_identity_title', identityDocuments, user, dto) }
                { this.renderTable('bank_informations', bankInformationsDocuments, user, dto) }
                { this.renderTable('contract_documents', contractDocuments, user, dto) }
                { this.renderTable('invoices', invoicesDocuments, user, dto) }
                { this.renderTable('other_documents', othersDocuments, user, dto) }
              </Box>
            </>
          )
        }
      </ClientInfoContext.Consumer>
    );
  }

  private renderTable = (documentType: string, documents: IDocument[], user: IUser, dto: IUserKycMppDto) => (
    documents.length === 0 ?
      null :
      (
        <>
          <Typography variant="h6" sx={{ paddingLeft: 2, marginBottom: 1 }}><Trans>{ documentType }</Trans></Typography>
          <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color={ColorConstant.gray}>
                      <Trans>document_column_names</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" color={ColorConstant.gray}>
                      <Trans>document_column_owner</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" color={ColorConstant.gray}>
                      <Trans>document_column_errors</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" color={ColorConstant.gray}>
                      <Trans>document_column_statuses</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <DocumentsTableBody legalRepresentatives={dto.familyInformation.legalRepresentatives} user={user} documents={documents} />

            </Table>
          </TableContainer>
        </>
      )
  );

  private handleModal = async (reloadDocs?: boolean) => {
    if (reloadDocs) {
      try {
        const {
          bankInformationsDocuments,
          contractDocuments,
          identityDocuments,
          invoicesDocuments,
          otherDocuments,
          proofOfResidenceDocuments,
        } = await this.getDocuments();

        this.setState((previousState) => ({
          ...previousState,
          modalIsOpen: !previousState.modalIsOpen,
          bankInformationsDocuments,
          contractDocuments,
          identityDocuments,
          invoicesDocuments,
          otherDocuments,
          proofOfResidenceDocuments,
        }));
      } catch (error) {
        console.error('failed to fetch data : ', error);
        SnackbarService.open(t('failed_load_documents'), 'error');
        this.setState((previousState) => ({
          ...previousState,
          modalIsOpen: !previousState.modalIsOpen,
        }));
      }
    } else {
      this.setState((previousState) => ({
        ...previousState,
        modalIsOpen: !previousState.modalIsOpen,
      }));
    }
  };

  private getDocuments = async (): Promise<{
    annualSituationStatementDocuments: IDocuments;
    bankInformationsDocuments: IDocuments;
    contractDocuments: IDocuments;
    identityDocuments: IDocuments;
    invoicesDocuments: IDocuments;
    otherDocuments: IDocuments;
    proofOfResidenceDocuments: IDocuments;
  }> => {
    const { accountIri } = this.props;
    const response = await this.documentService.getDocuments(accountIri);
    const documents = response.data['hydra:member'];
    const annualSituationStatementDocuments: IDocuments = [];
    const bankInformationsDocuments: IDocuments = [];
    const contractDocuments: IDocuments = [];
    const identityDocuments: IDocuments = [];
    const invoicesDocuments: IDocuments = [];
    const otherDocuments: IDocuments = [];
    const proofOfResidenceDocuments: IDocuments = [];

    for (const document of documents) {
      switch (document.reason) {
        case Reasons.REASON_CARTE_IDENTITE_FR:
        case Reasons.REASON_DRIVING_LICENCE:
        case Reasons.REASON_TITRE_SEJOUR_FR:
        case Reasons.REASON_PASSEPORT_FR:
          identityDocuments.push(document);
          break;

        case Reasons.REASON_CARTE_IDENTITE_TIERS_FR:
        case Reasons.REASON_JUSTIFICATIF_DOMICILE:
        case Reasons.REASON_JUSTIFICATIF_DOMICILE_TIERS:
        case Reasons.REASON_ATTESTATION_SUR_HONNEUR_TIERS:
          proofOfResidenceDocuments.push(document);
          break;

        case Reasons.REASON_LETTRE_MISSION_MPP:
        case Reasons.REASON_MPP_SUBSCRIPTION_DOCUMENTS:
        case Reasons.REASON_BULLETIN_SOUSCRIPTION_GENERALI:
        case Reasons.REASON_BULLETIN_SOUSCRIPTION_APICIL:
        case Reasons.REASON_BULLETIN_SOUSCRIPTION_LFM:
        case Reasons.REASON_BULLETIN_AVENANT_GENERALI:
        case Reasons.REASON_BULLETIN_AVENANT_APICIL:
        case Reasons.REASON_BULLETIN_AVENANT_LFM:
          contractDocuments.push(document);
          break;

        case Reasons.REASON_IBAN:
          bankInformationsDocuments.push(document);
          break;

        case Reasons.REASON_ANNUAL_FEES:
        case Reasons.REASON_BILLING:
          invoicesDocuments.push(document);
          break;

        case Reasons.REASON_ANNUAL_SITUATION_STATEMENT:
          annualSituationStatementDocuments.push(document);
          break;
        default:
          otherDocuments.push(document);
          break;
      }
    }

    return {
      annualSituationStatementDocuments,
      bankInformationsDocuments,
      contractDocuments,
      identityDocuments,
      invoicesDocuments,
      otherDocuments,
      proofOfResidenceDocuments,
    };
  };
}

export default DocumentsPanel;
