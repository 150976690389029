import React from 'react';
class WindowNavigationService {
  public openInNewTab(pathname: string): void {
    window.open(pathname, '_blank');
  }

  public openInNewWindow(pathname: string): void {
    window.open(pathname, '_blank', `location=yes,width=${ window.innerWidth }, height=${ window.innerHeight },scrollbars=yes,status=yes`);
  }

  public tryAltOpen(event: React.MouseEvent, pathname: string): boolean {
    const { ctrlKey, metaKey, button } = event;

    if (ctrlKey || metaKey || button === 1) {
      this.openInNewTab(pathname);
      return true;
    }

    return false;
  }
}

export default WindowNavigationService;
