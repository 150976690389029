import keycloakAuth from '@mppteam/mpp-keycloak-auth';
import { Box, CircularProgress } from '@mui/material';
import React, { PropsWithChildren, ReactElement } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { IProviderContractTypes } from '../../interfaces/IProviderContractType';
import AccountsService from '../../services/AccountsService';
import AuthService from '../../services/AuthService';
import PermissionsService from '../../services/PermissionsService';
import UserService from '../../services/UserService';
import { IAuthContextProviderState } from './IAuthContextProviderState';

export default class AuthContextProvider extends React.Component<PropsWithChildren<unknown>, IAuthContextProviderState> {
  public readonly state: Readonly<IAuthContextProviderState> = {
    authenticated: false,
    ready: false,
    providerContractTypes: [],
  };

  private userService = new UserService();
  private accountsService = new AccountsService();

  public componentDidMount() {
    AuthService.addListener(this.setAuthContext);

    (async () => {
      await keycloakAuth.init(
        process.env.REACT_APP_KEYCLOAK_API_URL as string,
        process.env.REACT_APP_KEYCLOAK_REALM as string,
        process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
      );

      if (!keycloakAuth.hasSession()) {
        this.setState({
          ready: true,
          authenticated: false,
        });
        keycloakAuth.cleanSession(['accountsListFavoriteColumns']);
        return;
      }

      try {
        if (keycloakAuth.hasSession() && keycloakAuth.isSessionExpired()) {
          console.debug('refresh');
          await keycloakAuth.refreshSession();
        }

        const [userInfo, providerContractTypesResponse] = await Promise.all([
          keycloakAuth.getUserInfo(),
          this.accountsService.getProviderContractTypes(),
          this.userService.getConnectedUser(),
        ]);

        PermissionsService.userPermissions = userInfo.resource_access['mpp-financier']?.roles;

        this.setState({
          ready: true,
          authenticated: true,
          providerContractTypes: providerContractTypesResponse.data['hydra:member'],
        });

      } catch (exception) {
        keycloakAuth.cleanSession(['accountsListFavoriteColumns']);
        this.setState({
          ready: true,
          authenticated: false,
        });
      }
    })();
  }

  public render(): ReactElement {
    const { authenticated, ready, providerContractTypes } = this.state;
    const { children } = this.props;
    const contextData = {
      authenticated,
      setAuthContext: this.setAuthContext,
      providerContractTypes,
    };

    return (
      ready ? (
        <AuthContext.Provider value={contextData}>
          { children }
        </AuthContext.Provider>
      ) : (
        <Box sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <CircularProgress size={60} />
        </Box>
      )
    );
  }

  private setAuthContext = (authenticated: boolean, providerContractTypes?: IProviderContractTypes) => {
    this.setState((oldState) => ({
      ...oldState,
      authenticated,
      providerContractTypes: providerContractTypes?.length ? providerContractTypes : oldState.providerContractTypes,
    }));
  };
}
