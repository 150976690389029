import keycloakAuth from '@mppteam/mpp-keycloak-auth';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import IDocument from '../interfaces/IDocument';
import IDocumentBody from '../interfaces/IDocumentBody';
import IIriArray from '../interfaces/IIriArray';
import { IRIString } from '../interfaces/IriType';
import HttpQueueService from './HttpQueueService';
import ServiceConstructor from './ServiceConstructor';

class DocumentService extends ServiceConstructor {
  public getDocuments(accountIri: IRIString): Promise<AxiosResponse<IIriArray<IDocument>>> {
    return this.getRessource<IIriArray<IDocument>>(`${ accountIri }/documents`);
  }

  public uploadDocument({
    type,
    reason,
    file,
    investmentAccount: investmentAccountIri,
    user,
    subreason,
    investRequest: investRequestIri,
    expirationDate,
    iban,
    bic,
  }: IDocumentBody): Promise<AxiosResponse<IDocument>> {
    const formdata = new FormData();

    formdata.append('reason', reason);
    formdata.append('investmentAccount', investmentAccountIri);
    formdata.append('user', user);

    if (file) {
      formdata.append('type', type);
      formdata.append('file', file);
    }

    if (subreason) {
      formdata.append('subreason', subreason);
    }

    if (investRequestIri) {
      formdata.append('investRequest', investRequestIri);
    }

    if (expirationDate) {
      formdata.append('expirationDate', format(expirationDate, 'Y-M-D'));
    }

    if (iban) {
      formdata.append('iban', iban);
    }

    if (bic) {
      formdata.append('bic', bic);
    }

    return HttpQueueService.makeSingleRequest<IDocument, FormData>({
      method: 'POST',
      url: '/v1/documents',
      signal: this.signal,
      data: formdata,
    });
  }

  public getDocumentDownloadUrl(documentIri: IRIString, tryOpen = false): string {
    const accessToken = keycloakAuth.getAccessToken();

    return  `${ process.env.REACT_APP_MPP_API_URL }${ documentIri }/download?token=${ accessToken }&try_open=${ tryOpen }`;
  }
}

export default DocumentService;
