import { AxiosRequestConfig } from 'axios';
import IReferentialContext from '../interfaces/IReferentialContext';
import HttpQueueService from './HttpQueueService';
import ServiceConstructor from './ServiceConstructor';

class ReferentialService extends ServiceConstructor {
  public async init(): Promise<IReferentialContext> {
    const [
      { data: maritalStatuses },
      { data: patrimonies },
      { data: beneficiaries },
      { data: loanTypes },
      { data: countries },
      { data: professionalStatuses },
      { data: currentSteps },
      { data: investProfileCategories },
      { data: goals },
      { data: providers },
      { data: providerContractTypes },
      { data: advisors },
    ] = await HttpQueueService.makeMultipleRequests([
      this.getStandardReferential('marital_status'),
      this.getStandardReferential('patrimony'),
      this.getStandardReferential('apicil_beneficiaries'),
      this.getStandardReferential('loan_types'),
      this.getStandardReferential('countries'),
      this.getStandardReferential('professional_status'),
      this.getStandardReferential('current_steps'),
      this.getCustomReferential('invest_profile_categories'),
      this.getCustomReferential('goals'),
      this.getCustomReferential('providers'),
      this.getCustomReferential('provider_contract_types'),
      this.getCustomReferential('clients/mpp-api/roles/VALIDATE_ADVICE_ITEM_ALL/users'),
    ]);

    return {
      maritalStatuses,
      patrimonies,
      beneficiaries,
      loanTypes,
      countries,
      professionalStatuses,
      currentSteps,
      investProfileCategories: investProfileCategories['hydra:member'],
      goals: goals['hydra:member'],
      providers: providers['hydra:member'],
      providerContractTypes: providerContractTypes['hydra:member'],
      advisors: advisors['hydra:member'],
    };
  }

  public getStandardReferential(referentialName: string): AxiosRequestConfig {
    return ({
      method: 'GET',
      url: `/v1/referentials.json`,
      signal: this.signal,
      params: { referentialName },
    });
  }

  public getCustomReferential(referentialName: string): AxiosRequestConfig {
    return ({
      method: 'GET',
      url: `/v1/${ referentialName }`,
      signal: this.signal,
    });
  }

  public isMarriedButGoodsSharing(maritalStatusSlug: string, matrimonialRegimeSlug?: string): boolean {
    if (!matrimonialRegimeSlug || 'family-situation-married' !== maritalStatusSlug) {
      return false;
    }

    return 'goods-sharing' !== matrimonialRegimeSlug;
  }
}

export default ReferentialService;
