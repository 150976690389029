import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton, SxProps, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { Trans } from 'react-i18next';
import PermissionChecker from '../../components/PermissionChecker';
import Permission from '../../constants/Permission';
import { IAdviceFormat } from '../../interfaces/IAdviceFormat';
import { IMaintenanceInterval, ISettingContactChoice, ISettingMaintenance } from '../../interfaces/ISettings';
import SettingService from '../../services/SettingService';
import SnackbarService from '../../services/SnackbarService';
import ISettingPageState from './ISettingPageState';

const styles: { [key: string]: SxProps } = {
  circularWrapper: {
    height: '100%',
    width: '100%',
    mt: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'scroll',
    p: 2,
  },
  contactChoiceCardContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    pt: 3,
  },
  adviceFormatsCardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    pt: 3,
  },
  maintenanceCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 2,
    pt: 3,
  },
  dateContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 3,
    width: '100%',
  },
  dateField: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    mb: 2 ,
  },
};

export default class SettingsPage extends React.Component<unknown, ISettingPageState> {
  public state: ISettingPageState = {
    loaded: false,
    adviceFormats: [],
    contactChoiceSettingValue: { enabled: undefined },
    maintenanceSettingValue: { apicil: [], generali: [], laFranceMutualiste: [] },
  };
  private abortController = new AbortController();
  private settingsService = new SettingService(this.abortController.signal);
  private contactChoiceSettingKey = '';
  private maintenanceSettingKey = '';

  public async componentDidMount(): Promise<void> {
    try {
      const [
        adviceFormatResponse,
        { data: contactChoiceResponse },
        { data: maintenanceResponse },
      ] = await Promise.all([
        this.settingsService.getAdviceFormats(),
        this.settingsService.getSettings('contact-choice-enabled'),
        this.settingsService.getSettings('maintenance'),
      ]);

      this.setState({
        loaded: true,
        adviceFormats: adviceFormatResponse.data['hydra:member'].filter((adviceFormat) => adviceFormat.slug !== 'none'),
        contactChoiceSettingValue: contactChoiceResponse.value as ISettingContactChoice,
        maintenanceSettingValue: maintenanceResponse.value as ISettingMaintenance,
      });

      this.contactChoiceSettingKey = contactChoiceResponse.key;
      this.maintenanceSettingKey = maintenanceResponse.key;
    } catch (e) {
      console.debug(e);
    }
  }

  public componentWillUnmount(): void {
    this.abortController.abort();
  }

  public render() {
    const { loaded } = this.state;

    return (
      <PermissionChecker permissions={[Permission.EDIT_SETTINGS]}>
        <Box sx={styles.settingContainer}>
          { loaded ? (
            <Box>
              { this.renderContactChoiceSection() }
              { this.renderAdviceFormatsSection() }
              { this.renderMaintenanceSection() }
            </Box>
          ) : (
            <Box sx={styles.circularWrapper}>
              <CircularProgress size={60} />
            </Box>
          ) }
        </Box>
      </PermissionChecker>
    );
  }

  private renderContactChoiceSection(): JSX.Element {
    const { contactChoiceSettingValue } = this.state;

    return (
      <>
        <Typography variant="h6" sx={{ pl: 2, mb: 1 }}><Trans>setting_contact_choice_title</Trans></Typography>

        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent sx={styles.contactChoiceCardContent}>
            <Typography variant="body1" component="div">
              <Trans>setting_contact_choice_description</Trans> :
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={contactChoiceSettingValue.enabled}
              exclusive
              onChange={this.contactChoiceChange}
            >
              <ToggleButton value={true}><Trans>setting_toggle_on</Trans></ToggleButton>
              <ToggleButton value={false}><Trans>setting_toggle_off</Trans></ToggleButton>
            </ToggleButtonGroup>
          </CardContent>
        </Card>
      </>
    );
  }

  private renderAdviceFormatsSection(): JSX.Element {
    const { adviceFormats } = this.state;

    return (
      <>
        <Typography variant="h6" sx={{ pl: 2, mb: 1 }}><Trans>setting_advice_format_title</Trans></Typography>

        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent sx={styles.adviceFormatsCardContent}>

            { adviceFormats?.map((adviceFormat) => (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} key={adviceFormat['@id']}>
                <Typography variant="body1" component="div">
                  { adviceFormat.name } :
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={adviceFormat.enabled}
                  exclusive
                  onChange={this.adviceFormatChange(adviceFormat)}
                >
                  <ToggleButton value={true}><Trans>setting_toggle_on</Trans></ToggleButton>
                  <ToggleButton value={false}><Trans>setting_toggle_off</Trans></ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )) }

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={this.submitAdviceFormat}>
                <Trans>setting_valid_button</Trans>
              </Button>
            </Box>

          </CardContent>
        </Card>
      </>
    );
  }

  private renderMaintenanceSection(): JSX.Element {
    const { maintenanceSettingValue } = this.state;

    return (
      <>
        <Typography variant="h6" sx={{ pl: 2, mb: 1 }}><Trans>setting_maintenance_title</Trans></Typography>

        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent sx={styles.maintenanceCardContent}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={styles.dateContainer}>
                { Object.entries(maintenanceSettingValue).map(([provider, intervals]) => (
                  <Card key={provider}>
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 2 }}>{ provider.charAt(0).toUpperCase() + provider.slice(1) }</Typography>
                      { intervals.map((interval: IMaintenanceInterval, index: number) => (
                        <Box sx={styles.dateField} key={index}>
                          <Box sx={{ flexBasis: '45%' }}>
                            <DateTimePicker
                              label="Date de début"
                              value={interval.start}
                              onChange={this.handleDateChange(provider, index, 'start')}
                              renderInput={(params) => <TextField {...params} fullWidth />}
                              ampm={false}
                            />
                          </Box>
                          <Box sx={{ flexBasis: '45%' }}>
                            <DateTimePicker
                              label="Date de fin"
                              value={interval.end}
                              onChange={this.handleDateChange(provider, index, 'end')}
                              renderInput={(params) => <TextField {...params} fullWidth />}
                              ampm={false}
                            />
                          </Box>
                          <Box sx={{ flexBasis: '10%' }}>
                            <IconButton color="error" onClick={this.removeInterval(provider, index)}>
                              <RemoveIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      )) }

                      <IconButton color="primary" onClick={this.addInterval(provider)}>
                        <AddIcon />
                      </IconButton>
                    </CardContent>
                  </Card>
                )) }
              </Box>
              <Button variant="contained" color="primary" onClick={this.submitMaintenance}>
                <Trans>setting_valid_button</Trans>
              </Button>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </>
    );
  }

  private contactChoiceChange = async (event: React.MouseEvent<HTMLElement>, value: boolean) => {
    if (value !== null) {
      const contactChoiceSettingValue = {
        enabled: value,
      };

      try {
        await this.settingsService.patchSettings(this.contactChoiceSettingKey, { contactChoice: contactChoiceSettingValue });
        this.setState({ contactChoiceSettingValue });
        SnackbarService.open('setting_contact_choice_changed', 'success');
      } catch (error) {
        console.debug(error);
        SnackbarService.open('setting_change_failed', 'error');
      }
    }
  };

  private adviceFormatChange = (adviceFormat: IAdviceFormat) => (event: React.MouseEvent<HTMLElement>, value: boolean) => {
    if (value !== null) {
      this.setState((previousState) => {
        const updatedAdviceFormats = previousState.adviceFormats.map((af) => {
          if (af.slug !== adviceFormat.slug && value) {
            af.enabled = false;
          } else {
            af.enabled = value;
          }

          return af;
        });

        return {
          adviceFormats: updatedAdviceFormats,
        };
      });
    }
  };

  private submitAdviceFormat = async () => {
    const promises = [];
    const { adviceFormats } = this.state;

    for (const adviceFormat of adviceFormats) {
      promises.push(this.settingsService.patchAdviceFormats(adviceFormat.slug, adviceFormat.enabled));
    }

    try {
      await Promise.all(promises);
      SnackbarService.open('setting_advice_format_change', 'success');
    } catch (error) {
      console.debug(error);
      SnackbarService.open('setting_change_failed', 'error');
    }
  };

  private handleDateChange = (provider: string, index: number, field: 'start' | 'end') => (date: string | null) => {
    if (date) {
      const newData = this.state.maintenanceSettingValue[provider as keyof ISettingMaintenance]?.map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: new Date(date).toISOString() };
        }
        return item;
      });

      this.setState((prevState) => ({
        maintenanceSettingValue: { ...prevState.maintenanceSettingValue, [provider]: newData },
      }));
    }
  };

  private addInterval = (provider: string) => () => {
    const intervals = [...this.state.maintenanceSettingValue[provider as keyof ISettingMaintenance] || []];

    intervals.push({
      start: new Date().toISOString(),
      end: new Date().toISOString(),
    });

    this.setState((prevState) => ({
      maintenanceSettingValue: {
        ...prevState.maintenanceSettingValue,
        [provider]: intervals,
      },
    }));
  };

  private removeInterval = (provider: string, index: number) => () => {
    const intervals = [...this.state.maintenanceSettingValue[provider as keyof ISettingMaintenance] || []];

    intervals.splice(index, 1);

    this.setState((prevState) => ({
      maintenanceSettingValue: {
        ...prevState.maintenanceSettingValue,
        [provider]: intervals,
      },
    }));
  };

  private submitMaintenance = async () => {
    try {
      await this.settingsService.patchSettings(this.maintenanceSettingKey, { maintenance: this.state.maintenanceSettingValue });
      SnackbarService.open('setting_maintenance_changed', 'success');
    } catch (error) {
      console.debug(error);
      SnackbarService.open('setting_change_failed', 'error');
    }
  };
}
