import { ViewList } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Drawer, IconButton, SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ColorConstant from '../../constants/ColorConstant';
import PropertiesConstant from '../../constants/PropertiesConstant';
import TablePaginationConstants from '../../constants/TablePaginationConstant';
import AuthContext from '../../contexts/AuthContext';
import { IAccountInformation } from '../../interfaces/IAccountInformation';
import { IProviderContractTypes } from '../../interfaces/IProviderContractType';
import AccountsListTableBodyRow from '../AccountsListTableBodyRow/AccountsListTableBodyRow';
import { IFilterPossibility } from './IAccountsListFilter';
import IAccountsListProps from './IAccountsListProps';
import IAccountsListState from './IAccountsListState';

const styles: { [key: string]: SxProps } = {
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minHeight: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  table: {
    height: '100%',
    backgroundColor: ColorConstant.theme.palette.secondary.main,
  },
  drawer: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  drawerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sticky: {
    position: 'sticky',
    right: 0,
    background: 'white',
    zIndex: 3,
    textAlign: 'right',
  },
};

class AccountsList extends React.PureComponent<IAccountsListProps, IAccountsListState> {
  private rowsPerPagePossibilities = PropertiesConstant.ROWS_PER_PAGE_POSSIBILITIES;
  private POSSIBLE_COLUMNS = [
    'providerContractTypeSlug',
    'email',
    'couponCodes',
    'phone',
    'currentStep',
    'lastLoginAt',
    'signatureDate',
    'createdAt',
    'videoUploadedAt',
    'initialInvestmentFirst',
    'initialInvestmentMonthly',
    'advisor',
  ] as const;

  constructor(props: IAccountsListProps) {
    super(props);

    this.state = {
      drawerOpen: false,
      favoriteColumns: JSON.parse(localStorage.getItem('accountsListFavoriteColumns') ?? '[]'),
    };
  }

  public render() {
    const { rowsPerPage, page, totalAccounts, isLoading } = this.props;
    const { drawerOpen, favoriteColumns  } = this.state;

    return (
      <>
        <Paper sx={styles.tableWrapper}>
          <TableContainer sx={{ flex: 1 }}>
            <Table sx={{ ...styles.table, height: totalAccounts === 0 || isLoading ? '100%' : 'inherit' }} padding={'none'} stickyHeader>
              { this.renderTableHead() }
              { this.renderTableBody() }
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={totalAccounts}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={this.rowsPerPagePossibilities}
            labelRowsPerPage={<Trans>rows_per_page</Trans>}
            onRowsPerPageChange={this.handleRowsPerPage}
            page={totalAccounts > 0 ? page : 0}
            onPageChange={this.handleChangePage}
            sx={TablePaginationConstants.style}
          />
        </Paper>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={this.closeDrawer}
        >
          <Box sx={styles.drawer}>
            <Box sx={styles.drawerContent}>
              <Typography padding={2} fontWeight="medium"><Trans>accounts_list_preference</Trans></Typography>
              <Box>
                <IconButton onClick={this.closeDrawer}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ padding: 2, overflowY: 'auto', flex: 1, display: 'flex', flexDirection: 'column' }}>
              { this.POSSIBLE_COLUMNS.map((column, index) => (
                <FormControlLabel
                  key={index}
                  control={(
                    <Checkbox
                      checked={index < 2 ? true : favoriteColumns.includes(column)}
                      disabled={index < 2}
                      onChange={() => this.handleCheckboxChange(column)}
                    />
                  )}
                  label={<Trans>{ `accounts_list_${ column }` }</Trans>}
                />
              )) }
            </Box>
          </Box>
        </Drawer>
      </>
    );
  }

  private handleRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onTableListChange } = this.props;

    onTableListChange({
      rowsPerPage: +event.target.value,
    });
  };

  private handleChangePage = (event: unknown, newPage: number) => {
    const { isLoading, onTableListChange } = this.props;

    if (isLoading) {
      return;
    }
    onTableListChange({ page: newPage });
  };

  private shortenProviderContractTypeSlug = (providerContractTypeIri: IAccountInformation['providerContractType'], providerContractTypes: IProviderContractTypes): string => {
    const providerContractType = providerContractTypes.find((elt) => elt['@id'] === providerContractTypeIri);

    return providerContractType?.name.split(' ').map((word) => word.at(0)).join('') ?? '-';
  };

  private handleCheckboxChange = (item: string) => {
    this.setState((prevState) => {
      const { favoriteColumns } = prevState;
      const index = favoriteColumns.indexOf(item);
      let newValue: string[] = [];

      if (index > -1) {
        newValue = [...favoriteColumns.slice(0, index), ...favoriteColumns.slice(index + 1)];
      } else {
        newValue = [...favoriteColumns, item];
      }

      newValue = this.POSSIBLE_COLUMNS.filter((item) => newValue.includes(item));

      return { favoriteColumns: newValue };
    });
  };

  private closeDrawer = () => {
    const { favoriteColumns } = this.state;

    localStorage.setItem('accountsListFavoriteColumns', JSON.stringify(favoriteColumns));

    this.setState({
      drawerOpen: false,
    });
  };

  private renderTableHead() {
    const { order, orderBy, onTableListChange } = this.props;
    const { favoriteColumns } = this.state;
    const defaultColumns = [
      'providerContractTypeSlug',
      'email',
    ];
    const displayedColumns = defaultColumns.concat(favoriteColumns);
    const unSortableColumns = [
      'providerContractTypeSlug',
      'couponCodes',
      'phone',
      'initialInvestmentFirst',
      'initialInvestmentMonthly',
      'advisor',
    ];

    return (
      <TableHead>
        <TableRow>
          {
            displayedColumns.map((column: string) => unSortableColumns.includes(column)
              ? (
                <TableCell key={column}>
                  <Typography fontWeight={'medium'} fontSize={'0.8rem'}>
                    <Trans>{ `accounts_list_${ column }` }</Trans>
                  </Typography>
                </TableCell>
              )
              : (
                <TableCell key={column}>
                  <TableSortLabel
                    active={orderBy === column}
                    direction={orderBy === column ? order : 'asc'}
                    onClick={() => {
                      const isAsc = orderBy === column && order === 'asc';

                      onTableListChange({
                        order: isAsc ? 'desc' : 'asc',
                        orderBy: column as IFilterPossibility,
                      });
                    }}
                  >
                    <Typography fontWeight={'medium'} fontSize={'0.8rem'}>
                      <Trans>{ `accounts_list_${ column }` }</Trans>
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              ),
            )
          }
          <TableCell sx={styles.sticky}>
            <Tooltip title={t('accounts_list_preference_tooltip')}>
              <Button
                size="small"
                sx={{ borderRadius: '50%', minWidth: 0, p: 1, color: ColorConstant.darkGray }}
                onClick={() => this.setState({ drawerOpen: true })}
              >
                <ViewList />
              </Button>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  private renderAccounts() {
    const { accounts } = this.props;
    const { favoriteColumns } = this.state;

    return (
      <AuthContext.Consumer>
        {
          ({ providerContractTypes }) => {
            return accounts.length > 0 ?
              accounts.map((account) => {
                const providerContractTypeSlug = this.shortenProviderContractTypeSlug(account.providerContractType, providerContractTypes);

                return (
                  <AccountsListTableBodyRow
                    key={account.id}
                    account={account}
                    favoriteColumns={favoriteColumns}
                    providerContractTypeSlug={providerContractTypeSlug}
                    email={account.email || '-'}
                  />
                );
              },
              ) : (
                <TableRow>
                  <TableCell colSpan={favoriteColumns.length + 3} align="center">
                    <Typography fontWeight={'normal'} fontSize={14}><Trans>no_accounts_found</Trans></Typography>
                  </TableCell>
                </TableRow>
              );
          }
        }
      </AuthContext.Consumer>
    );
  }

  private renderTableBody() {
    const { isLoading } = this.props;
    const { favoriteColumns } = this.state;

    return (
      <TableBody sx={{ overflowY: 'scroll' }}>
        {
          isLoading ? (
            <TableRow>
              <TableCell colSpan={favoriteColumns.length + 3} align="center">
                <CircularProgress size={60} />
              </TableCell>
            </TableRow>
          )
            : this.renderAccounts()
        }
      </TableBody>
    );
  }
}

export default withRouter(AccountsList);
