import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SxProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import AccountsListMenu from '../AccountsListMenu/AccountsListMenu';
import IAccountsListIconMenuProps from './IAccountsListIconMenuProps';

const styles: { [x: string]: SxProps } = {
  sticky: {
    position: 'sticky',
    right: 0,
    background: 'white',
    zIndex: 1,
  },
};

const AccountsListIconMenu = ({ accountId, anchorEl, handleIconClick, handleCloseMenu }: IAccountsListIconMenuProps) => (
  <>
    <TableCell align="right" sx={{ ...styles.sticky, cursor: 'pointer' }} onClick={handleIconClick}>
      <IconButton>
        <MoreVertIcon fontSize="medium" />
      </IconButton>
    </TableCell>
    <AccountsListMenu accountId={accountId} anchorEl={anchorEl} handleClose={handleCloseMenu} />
  </>
);

export default AccountsListIconMenu;
