import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { PureComponent } from 'react';
import { Trans } from 'react-i18next';
import { IAccount } from '../../interfaces/IAccount';
import { IOrder, IOrders } from '../../interfaces/IOrder';
import AccountsService from '../../services/AccountsService';
import OrderPanelCard from '../OrderPanelCard/OrderPanelCard';
import IOrderPanelProps from './IOrderPanelProps';
import IOrderPanelState from './IOrderPanelState';

const styles: {[key: string]: SxProps} = {
  card: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  cardContent: {
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    ':last-child': { paddingBottom: 2 },
  },

  list: {
    width: '100%',
    pt: 0,
    height: 'calc(100% - 36px)',
    overflow: 'auto',
    paddingBottom: 0,
  },
};

export default class OrderPanel extends PureComponent<IOrderPanelProps, IOrderPanelState> {
  public readonly state: IOrderPanelState = {
    orders: [],
  };

  private abortController = new AbortController();
  private accountsService;

  constructor(props: IOrderPanelProps) {
    super(props);
    this.accountsService = new AccountsService(this.abortController.signal);
  }

  public async componentDidMount() {
    await this.getOrders();
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const { orders } = this.state;

    return (
      <Card sx={styles.card}>
        <CardContent sx={styles.cardContent}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              <Trans>account_order_label</Trans>
            </Typography>
            <Button
              type="submit"
              variant="contained"
              sx={{ textTransform: 'none' }}
            >
              <Trans>order_new_button</Trans>
            </Button>
          </Box>

          <List sx={styles.list}>
            { orders.map(
              (order: IOrder) => <OrderPanelCard order={order} updateOrders={this.getOrders} key={order['@id']} />,
            ) }
          </List>
        </CardContent>
      </Card>
    );
  }

  private getOrders = async () => {
    try {
      const { account } = this.props as { account: IAccount };
      const accountIri = account['@id'];
      const [investRequests, investOrders] = await Promise.all([
        this.accountsService.getInvestRequests(accountIri),
        this.accountsService.getInvestOrders(accountIri),
      ]);
      const orders = [...investRequests as IOrders, ...investOrders as IOrders];

      this.setOrders(orders);
    } catch (error) {
      console.debug(error);
    }
  };

  private setOrders(orders: IOrders): void {
    this.setState({ orders });
  }
}
