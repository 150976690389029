import { Settings } from '@mui/icons-material';
import Groups from '@mui/icons-material/Groups';
import Home from '@mui/icons-material/Home';
import LocalOffer from '@mui/icons-material/LocalOffer';

const MenuConstant = [
  {
    slug: 'dashboard',
    title: 'dashboard_title',
    path: '/dashboard',
    icon: Home,
  },
  {
    slug: 'accounts',
    title: 'accounts_title',
    path: '/accounts',
    icon: Groups,
  },
  {
    slug: 'coupons',
    title: 'coupons_title',
    path: '/coupons',
    icon: LocalOffer,
  },
  {
    slug: 'setting',
    title: 'settings_title',
    path: '/settings',
    icon: Settings,
  },
] as const;

export default MenuConstant;
