import Box from '@mui/material/Box';
import TabPanelProps from './ITabPanel';

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      sx={{ height: '100%' }}
    >
      {
        value === index && (
          <Box sx={{ height: '100%' }}>
            { children }
          </Box>
        )
      }
    </Box>
  );
};

export default TabPanel;
