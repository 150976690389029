import { SxProps } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { Link, Route, withRouter } from 'react-router-dom';
import AccountPageActions from '../../components/AccountPageActions/AccountPageActions';
import ClientInfoContextProvider from '../../components/ClientInfoContextProvider/ClientInfoContextProvider';
import DocumentsPanel from '../../components/DocumentsPanel/DocumentsPanel';
import MinorBadge from '../../components/MinorBadge/MinorBadge';
import PermissionChecker from '../../components/PermissionChecker';
import TabPanel from '../../components/TabPanel/TabPanel';
import UserInfo from '../../components/UserInfo/UserInfo';
import ColorConstant from '../../constants/ColorConstant';
import Permission from '../../constants/Permission';
import PropertiesConstant from '../../constants/PropertiesConstant';
import ClientInfoContext from '../../contexts/ClientInfoContext';
import { IAccount } from '../../interfaces/IAccount';
import IAccountPageTab, { IAccountPageTabs } from '../../interfaces/IAccountPageTab';
import IUser from '../../interfaces/IUser';
import createIriFromId from '../../utils/createIriFromId';
import { IAccountPageProps } from './IAccountPageProps';
import IAccountPageState from './IAccountPageState';

const styles: {[key: string]: SxProps} = {
  box: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${ PropertiesConstant.TOPBAR_SIZE }px)`,
    boxSizing: 'border-box',
  },

  appBar: {
    background: '#FFF',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: 'none',
    borderBottom: `1px solid ${ ColorConstant.lightGray }`,
    height: `${ PropertiesConstant.TOPBAR_SIZE }px`,
  },

  tabPanelBox: {
    padding: '16px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },

  tabItem: {
    fontSize: '20px',
    padding: '12px 24px',
    height: `${ PropertiesConstant.TOPBAR_SIZE }px`,
  },

  tabLabel: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
};

class AccountPage extends React.PureComponent<IAccountPageProps, IAccountPageState> {
  private tabs: IAccountPageTabs;

  constructor(props: IAccountPageProps) {
    super(props);
    const { match } = this.props;
    const { id } = match.params;

    this.tabs = [
      {
        index: 0,
        label: <Trans>tab_title_client_info</Trans>,
        slug: '/client-info',
        children: <UserInfo />,
      },
      {
        index: 1,
        label: <Trans>tab_title_documents</Trans>,
        slug: '/documents',
        children: <DocumentsPanel accountIri={createIriFromId('user_investment_accounts', id)} />,
      },
    ];

    this.state = {
      tabIndex: this.tabs.findIndex((tab) => tab.slug.slice(1) === match.params.tab),
    };
  }

  public componentDidMount() {
    const { match, history } = this.props;
    const tabParamExists = this.tabs.find((tab) => tab.slug.slice(1) === match.params.tab);

    if (undefined === tabParamExists) {
      const firstTabSlug = this.tabs[0].slug;

      history.push(`/accounts/${ match.params.id }${ firstTabSlug }`);
      this.setState({ tabIndex: 0 });
    }
  }

  public render() {
    const { tabIndex } = this.state;
    const { tabs } = this;

    return (
      <PermissionChecker permissions={[Permission.READ_CLIENT_INFO]}>
        <ClientInfoContextProvider>
          <ClientInfoContext.Consumer>
            {
              ({ account, setUser, user, isAccountMinor }) => (
                <>
                  <Box sx={{ flexGrow: 1 }} >
                    <AppBar
                      position="static"
                      sx={styles.appBar}
                    >
                      <Tabs
                        value={tabIndex}
                        sx={{ height: `${ PropertiesConstant.TOPBAR_SIZE }px` }}
                        TabIndicatorProps={{
                          style: { height: '8px' },
                        }}
                      >
                        {
                          tabs.map((tab) => (
                            <Tab
                              key={tab.slug}
                              label={this.renderTabLabel(tab, isAccountMinor)}
                              component={Link}
                              to={`/accounts/${ (account as IAccount).id }${ tab.slug }`}
                              onClick={(event: React.SyntheticEvent) => this.handleChange(event, tab.index)}
                              sx={styles.tabItem}
                            />
                          ))
                        }
                      </Tabs>

                      <AccountPageActions
                        accountIri={(account as IAccount)['@id']}
                        userIri={user['@id']}
                        setUser={setUser as (newValues: Partial<IUser>) => void}
                      />
                    </AppBar>
                  </Box>

                  <Box sx={styles.box}>
                    {
                      tabs.map((tab, idx) => (
                        <Route
                          key={tab.slug}
                          path={`/accounts/${ (account as IAccount).id }${ tab.slug }`}
                        >
                          <TabPanel
                            value={tabIndex}
                            index={idx}
                          >
                            <Box sx={styles.tabPanelBox}>
                              { tab.children }
                            </Box>
                          </TabPanel>
                        </Route>
                      ))
                    }
                  </Box>
                </>
              )
            }
          </ClientInfoContext.Consumer>
        </ClientInfoContextProvider>
      </PermissionChecker>
    );
  }

  private handleChange(event: React.SyntheticEvent, newTabIndex: number) {
    this.setState({ tabIndex: newTabIndex });
  }

  private renderTabLabel(tab: IAccountPageTab, isAccountMinor?: boolean): ReactElement | JSX.Element {
    if (tab.index === 0 && isAccountMinor) {
      return (
        <Box sx={styles.tabLabel}>
          { tab.label }
          <MinorBadge />
        </Box>
      );
    }

    return tab.label;
  }
}

export default withRouter(AccountPage);
