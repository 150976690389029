import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ClientInfoContext from '../../contexts/ClientInfoContext';
import ReferentialContext from '../../contexts/ReferentialContext';
import { IAccount } from '../../interfaces/IAccount';
import AdvicePanel from '../AdvicePanel/AdvicePanel';
import FinancialCapitalPanel from '../FinancialCapitalPanel';
import IdentityPanel from '../IdentityPanel/IdentityPanel';
import InvestmentValuesChart from '../InvestmentValuesChart/InvestmentValuesChart';
import LegalRepresentativeIdentityCard from '../LegalRepresentativeIdentityCard/LegalRepresentativeIdentityCard';
import MoneyPotsPanel from '../MoneyPotsPanel/MoneyPotsPanel';
import OrderPanel from '../OrderPanel/OrderPanel';
import ProductList from '../ProductList/ProductList';
import ProfilePanel from '../ProfilePanel/ProfilePanel';

const styles: { [key: string]: SxProps } = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  top: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },

  bottom: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    padding: '16px 0 1px',
    overflow: 'hidden',
  },

  bottomWrapper: {
    border: '1px solid',
    borderColor: 'lightGray',
    padding: '8px',
    height: '100%',
    mr: 1,
    width: '50%',
    position: 'relative',
  },

  left: {
    flexBasis: '70%',
    gap: '16px',
    display: 'flex',
  },

  right: {
    flexBasis: '30%',
  },

  legalRepresentativesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
  },
};

class UserInfo extends React.PureComponent<RouteComponentProps> {
  public render() {
    return (
      <ReferentialContext.Consumer>
        {
          ({ investProfileCategories }) => (
            <ClientInfoContext.Consumer>
              {
                ({ dto, account, user, isAccountMinor, setClientInfo }) => (
                  <Box sx={{
                    ...styles.wrapper,
                    minHeight: isAccountMinor ? '1003px' : '736px',
                  }}
                  >
                    <Box sx={styles.top}>
                      <Box sx={styles.left}>
                        <Box sx={{ flexBasis: '70%' }}>
                          <IdentityPanel
                            user={user}
                            dto={dto}
                            account={account as IAccount}
                            setClientInfo={setClientInfo as (id: string) => void}
                          />
                        </Box>
                        <Box sx={{ flexBasis: '30%' }}>
                          <ProfilePanel />
                        </Box>
                      </Box>

                      <Box sx={{ flexBasis: '30%' }}>
                        <AdvicePanel />
                      </Box>
                    </Box>
                    <Box sx={styles.bottom}>
                      <Box sx={styles.left} flexDirection="column">
                        <MoneyPotsPanel userIri={user['@id']} />
                        {
                          isAccountMinor ? (
                            <Box sx={styles.legalRepresentativesContainer}>
                              {
                                dto.familyInformation.legalRepresentatives?.map(
                                  (legal) => <LegalRepresentativeIdentityCard legal={legal} isPayer={legal.user === dto.bankInformation.owner} key={legal.user} />,
                                )
                              }
                            </Box>
                          ) : null
                        }
                        <FinancialCapitalPanel />
                        <Card sx={{ height: '100%' }}>
                          <CardContent sx={{
                            display: 'flex',
                            height: '100%',
                            ':last-child': { padding: 2 },
                          }}
                          >
                            <Box sx={styles.bottomWrapper}>
                              <InvestmentValuesChart account={account} />
                            </Box>
                            <Box sx={{ width: '50%', ml: 1, height: '100%' }}>
                              <ProductList account={account} investProfileCategories={investProfileCategories} />
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                      <Box sx={styles.right}>
                        <OrderPanel account={account} />
                      </Box>
                    </Box>
                  </Box>
                )
              }
            </ClientInfoContext.Consumer>
          ) }
      </ReferentialContext.Consumer>
    );
  }
}

export default withRouter(UserInfo);
