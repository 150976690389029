import IUserInvestmentAccountInformationsParams from '../interfaces/IUserInvestmentAccountInformationsParams';

export function createParamsObjectFromQuerystring(): URLSearchParams {
  let queryParams = new URLSearchParams(location.search);

  if (queryParams.get('returnURL')) {
    queryParams = new URLSearchParams(queryParams.get('returnURL')?.split('?').pop());
  }

  const params = new URLSearchParams();

  for (const pairAndValue of queryParams.entries()) {
    const pair: keyof IUserInvestmentAccountInformationsParams = pairAndValue[0] as keyof IUserInvestmentAccountInformationsParams;
    const value = pairAndValue[1];

    params.append(pair, value);
  }

  return params;
}
