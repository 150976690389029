import { AxiosResponse } from 'axios';
import { IAdviceFormat } from '../interfaces/IAdviceFormat';
import IIriArray from '../interfaces/IIriArray';
import { ISettingBody } from '../interfaces/ISettings';
import { ISettings } from '../interfaces/ISettings';
import ServiceConstructor from './ServiceConstructor';

class SettingService extends ServiceConstructor {
  public getSettings(key: string): Promise<AxiosResponse<ISettings>> {
    return this.getRessource(`/v1/settings/${ key }`);
  }

  public patchSettings(key: string, body: ISettingBody): Promise<AxiosResponse<void>> {
    return this.patchRessource(`v1/settings/${ key }`, body);
  }

  public getAdviceFormats(): Promise<AxiosResponse<IIriArray<IAdviceFormat>>> {
    return this.getRessource<IIriArray<IAdviceFormat>>(`/v1/advice_formats?obsolete=false`);
  }

  public patchAdviceFormats(slug: string, enabled: boolean): Promise<AxiosResponse<void>> {
    return this.patchRessource(`/v1/advice_formats/${ slug }`, { enabled });
  }
}

export default SettingService;
