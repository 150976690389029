import { Redirect, Route } from 'react-router-dom';
import SignInPage from '../pages/SignInPage';

const RenderPublicRoutes = () => {
  const { pathname, search } = window.location;
  const urlParams = new URLSearchParams();
  const shouldRedirect = pathname !== '/sign-in';

  if (shouldRedirect && pathname !== '/') {
    const returnUrl = `${ pathname }${ search }`;

    urlParams.append('returnURL', returnUrl);
  }

  return (
    <>
      <Route path="/sign-in" key="sign-in-route" component={SignInPage} />
      {
        shouldRedirect && (
          <Redirect
            path="**"
            key="redirect-to-sign-in"
            to={{
              pathname: '/sign-in',
              search: `?${ urlParams.toString() }`,
            }}
          />
        )
      }
    </>
  );
};

export default RenderPublicRoutes;
