import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { ChangeEvent } from 'react';
import { Trans } from 'react-i18next';
import ColorConstant from '../../constants/ColorConstant';
import FileReasonConstants from '../../constants/FileReasonConstant';
import FileSubreasonConstant from '../../constants/FileSubreasonConstant';
import IDocumentBody from '../../interfaces/IDocumentBody';
import DocumentService from '../../services/DocumentService';
import SnackbarService from '../../services/SnackbarService';
import IDocumentPanelModalProps from './IDocumentPanelModalProps';
import IDocumentPanelModalState from './IDocumentPanelModalState';

const styles: {[key: string]: SxProps} = {
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 6px #00000029',
    padding: 0,
    width: 700,
    maxHeight: '90vh',
    overflow: 'auto',
  },

  modalHeaderBorder: {
    p: 0,
    borderBottom: `1px solid ${ ColorConstant.lightGray }`,
  },

  modalHeaderContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
  },

  modalContent: {
    padding: '16px',
  },

  formControl: {
    padding: '36px 72px',
    width: '100%',
  },

  formSelect: {
    width: '40%',
    minWidth: '220px',
    marginBottom: '32px',
  },

  formInputFile: {
    width: '100%',
    height: '72px',
    border: `4px dashed ${ ColorConstant.borderColor }`,
    borderRadius: '4px',
    cursor: 'pointer',
    position: 'relative',
  },

  dragAndDropText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textTransform: 'uppercase',
    color: ColorConstant.disabledGray,
    display: 'flex',
    alignItems: 'center',
  },

  droppedText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    color: ColorConstant.primary,
  },

  inputFile: {
    opacity: 0,
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
};

class DocumentPanelModal extends React.PureComponent<IDocumentPanelModalProps, IDocumentPanelModalState> {
  private subreasonTriggers = ['carte-identite-fr', 'driving-licence', 'titre-sejour-fr'];
  private inputFileRef = React.createRef<HTMLInputElement>();
  private inputIbanRef = React.createRef<HTMLInputElement>();
  private inputBicRef = React.createRef<HTMLInputElement>();
  private documentService = new DocumentService();

  constructor(props: IDocumentPanelModalProps) {
    super(props);

    const { userIri, dto } = this.props;
    const possibleUsers: IDocumentPanelModalState['possibleUsers'] = [
      {
        id: userIri,
        fullName: `${ dto.identity.firstName } ${ dto.identity.lastName }`,
      },
      ...dto.familyInformation.legalRepresentatives.map((legal) => ({
        id: legal.user,
        fullName: `${ legal.firstName } ${ legal.lastName }`,
      })),
    ];

    this.state = {
      selectedReason: '',
      selectedSubreason: '',
      uploadedFileName: '',
      selectedUser: possibleUsers.length === 1 ? possibleUsers[0].id.toString() : '',
      showSubreasonSelected: false,
      isDragging: false,
      possibleUsers: possibleUsers,
      isIbanCheckboxChecked: false,
      isUploadBtnDisabled: true,
      isLoading: false,
    };
  }

  public render() {
    const { isDragging, selectedReason, selectedSubreason, showSubreasonSelected, uploadedFileName, selectedUser, possibleUsers, isUploadBtnDisabled, isLoading } = this.state;
    const { inputFileRef } = this;

    return (
      <Modal open onClose={() => this.closeModal()}>
        <Box sx={styles.modalWrapper}>
          <Box sx={styles.modalHeaderBorder}>
            <Box sx={styles.modalHeaderContent}>
              <Typography
                variant="h6"
                sx={{ textTransform: 'uppercase' }}
              >
                <Trans>upload</Trans>
              </Typography>
              <CloseIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => this.closeModal()}
              />
            </Box>
          </Box>

          <Box sx={styles.modalContent} component="form">
            <Box sx={styles.formControl}>
              <FormControl fullWidth>
                <InputLabel>
                  <Trans>document_file_owner</Trans>
                </InputLabel>
                <Select
                  required
                  sx={styles.formSelect}
                  value={selectedUser}
                  disabled={possibleUsers.length === 1}
                  label={t('document_file_owner')}
                  onChange={(e: SelectChangeEvent<string>) => this.handleChangeUser(e)}
                >
                  {
                    possibleUsers.map(
                      (user) => <MenuItem key={user.id} value={user.id}>{ user.fullName }</MenuItem>,
                    )
                  }
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>
                  <Trans>document_file_type</Trans>
                </InputLabel>
                <Select
                  sx={styles.formSelect}
                  value={selectedReason}
                  label={t('document_file_type')}
                  onChange={(e) => this.handleChangeReason(e)}
                >
                  {
                    FileReasonConstants
                      .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                      .map((fileReason, index) => (
                        <MenuItem
                          key={index}
                          value={fileReason.label}
                        >
                          { fileReason.label }
                        </MenuItem>
                      ))
                  }
                </Select>
              </FormControl>

              {
                showSubreasonSelected && (
                  <FormControl fullWidth>
                    <InputLabel>
                      <Trans>subreason_type</Trans>
                    </InputLabel>
                    <Select
                      sx={styles.formSelect}
                      value={selectedSubreason}
                      label="Type de subreason"
                      onChange={(e) => this.handleChangeSubreason(e)}
                    >
                      {
                        FileSubreasonConstant
                          .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                          .map((fileSubreason) => (
                            <MenuItem
                              key={fileSubreason.label}
                              value={fileSubreason.label}
                            >
                              { fileSubreason.label }
                            </MenuItem>
                          ))
                      }
                    </Select>
                  </FormControl>
                )
              }

              {
                this.shouldDisplayInputFile() && (
                  <>
                    <InputLabel
                      htmlFor="file"
                      sx={styles.formInputFile}
                    >
                      <Box sx={styles.dragAndDropText}>
                        {
                          isDragging ? (
                            <CheckCircleOutlineIcon
                              fontSize="large"
                              sx={{ color: ColorConstant.primary }}
                            />
                          ) : uploadedFileName ? (
                            <Typography
                              variant="h6"
                              paragraph
                              sx={styles.droppedText}
                            >
                              <CheckCircleOutlineIcon
                                fontSize="medium"
                                sx={{ color: ColorConstant.primary }}
                              />
                              { uploadedFileName }
                            </Typography>
                          ) : (
                            <Trans>upload_drag_and_drop</Trans>
                          )
                        }
                      </Box>

                      <Box
                        component="input"
                        type="file"
                        name="file"
                        id="file"
                        ref={inputFileRef}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => this.setState({ uploadedFileName: event.target?.files?.[0]?.name || '' }, this.isUploadButtonDisabled)}
                        onDragEnter={() => this.manageIsDragging(true)}
                        onDragLeave={() => this.manageIsDragging(false)}
                        onDrop={() => this.manageIsDragging(false)}
                        sx={styles.inputFile}
                      />
                    </InputLabel>

                    {
                      selectedReason === 'iban' ?
                        this.renderUpdateIban()
                        : null
                    }
                  </>
                )
              }

            </Box>

            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            >
              <Button
                variant="contained"
                onClick={() => this.handleUpload()}
                sx={{ letterSpacing: '1.25px' }}
                disabled={isUploadBtnDisabled || isLoading}
              >
                {
                  isLoading ?
                    <CircularProgress size={24} color="secondary" />
                    : <Trans>upload</Trans>
                }
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }

  private manageIsDragging(newValue: boolean) {
    this.setState({ isDragging: newValue });
  }

  private manageSubreasonSelectVisibility() {
    const { selectedReason } = this.state;
    const triggerFounded = this.subreasonTriggers.find((subreasonTrigger) => subreasonTrigger === selectedReason);

    this.setState({
      showSubreasonSelected: !!triggerFounded,
      selectedSubreason: '',
    }, this.isUploadButtonDisabled);
  }

  private handleChangeReason (event: SelectChangeEvent) {
    this.setState({ selectedReason: event.target.value }, this.manageSubreasonSelectVisibility);
  }

  private handleChangeSubreason (event: SelectChangeEvent) {
    this.setState({ selectedSubreason: event.target.value }, this.isUploadButtonDisabled);
  }

  private handleChangeUser (event: SelectChangeEvent) {
    this.setState({ selectedUser: event.target.value });
  }

  private async handleUpload() {
    this.setState({ isLoading : true });

    try {
      const { selectedUser, selectedReason, selectedSubreason, isIbanCheckboxChecked } = this.state;
      const { inputFileRef, inputBicRef, inputIbanRef } = this;
      const { accountIri } = this.props;
      const file = inputFileRef?.current?.files && inputFileRef?.current?.files[0];

      if (file?.type && selectedReason) {
        const document: IDocumentBody = {
          type: file.type,
          reason: selectedReason,
          file,
          user: selectedUser,
          investmentAccount: accountIri,
          subreason: selectedSubreason,
        };

        if (isIbanCheckboxChecked) {
          document.iban = inputIbanRef.current?.value;
          document.bic = inputBicRef.current?.value;
        }

        await this.documentService.uploadDocument(document);
      }

      SnackbarService.open(t('upload_document_success'), 'success');
      this.closeModal(true);
    } catch (error) {
      console.debug(error);
      SnackbarService.open(t('upload_document_failed'), 'error');
      this.setState({ isLoading : false });
    }
  }

  private closeModal = (relaodDocs?: boolean) => {
    const { handleModal } = this.props;

    handleModal(relaodDocs);
  };

  private shouldDisplayInputFile(): boolean {
    const { selectedUser, selectedReason, showSubreasonSelected, selectedSubreason, uploadedFileName } = this.state;
    const value = !!selectedReason && !!selectedUser && (showSubreasonSelected ? !!selectedSubreason : true);

    if (!value && uploadedFileName !== '') {
      this.setState({ uploadedFileName: '' });
    }

    return value;
  }

  private isUploadButtonDisabled = () => {
    const { selectedUser, selectedReason, showSubreasonSelected, selectedSubreason, isIbanCheckboxChecked, isUploadBtnDisabled } = this.state;
    const value = !selectedUser ||
      !selectedReason ||
      !this.inputFileRef?.current?.files?.[0] ||
      (showSubreasonSelected && !selectedSubreason) ||
      (selectedReason === 'iban' ?
        isIbanCheckboxChecked ? (!this.inputBicRef?.current?.value || !this.inputIbanRef?.current?.value) : false
        : false);

    if (isUploadBtnDisabled !== value) {
      this.setState({ isUploadBtnDisabled: value });
    }
  };

  private onIbanCheckboxChange(checked: boolean) {
    this.setState({
      isIbanCheckboxChecked: checked,
    }, this.isUploadButtonDisabled);
  }

  private renderUpdateIban() {
    const { isIbanCheckboxChecked } = this.state;
    const checkBoxLabel = 'Je souhaite modifier l\'iban et le bic';

    return (
      <Box sx={{ marginTop : 1 }}>
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label={checkBoxLabel} onChange={(_, checked) => this.onIbanCheckboxChange(checked)} />
        </FormGroup>

        {
          isIbanCheckboxChecked ? (
            <Box>
              <TextField
                fullWidth
                label={<Trans>iban</Trans>}
                variant="standard"
                inputRef={this.inputIbanRef}
                onChange={this.isUploadButtonDisabled}
              />
              <TextField
                fullWidth
                label={<Trans>bic</Trans>}
                variant="standard"
                inputRef={this.inputBicRef}
                onChange={this.isUploadButtonDisabled}
              />
            </Box>
          )
            : null
        }
      </Box>
    );
  }
}

export default DocumentPanelModal;
