import { createContext } from 'react';
import IClientInfoProviderState from '../components/ClientInfoContextProvider/IClientInfoProviderState';
import { IAccount } from '../interfaces/IAccount';
import IFinancialCapital from '../interfaces/IFinancialCapital';
import IUser from '../interfaces/IUser';
import IUserKycMppDto from '../interfaces/UserKycMppDto/IUserKycMppDto';

interface IClientInfoContext extends IClientInfoProviderState {
  setAccount?: (account: IAccount) => void;
  setUser?: (newValues: Partial<IUser>) => void;
  dto: IUserKycMppDto;
  financialCapital: IFinancialCapital;
  user: IUser;
}

const ClientInfoContext = createContext<IClientInfoContext>({
  loaded: false,
  firstTimeLoading: true,
  account: null,
  dto: {} as IUserKycMppDto,
  financialCapital: {} as IFinancialCapital,
  user: {} as IUser,
  kycQuestions: [],
});

export default ClientInfoContext;
