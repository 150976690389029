export const CURRENT_STEPS = {
  INTRO: '0100-intro',
  QUESTIONS: '0200-questions',
  DOCUMENTS_IDENTITY: '0300-documents-identity',
  DOCUMENTS_RESIDENCE_PROOF: '0400-documents-residence-proof',
  AMOUNTS_VALIDATION: '0500-amounts-validation',
  STUCK_BEFORE_CONTACT_CHOICE: '0545-stuck-before-contact-choice',
  CONTACT_CHOICE: '0550-contact-choice',
  ADVICE_CREATION: '1000-advice-creation',
  ADVICE_VIDEO_FAILURE: '1040-advice-video-generation-failure',
  ADVICE_VIDEO_GENERATION: '1050-advice-video-generation',
  ADVICE_VALIDATION: '1100-advice-validation',
  RECONNECTED_AFTER_ADVICE: '1200-reconnected-after-advice',
  DISTRIBUTION_VALIDATION: '1300-distribution-validation',
  INVESTMENT_SUPPORT_VALIDATION: '1400-investment-support-validation',
  BENEFICIARIES_CHOICE: '1500-beneficiaries-choice',
  BENEFICIARIES_OTHER_CHOICE: '1600-beneficiaries-other-choice',
  ORIGIN_FUNDS_CHOICE: '1700-origin-funds-choice',
  DOCUMENTS_ORIGIN_PROOF: '1800-documents-origin-proof',
  ORIGIN_ASSETS: '1900-origin-assets',
  PPE_AGREEMENT: '2000-ppe-agreement',
  UPLOAD_DOCUMENTS: '2050-upload-documents',
  DOCUMENTS_IBAN: '2100-documents-iban',
  AMOUNTS_SUMMARY: '2150-amounts-summary',
  STUCK_BEFORE_MPP_SIGNATURE: '2190-stuck-before-mpp-signature',
  DOCUMENTS_MPP_GENERATION: '2200-documents-mpp-generation',
  DOCUMENTS_MPP_SIGNATURE: '2300-documents-mpp-signature',
  DOCUMENTS_MPP_FAILURE: '2400-documents-mpp-failure',
  UNIVERSIGN_FAILURE: '2500-universign-failure',
  CONSENTS_AGREEMENT: '2600-consents-agreement',
  STUCK_BEFORE_PROVIDER_SIGNATURE: '2700-stuck-before-provider-signature',
  DOCUMENTS_PROVIDER_GENERATION: '2800-documents-provider-generation',
  DOCUMENTS_PROVIDER_SIGNATURE: '2900-documents-provider-signature',
  DOCUMENTS_PROVIDER_FAILURE: '3000-documents-provider-failure',
  AWAITING_UNIVERSING_AGREEMENT: '3050-awaiting-universign-agreement',
  DOCUMENTS_PROVIDER_SENDING: '3100-documents-provider-sending',
  END: '3200-end',
  OBSOLETE: '3300-obsolete',
} as const;
