import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, ListItem, SxProps } from '@mui/material';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IAccountInformation } from '../../interfaces/IAccountInformation';
import AccountsService from '../../services/AccountsService';
import WindowNavigationService from '../../services/WindowNavigationService';
import AccountsBasicSearch from '../AccountsBasicSearch/AccountsBasicSearch';
import ITopBarSearchState from './ITopBarSearchState';

const styles: { [key: string]: SxProps } = {
  modalWrapper: {
    position: 'absolute',
    top: '146px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '892px',
    border: 'none',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxHeight: 'calc(100% - 146px - 146px)',
  },

  list: {
    backgroundColor: 'white',
    overflowY: 'auto',
    borderRadius: '4px',
  },

  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
};

class TopBarSearch extends React.Component<RouteComponentProps, ITopBarSearchState> {
  public readonly state: ITopBarSearchState = {
    isModalOpen: false,
    accounts: [],
    searching: false,
  };

  private abortController = new AbortController();
  private accountsService: AccountsService;
  private windowNavigationService = new WindowNavigationService();

  constructor(props: RouteComponentProps) {
    super(props);
    this.accountsService = new AccountsService(this.abortController.signal);
  }

  public render() {
    const { isModalOpen, accounts, searching } = this.state;

    return (
      <>
        <IconButton sx={{ cursor: 'pointer ' }} onClick={this.handleSearchClick}>
          <SearchIcon />
        </IconButton>
        <Modal open={isModalOpen} onClose={this.handleModalClose}>
          <Container sx={styles.modalWrapper} >
            <Box sx={{ background: 'none' }}>
              <AccountsBasicSearch getUserInvestmentAccountInformations={this.getUserInvestmentAccountInformations} />
            </Box>

            {
              (accounts.length > 0 || searching) && (
                <List sx={styles.list}>
                  {
                    !searching && accounts.map((account: IAccountInformation) => (
                      <ListItemButton
                        key={account['@id']}
                        sx={{ padding: '20px 32px' }}
                        onClick={(event) => this.handleListItemClick(event, account.id)}
                        onAuxClick={(event) => this.handleListItemClick(event, account.id)}
                      >
                        <Typography>{ account.email }</Typography>
                      </ListItemButton>
                    ))
                  }
                  {
                    searching && (
                      <ListItem sx={styles.loading}>
                        <CircularProgress />
                      </ListItem>
                    )
                  }
                </List>
              )
            }
          </Container>
        </Modal>
      </>
    );
  }

  private handleSearchClick = () => {
    this.setState({ isModalOpen: true });
  };

  private handleModalClose = () => {
    this.abortController?.abort();

    this.setState({
      isModalOpen: false,
      accounts: [],
    });
  };

  private getUserInvestmentAccountInformations = async (params: URLSearchParams) => {
    if (!this.shouldGetAccounts(params)) {
      return;
    }

    this.setState({ searching: true });

    try {
      const accountId = params.get('accountId');

      if (accountId) {
        const response = await this.accountsService.getUserInvestmentAccountInformation(accountId);

        this.setState({
          accounts: [response.data],
          searching: false,
        });
      } else {
        for (const [key, value] of Array.from(params.entries())) {
          if (!value) {
            params.delete(key);
          }
        }

        params.append('limit', '25');

        const response = await this.accountsService.getUserInvestmentAccountInformationCollection(params);
        const accounts = response.data['hydra:member'];

        this.setState({
          accounts,
          searching: false,
        });
      }
    } catch (error) {
      this.setState({
        accounts: [],
        searching: false,
      });
    }
  };

  private handleListItemClick = (event: React.MouseEvent, accountId: string) => {
    const { ctrlKey, metaKey, button } = event;
    const { history } = this.props;

    this.windowNavigationService.tryAltOpen(event, `/accounts/${ accountId }/client-info`) || history.push(`/accounts/${ accountId }/client-info`);

    if (!ctrlKey && !metaKey && 0 === button) {
      this.handleModalClose();
    }
  };

  private shouldGetAccounts(params: URLSearchParams): boolean {
    for (const [key, value] of params) {
      if ('currentStep[]' !== key && value) {
        return true;
      }
    }

    return false;
  }
}

export default withRouter(TopBarSearch);
