import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/index.scss';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://551c0133375443d6b4f5ee710d2c1e84@sentry.monpetitplacement.fr/12',
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
