import { Modal, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Trans } from 'react-i18next';
import ClientInfoContext from '../../contexts/ClientInfoContext';
import { IChoiceDistribution } from '../../interfaces/UserKycMppDto/IAdviceDto';
import IAdvicePanelState from './IAdvicePanelState';

class AdvicePanel extends React.PureComponent<unknown, IAdvicePanelState> {
  public readonly state: IAdvicePanelState = {
    open: false,
  };

  public render() {
    const { open } = this.state;

    return (
      <ClientInfoContext.Consumer>
        {
          ({ dto }) => (
            <Card sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
            >
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      <Trans>account_advice_title</Trans>
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          <b><Trans>account_advice_initial_label</Trans></b>
                        </Typography>
                        {
                          dto.adviceDto.mppChoice?.initialDistribution && dto.adviceDto.mppChoice.initialDistribution.length > 3 ? (
                            <>
                              {
                                dto.adviceDto.mppChoice.initialDistribution.slice(0, 2).map(
                                  (initialAdvice) => this.renderAdvice(initialAdvice),
                                )
                              }
                              <Box sx={{
                                mb: 2,
                                maxHeight: '20px',
                                ':last-child': {
                                  mb: 0,
                                },
                              }}
                              >
                                <Typography variant="body2" sx={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                >
                                  { `...${ dto.adviceDto.mppChoice.initialDistribution.length - 2 } de plus` }
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            dto.adviceDto.mppChoice?.initialDistribution.map(
                              (initialAdvice) => this.renderAdvice(initialAdvice),
                            )
                          )
                        }
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          <b><Trans>account_advice_monthly_label</Trans></b>
                        </Typography>
                        {
                          dto.adviceDto.mppChoice?.monthlyDistribution && dto.adviceDto.mppChoice.monthlyDistribution.length > 3 ? (
                            <>
                              {
                                dto.adviceDto.mppChoice.monthlyDistribution.slice(0, 2).map(
                                  (monthlyAdvice) => this.renderAdvice(monthlyAdvice),
                                )
                              }
                              <Box sx={{
                                mb: 2,
                                maxHeight: '20px',
                                ':last-child': {
                                  mb: 0,
                                },
                              }}
                              >
                                <Typography variant="body2" sx={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                >
                                  { `...${ dto.adviceDto.mppChoice.monthlyDistribution?.length - 2 } de plus` }
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            dto.adviceDto.mppChoice?.monthlyDistribution.map(
                              (monthlyAdvice) => this.renderAdvice(monthlyAdvice),
                            )
                          )
                        }
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          <b><Trans>account_advice_overwrite_label</Trans> :</b>&nbsp;
                          {
                            (dto.adviceDto.userChoice?.initialDistribution.length ?? 0) > 0 ||
                              (dto.adviceDto.userChoice?.monthlyDistribution.length ?? 0) > 0 ? 'Oui' : 'Non'
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </CardContent>
              <CardActions sx={{ display: 'flex', justifyContent: 'right' }}>
                <Link
                  component="button"
                  onClick={() => {
                    this.handleOpen();
                  }}
                >
                  <Trans>account_seemore_button</Trans>
                </Link>
              </CardActions>

              <Modal
                open={open}
                onClose={() => {
                  this.handleClose();
                }}
              >
                <Box className="modal">
                  <Typography variant="h5" sx={{
                    borderBottom: '1px solid',
                    borderColor: 'lightGray',
                    p: 2,
                    textTransform: 'uppercase',
                  }}
                  >
                    <Trans>account_advice_title</Trans>
                  </Typography>

                  <Box sx={{ display: 'flex' }}>
                    <Box sx={{ p: 2, flexBasis: '100%' }}>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <small><Trans>account_advice_initial_label</Trans></small>
                          <Typography variant="subtitle1">
                            {
                              dto.adviceDto.mppChoice?.initialDistribution.map((initialAdvice) => (
                                <Box key={initialAdvice.name} sx={{ mb: 1, mt: 1, maxHeight: '20px' }}>
                                  <Typography variant="body2">
                                    { `${ initialAdvice?.percent }% - ${ initialAdvice?.name }` }
                                  </Typography>
                                </Box>
                              ))
                            }
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <small><Trans>account_advice_monthly_label</Trans></small>
                          <Typography variant="subtitle1">
                            {
                              dto.adviceDto.mppChoice?.monthlyDistribution.map((monthlyAdvice) => (
                                <Box key={monthlyAdvice.name} sx={{ mb: 1, mt: 1, maxHeight: '20px' }}>
                                  <Typography variant="body2">
                                    { `${ monthlyAdvice?.percent }% - ${ monthlyAdvice?.name }` }
                                  </Typography>
                                </Box>
                              ))
                            }
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <small><Trans>account_advice_overwrite_label</Trans></small>
                          <Typography variant="subtitle1">
                            {
                              (dto.adviceDto.userChoice?.initialDistribution.length ?? 0) > 0 ||
                                (dto.adviceDto.userChoice?.monthlyDistribution.length ?? 0) > 0 ? 'Oui' : 'Non'
                            }
                          </Typography>
                        </Grid>

                        {
                          dto.adviceDto.userChoice && dto.adviceDto.userChoice?.initialDistribution.length > 0 ? (
                            <Grid item xs={6}>
                              <small><Trans>account_advice_overwrite_initial_label</Trans></small>
                              <Typography variant="subtitle1">
                                {
                                  dto.adviceDto.userChoice.initialDistribution.map((initialAdvice) => (
                                    <Box key={initialAdvice.name} sx={{ mb: 1, mt: 1, maxHeight: '20px' }}>
                                      <Typography variant="body2">
                                        { `${ initialAdvice?.percent }% - ${ initialAdvice?.name }` }
                                      </Typography>
                                    </Box>
                                  ))
                                }
                              </Typography>
                            </Grid>
                          ) : <></>
                        }
                        {
                          dto.adviceDto.userChoice && dto.adviceDto.userChoice?.monthlyDistribution.length > 0 ? (
                            <Grid item xs={6}>
                              <small><Trans>account_advice_overwrite_monthly_label</Trans></small>
                              <Typography variant="subtitle1">
                                {
                                  dto.adviceDto.userChoice.monthlyDistribution.map((initialAdvice) => (
                                    <Box key={initialAdvice.name} sx={{ mb: 1, mt: 1, maxHeight: '20px' }}>
                                      <Typography variant="body2">
                                        { `${ initialAdvice.percent }% - ${ initialAdvice.name }` }
                                      </Typography>
                                    </Box>
                                  ))
                                }
                              </Typography>
                            </Grid>
                          ) : <></>
                        }

                        {
                          dto.adviceDto.mppChoice?.splitDuration ? (
                            <Grid item xs={12}>
                              <small><Trans>split_duration</Trans></small>
                              <Typography variant="subtitle1">
                                {
                                  dto.adviceDto.mppChoice.splitDuration
                                } <Trans>months</Trans>
                              </Typography>
                            </Grid>
                          ) : null
                        }
                      </Grid>

                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                        <Button
                          onClick={() => this.handleClose()}
                          type="submit"
                          color="secondary"
                          variant="contained"
                          sx={{ mt: 3 }}
                        >
                          <Trans>close_button</Trans>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </Card>
          )
        }
      </ClientInfoContext.Consumer>
    );
  }

  private handleOpen() {
    this.setState({ open: true });
  }

  private handleClose() {
    this.setState({ open: false });
  }

  private renderAdvice = (distribution: IChoiceDistribution) => (
    <Box key={distribution.name} sx={{
      mb: 2,
      maxHeight: '20px',
    }}
    >
      <Tooltip title={distribution.name}>
        <Typography variant="body2" sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        >
          { `${ distribution.percent }% - ${ distribution.name }` }
        </Typography>
      </Tooltip>
    </Box>
  );
}

export default AdvicePanel;
