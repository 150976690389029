import { PropsWithChildren, ReactElement } from 'react';
import { Redirect, matchPath, useHistory } from 'react-router-dom';

const RoutesHandler = (props: PropsWithChildren<{ children: ReactElement[] }>): ReactElement => {
  const REDIRECT_PATHNAME = '/accounts';
  const REDIRECT_SEARCH = '?limit=25&page=1';
  const { location } = useHistory();
  const { children } = props;
  let redirectPath: {pathname: string; search?: string} = {
    pathname: location.pathname,
    search: location.search,
  };
  let { pathname, search } = location;

  if (search) {
    const query = new URLSearchParams(location.search);
    const returnURL = query.get('returnURL');

    if (returnURL) {
      const newUrl = new URL(returnURL, 'https://app');

      pathname = newUrl.pathname;
      search = newUrl.search;
    }
  }

  const routeFound = children.find(
    (child: ReactElement) => matchPath(pathname, {
      path: child.props.path,
      exact: false,
      strict: false,
    })?.isExact,
  );

  if (routeFound) {
    redirectPath = {
      pathname,
      search,
    };
  } else {
    redirectPath = {
      pathname: REDIRECT_PATHNAME,
      search: REDIRECT_SEARCH,
    };
  }

  return (
    <>
      { children }
      <Redirect
        path="**"
        key={`redirect-to-${ REDIRECT_PATHNAME }`}
        to={redirectPath}
      />
    </>
  );
};

export default RoutesHandler;
