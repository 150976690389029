import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { CSSProperties } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';
import { t } from 'i18next';
import ColorConstant from '../../constants/ColorConstant';
import { IFromDateToDateProps } from './IFromDateToDateProps';

const styles: { [key: string]: CSSProperties } = {
  fieldset: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '4px',
    borderColor: 'rgba(0, 0, 0, .23)',
  },
  displayInLine: {
    display: 'flex',
    gap: 2,
  },
};

const CustomTextField = ({ params, hasValue }: {params: TextFieldProps; hasValue: boolean}) => (
  <TextField
    {...params}
    inputProps={{ ...params.inputProps, placeholder: 'jj/mm/aaaa' }}
    sx={{
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: 'primary.main',
          borderWidth: '2px',
        },
        ...(hasValue && {
          '& fieldset': {
            borderColor: 'primary.main',
            borderWidth: '2px',
          },
        }),
      },
    }}
  />
);

const FromDateToDate = ({ legend, fromDateLabel, toDatelabel, fromDateValue, toDateValue, handleDateValueChange, fieldNames, displayInLine }: IFromDateToDateProps) => {
  const [fromDateHasValue, toDateHasValue] = [!!fromDateValue, !!toDateValue];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <fieldset style={styles.fieldset}>
        <Typography
          sx={fromDateHasValue && toDateHasValue ? { color: ColorConstant.primary } : undefined}
          component="legend"
        >
          { legend }
        </Typography>
        <Box sx={displayInLine ? styles.displayInLine : undefined}>
          <Box sx={{ marginBottom: 1 }}>
            <DatePicker
              inputFormat={t('date_picker_input_format')}
              maxDate={toDateValue}
              label={
                fromDateHasValue ? (
                  <Typography component="span" sx={{ color: ColorConstant.primary }}>
                    { fromDateLabel }
                  </Typography>
                ) : (
                  fromDateLabel
                )
              }
              value={fromDateValue || null}
              onChange={handleDateValueChange(fieldNames[0])}
              renderInput={(params) => <CustomTextField params={params} hasValue={fromDateHasValue} />}
            />
          </Box>
          <Box>
            <DatePicker
              inputFormat={t('date_picker_input_format')}
              minDate={fromDateValue}
              label={
                toDateHasValue ? (
                  <Typography component="span" sx={{ color: ColorConstant.primary }}>
                    { toDatelabel }
                  </Typography>
                ) : (
                  toDatelabel
                )
              }
              value={toDateValue || null}
              onChange={handleDateValueChange(fieldNames[1])}
              renderInput={(params) => <CustomTextField params={params} hasValue={toDateHasValue} />}
            />
          </Box>
        </Box>
      </fieldset>
    </LocalizationProvider>
  );
};

export default FromDateToDate;
