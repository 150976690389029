import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import React, { PropsWithChildren } from 'react';
import PropertiesConstant from '../constants/PropertiesConstant';
import ReferentialProvider from './ReferentialProvider/ReferentialProvider';
import SideBar from './SideBar';
import TopBar from './TopBar';

const styles: { [key: string]: SxProps } = {
  main: {
    flexGrow: 1,
    backgroundColor: '#F5F5F3',
    mt: `${ PropertiesConstant.TOPBAR_SIZE }px`,
    height: `calc(100vh - ${ PropertiesConstant.TOPBAR_SIZE }px)`,
    overflow: 'hidden',
  },
};

export default class Layout extends React.PureComponent<PropsWithChildren<unknown>> {
  public render() {
    const { children } = this.props;

    return (
      <ReferentialProvider>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <TopBar />
          <SideBar />
          <Box
            component="main"
            sx={styles.main}
          >
            { children }
          </Box>
        </Box>
      </ReferentialProvider>
    );
  }
}
